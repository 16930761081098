import HttpClient from "shared/repositories/HttpClient";
import { GetMirorSitemapListParams } from "shared/types";

export class MirorSitemapRepository {
  private getListPath({ type }: GetMirorSitemapListParams) {
    return `/sitemaps?type=${type}`;
  }

  public getList(params: GetMirorSitemapListParams) {
    const path = this.getListPath(params);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get(path, { params })
          .then((res) => res.data),
    };
  }
}
