import { useState, useEffect } from "react";
import localStorage, {
  keys,
  SafeLocalStorage,
  makeCustomKey,
  Keys,
} from "../utils/localStorage";

export type UseLocalStorageReturn<T> = {
  values: Record<Keys, T>;
  loading: boolean;
  keys: typeof keys;
  resetStorage: () => void;
  makeCustomKey: typeof makeCustomKey;
} & SafeLocalStorage;

/**
 *
 * @returns
 *  - values [Record<Keys, T>]
 *  - loading [boolean]
 *  - keys [typeof keys]
 *  - getSerializedItem [<T>(key: Keys) => T]
 *  - setItemAsString [(key: Keys, value: any) => void]
 *  - removeItem [(key: Keys) => void]
 *  - resetStorage [() => void];
 *  - makeCustomKey [(key: Keys, identifier: string) => CustomKey]
 */
const useLocalStorage = <
  T = string | undefined
>(): UseLocalStorageReturn<T> => {
  const [values, setValues] = useState({} as any);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Object.values(keys).map((key: any) => {
      values[key] = localStorage.getSerializedItem(key);
    });
    setValues(values);
    setLoading(false);
  }, [values]);

  const methods = localStorage;
  const resetStorage = () => {
    Object.values(keys).map((key) => methods.removeItem(key));
  };

  return {
    values,
    loading,
    keys,
    ...methods,
    resetStorage,
    makeCustomKey,
  };
};

export default useLocalStorage;
