import * as yup from "yup";
import * as yupLocaleJa from "yup-locale-ja";
import {
  rangeValidator,
  kanaValidator,
  dateValidator,
  extUrlValidator,
  imgValidator,
  passwordValidator,
} from "./validator";
import { ja as customLocaleJa } from "./locale";

yup.setLocale({
  ...yupLocaleJa.suggestive,
  ...customLocaleJa,
});

// MixedSchema
yup.addMethod(yup.mixed, imgValidator.name, imgValidator.validation);

// StringSchema
yup.addMethod(yup.string, rangeValidator.name, rangeValidator.validation);
yup.addMethod(yup.string, kanaValidator.name, kanaValidator.validation);
yup.addMethod(yup.string, dateValidator.name, dateValidator.validation);
yup.addMethod(yup.string, extUrlValidator.name, extUrlValidator.validation);
yup.addMethod(yup.string, passwordValidator.name, passwordValidator.validation);

// NumberSchema
yup.addMethod(yup.number, rangeValidator.name, rangeValidator.validation);

// OptionalArraySchema
yup.addMethod(yup.array, rangeValidator.name, rangeValidator.validation);

declare module "yup" {
  interface StringSchema {
    range(min: number, max: number): this;
    kana(): this;
    date(): this;
    preventExternalUrl(): this;
    password(): this;
  }

  interface NumberSchema {
    range(min: number, max: number): this;
  }

  // eslint-disable-next-line no-unused-vars
  interface BaseSchema<TCast, TContext, TOutput> {
    range(min: number, max: number): this;
    img(): this;
  }
}
