import dayjs, { Dayjs } from "dayjs";
import { Fortune, TelFortune, LocalizeValue } from ".";
import { MenuType, menuTypes } from "../types";

const historyType = {
  redeem: "redeem",
  registration: "registration",
  from_admin: "from_admin",
  point_back: "point_back",
  invitation: "invitation",
  cancel: "cancel",
} as const;

export type HistoryType = keyof typeof historyType;

const localizedHistoryType = {
  redeem: "",
  registration: "初回登録キャンペーン",
  from_admin: "運営からのポイントプレゼント",
  point_back: "購入時獲得ポイント",
  invitation: "友達招待CPポイント",
  cancel: "鑑定キャンセル",
  surplus: "決済時の差額返還",
};

export default class PointHistory {
  point: number;
  historyType: LocalizeValue<HistoryType>;
  menuType: MenuType | undefined;
  menu: Fortune | TelFortune;
  createdAt: Dayjs;

  static localizeHistoryType(historyType: HistoryType): string {
    return localizedHistoryType[historyType];
  }

  constructor(data?: Partial<PointHistory> & { historyType: HistoryType }) {
    Object.assign(this, data);

    if (!data) return;

    this.historyType = new LocalizeValue(
      data.historyType,
      PointHistory.localizeHistoryType
    );
    this.menu = data.menuType
      ? data.menuType === menuTypes.chat
        ? new Fortune(data.menu)
        : new TelFortune(data.menu)
      : null;
    this.createdAt = dayjs(data.createdAt);
  }

  get isEarn(): boolean {
    return this.historyType.rawValue !== "redeem";
  }
}
