import HttpClient from "shared/repositories/HttpClient";
import {
  GetInProgressKarteParams,
  GetInProgressKarteRes,
  GetKarteListParams,
  GetKarteListRes,
} from "shared/types";
import { SWRConfiguration } from "swr";

export class KarteRepository {
  private getInprogressKartePath = () => {
    return "me/in_progress_karte";
  };

  private getListPath = () => {
    return "me/kartes";
  };

  public getInProgressKarte(params?: GetInProgressKarteParams) {
    const path = this.getInprogressKartePath();

    return {
      path,
      key: [path, ...Object.values(params || {})],
      data: () =>
        HttpClient.miror
          .get<GetInProgressKarteRes>(path, { params })
          .then((res) => res.data),
    };
  }

  public getList = (params?: GetKarteListParams) => {
    const path = this.getListPath();

    return {
      path,
      key: [path, ...Object.values(params || {})],
      data: () =>
        HttpClient.miror
          .get<GetKarteListRes>(path, { params })
          .then((res) => res.data),
      fallbackData: {
        kartes: [],
        meta: { totalPages: 1 },
      } as SWRConfiguration["fallbackData"],
    };
  };
}
