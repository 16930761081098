import { ChatRoomList } from "shared/models";
import clients from "shared/repositories/HttpClient";
import { WithPaginationMetaResponse, Data, Pagination } from "shared/types";

const ChatRoomListRepository = {
  index: async (
    url: string,
    params?: Pagination
  ): Promise<WithPaginationMetaResponse<{ chatRoomList: ChatRoomList[] }>> => {
    const res = await clients.miror.get(url, { params });
    const chatRoomList = res.data.chatRoomList.map(
      (c: Data) => new ChatRoomList(c)
    );
    return { chatRoomList, meta: res.data.meta };
  },
};

export default ChatRoomListRepository;
