import { FreeFortuneFormData } from "features/無料占いBot/hooks/useFreeFortuneForm";
import { NextRouter, useRouter } from "next/router";
import * as Repo from "repositories";
import { useErrorMessage } from "shared/hooks/useErrorMessage";
import useLocalStorage, {
  UseLocalStorageReturn,
} from "shared/hooks/useLocalStorage";
import { LineCallbackQueries, Paths, queries } from "utils/routes";
import { usePrevLocation } from "features/shared/hooks/usePrevLocation";
import { useRegisterAcquisitionData } from "features/user/hooks/useRegisterAcquisitionData";
import { useSession } from "next-auth/client";
import { useState } from "react";
import HttpClient from "shared/repositories/HttpClient";

/** LINEログイン後に実行する処理 */
export const lineCallbackActions = {
  friendshipPrompt: "friendship_prompt",
  receiveCoupon: "receive_coupon",
  linkToLoggedInUser: "linkToLoggedInUser",
  redirectFortuneTellerList: "redirect_fortune_teller_list",
} as const;

const openFriendshipPrompt = () => {
  location.href =
    process.env.NEXT_PUBLIC_APP_ENV === "production"
      ? "https://line.me/R/ti/p/%40kmk3717k"
      : "https://line.me/R/ti/p/%40xjj9998u";
};

const sendFreeFortuneResultToLine = async (
  localStorage: UseLocalStorageReturn<FreeFortuneFormData>,
  router: NextRouter
) => {
  const { query } = router;
  const { getSerializedItem, removeItem, keys } = localStorage;
  const freeFortuneFormData =
    getSerializedItem<FreeFortuneFormData>("free_fortune/data");

  try {
    await Repo.UserFreeFortuneChatQuestions.post({
      answer: freeFortuneFormData?.answer,
      id: query.id,
      isSubscription: query.isSubscription === "true",
    });
  } catch (error) {
    // do nothing
  } finally {
    removeItem(keys["free_fortune/data"]);
    openFriendshipPrompt();
  }
};

const receiveCoupon = async (
  localStorage: UseLocalStorageReturn<string>,
  router: NextRouter
) => {
  const { getSerializedItem, keys, removeItem } = localStorage;
  const clid = getSerializedItem<string>(keys.clid);

  if (!clid) {
    await router.push({
      pathname: Paths.home(),
      query: {
        [queries.keys.receiveCoupon]: queries.values.receiveCoupon.noCampaignId,
      },
    });
    return;
  }

  try {
    await Repo.UserCoupons.post(clid);
    await router.push({
      pathname: Paths.couponHistories.index(),
      query: {
        [queries.keys.receiveCoupon]: queries.values.receiveCoupon.success,
      },
    });
  } catch (error) {
    removeItem(keys.clid);
    throw error;
  }
};

const linkToLoggedInUser = async (code: string) => {
  if (code) {
    try {
      await HttpClient.miror.post("/me/social_profiles", {
        provider: "line",
        code,
      });
    } catch (error) {
      // do nothing
    }
  }
};

/** LINEログイン後に実行する処理 */
export const useLineCallbackAction = (query: LineCallbackQueries) => {
  const [executed, setExecuted] = useState(false);

  const [session] = useSession();
  const router = useRouter();
  const localStorage = useLocalStorage();
  const { handleAxiosError } = useErrorMessage();
  const [prevLocation, prevQuery] = usePrevLocation();
  const { registerAcquisitionData } = useRegisterAcquisitionData();

  const callback = async () => {
    if (executed) return;

    setExecuted(true);

    try {
      switch (query.action) {
        case lineCallbackActions.friendshipPrompt: {
          await registerAcquisitionData();
          await sendFreeFortuneResultToLine(
            localStorage as unknown as UseLocalStorageReturn<FreeFortuneFormData>,
            router
          );
          break;
        }
        case lineCallbackActions.receiveCoupon: {
          await registerAcquisitionData();
          await receiveCoupon(localStorage, router);
          break;
        }
        case lineCallbackActions.linkToLoggedInUser: {
          await linkToLoggedInUser(query.code as string);
          openFriendshipPrompt();
          await router.push({
            pathname: prevLocation ? prevLocation : Paths.home(),
            query: {
              ...prevQuery,
              [queries.keys.socialAccount]:
                queries.values.socialAccount.linkedToLoggedInUser,
            },
          });
          break;
        }
        case lineCallbackActions.redirectFortuneTellerList: {
          await registerAcquisitionData();
          await router.push("/campaigns/tel_fortune/4/fortune_tellers");
          break;
        }
        default: {
          await registerAcquisitionData();

          if (session.isNewUser) {
            await router.push({
              pathname: prevLocation ? prevLocation : Paths.home(),
              query: {
                ...prevQuery,
                [queries.keys.signUp]: queries.values.signUp.success,
              },
            });
          } else {
            await router.push({
              pathname: prevLocation ? prevLocation : Paths.home(),
              query: {
                ...prevQuery,
                [queries.keys.signIn]: queries.values.signIn.success,
              },
            });
          }
        }
      }
    } catch (error) {
      handleAxiosError(error);
      await router.push(
        prevLocation ? `${prevLocation}?error=default` : Paths.home()
      );
    }
  };

  return {
    callback,
    canUseCallback: !localStorage.loading && !!session && !executed,
  };
};
