import { repository } from "repositories";
import HttpClient from "shared/repositories/HttpClient";
import {
  GetTelFortuneReviewListRes,
  ID,
  Pagination,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWRInfinite from "swr/infinite";

interface Params extends Pagination {
  telFortuneId: ID;
}

export const useTelFortuneReviewList = ({ telFortuneId }: Params) => {
  const getKey = (
    pageIndex: number,
    previousPageData: WithPaginationMetaResponse<GetTelFortuneReviewListRes>
  ) => {
    if (previousPageData && !previousPageData.telFortuneReviews.length)
      return null;

    const repo = repository.telFortuneReview.getList(telFortuneId, pageIndex);

    return repo.path;
  };

  return useSWRInfinite(getKey, (path: string) =>
    HttpClient.miror
      .get<GetTelFortuneReviewListRes>(path)
      .then((res) => res.data)
  );
};
