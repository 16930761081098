import Axios, { AxiosInstance } from "axios";
import { config } from "./HttpClient";
import { mapKeysSnakeCase, mapKeysCamelCase } from "../utils";

const withInterceptors = (axios: AxiosInstance) => {
  axios.interceptors.request.use(
    (config) => {
      config.data = mapKeysSnakeCase(config.data);
      config.params = mapKeysSnakeCase(config.params);

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      response.data = mapKeysCamelCase(response.data);
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export class BaseRepository {
  version: string;

  constructor(options?: { version: "v1" | "v2" }) {
    this.version = options?.version || "v1";
  }

  hosts = {
    miror: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/`,
    media: `https://media-api.miror.jp/`,
    works: `https://works.dears-fortune.jp/api/`,
  };

  clients = {
    miror: () => {
      const axios = Axios.create({
        baseURL: this.hosts.miror,
        ...config,
        withCredentials: true,
      });
      withInterceptors(axios);
      return axios;
    },
    media: Axios.create({
      baseURL: this.hosts.media,
      ...config,
      withCredentials: true,
    }),
    works: Axios.create({
      baseURL: this.hosts.works,
      ...config,
      withCredentials: true,
    }),
  };
}
