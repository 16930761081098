const LINER_GRADIENT_ID = {
  MESSAGE_1: "MESSAGE_1",
  MESSAGE_2: "MESSAGE_2",
  MESSAGE_3: "MESSAGE_3",
};

interface Props {
  className?: string;
}

export const MessageIcon = ({ className }: Props) => {
  return (
    <svg
      height="23"
      viewBox="0 0 27 23"
      width="27"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <linearGradient id={LINER_GRADIENT_ID.MESSAGE_1}>
        <stop offset="0" stopColor="#B647B7" />
        <stop offset="1" stopColor="#F683B6" />
      </linearGradient>
      <linearGradient
        id={LINER_GRADIENT_ID.MESSAGE_2}
        x1="97.848951%"
        x2="5.304953%"
        xlinkHref={`#${LINER_GRADIENT_ID.MESSAGE_1}`}
        y1="100%"
        y2="2.154082%"
      />
      <linearGradient
        id={LINER_GRADIENT_ID.MESSAGE_3}
        x1="100%"
        x2="3.295688%"
        xlinkHref={`#${LINER_GRADIENT_ID.MESSAGE_1}`}
        y1="86.21626%"
        y2="15.343996%"
      />
      <g fill="none" fillRule="evenodd" transform="translate(0 .2)">
        <g fill={`url(#${LINER_GRADIENT_ID.MESSAGE_2})`} fillRule="nonzero">
          <path d="m7.5 8.43330267c-1.03554 0-1.875.858084-1.875 1.91669733 0 1.05846.83946 1.9166667 1.875 1.9166667s1.875-.8582067 1.875-1.9166667c0-1.05861333-.83946-1.91669733-1.875-1.91669733z" />
          <path d="m13.5 8.43330267c-1.0356 0-1.875.858084-1.875 1.91669733 0 1.05846.8394 1.9166667 1.875 1.9166667s1.875-.8582067 1.875-1.9166667c0-1.05861333-.8394-1.91669733-1.875-1.91669733z" />
          <path d="m17.625 10.35c0-1.05861333.8394-1.91669733 1.875-1.91669733s1.875.858084 1.875 1.91669733c0 1.05846-.8394 1.9166667-1.875 1.9166667s-1.875-.8582067-1.875-1.9166667z" />
        </g>
        <path
          d="m19.65105.908914c-4.0413-.35229867-8.1036-.37275333-12.14814-.061134l-.289845.02232533c-3.85695.29716-6.838065 3.58283267-6.838065 7.536686v13.05987537c0 .4046466.208005.7793933.547725.9870066.33972.2076134.76119.2174267 1.109835.0259134l5.866185-3.2226067c.272865-.14996.577815-.2283133.887595-.2283133h13.46466c1.69815 0 3.1533-1.24108 3.4578-2.9489067.61695-3.46104.66555-7.002994.14415-10.48044067l-.1536-1.02477266c-.27975-1.86568334-1.77285-3.29292534-3.6126-3.453312zm-11.97903 2.23234933c3.92448-.302358 7.86648-.28251666 11.78793.05932467l2.43555.21232067c.8046.07013466 1.4574.69426266 1.5798 1.51011866l.1536 1.02477267c.4836 3.22502933.43845 6.50992-.13365 9.7198-.10965.6144067-.6333 1.0610667-1.24425 1.0610667h-13.46466c-.68151 0-1.3524.1725-1.952715.50232l-4.208625 2.3121133v-11.13630867c0-2.750662 2.07393-5.03646333 4.75716-5.24318733z"
          fill={`url(#${LINER_GRADIENT_ID.MESSAGE_3})`}
        />
      </g>
    </svg>
  );
};
