import HttpClient from "shared/repositories/HttpClient";
import { GetFreeFortuneGenreListRes } from "shared/types";

export class FreeFortuneGenreRepository {
  private getListPath() {
    return "/free_fortune/genres";
  }

  public getList() {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneGenreListRes>(path)
          .then((res) => res.data),
    };
  }
}
