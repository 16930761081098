import { FreeFortuneFormData } from "features/無料占いBot/hooks/useFreeFortuneForm";
import clients from "shared/repositories/HttpClient";
import { ID } from "shared/types";

const UserFreeFortuneChatQuestionsRepository = {
  post: async (
    params: Pick<FreeFortuneFormData, "answer"> & {
      id?: ID;
      isSubscription?: boolean;
    }
  ): Promise<boolean> => {
    const url = `/me/user_free_fortune_chat_questions`;
    const { answer, id, isSubscription } = params;
    const data = {
      userFreeFortuneChatQuestion: {
        freeFortuneChatQuestionId: answer,
      },
      id,
      isSubscription,
    };
    const res = await clients.miror.post(url, data);
    return res.status === 201;
  },
};

export default UserFreeFortuneChatQuestionsRepository;
