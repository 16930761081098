import tw from "twin.macro";
import HighLightStarIcon from "shared/assets/icons/HighLightStarIcon.svg";

const StampFrame = tw.li`w-10 h-10 bg-white rounded-full flex-center border-2 border-white border-solid`;

export interface StampCardFrameProps {
  stampCardCols: number;
  stampCardRows: { point: number }[];
  frameNum: number;
  isFilled: boolean;
}

export const StampCardFrame = ({
  stampCardCols,
  stampCardRows,
  frameNum,
  isFilled,
}: StampCardFrameProps) => {
  const isTierCol = frameNum % stampCardCols === 0;
  const tierRowIdx = frameNum / stampCardCols - 1;

  if (isTierCol) {
    return (
      <StampFrame css={isFilled && tw`bg-[#FE4FD0]`}>
        <p tw="font-bold text-[6px] tracking-[.23px] leading-[10px] text-[#FFE4F8] mt-0.5">
          {stampCardRows[tierRowIdx].point}Pt
          <br />
          プレゼント
        </p>
      </StampFrame>
    );
  } else if (isFilled) {
    return (
      <StampFrame tw="bg-[#FE4FD0]" css={{ transform: "rotate(16deg)" }}>
        <HighLightStarIcon
          tw="svg text-white relative right-[1.5px]"
          css={{ transform: "scale(.85)" }}
        />
      </StampFrame>
    );
  } else {
    return (
      <StampFrame>
        <HighLightStarIcon
          tw="svg text-[#FFE4F8] relative right-[1.5px]"
          css={{ transform: "scale(.85)" }}
        />
      </StampFrame>
    );
  }
};
