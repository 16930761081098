interface Props {
  className?: string;
}

export const CloseIcon = ({ className }: Props) => {
  return (
    <svg
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        transform="translate(1 1)"
      >
        <path d="m0 .5 14 14" />
        <path d="m0 .5 14 14" transform="matrix(-1 0 0 1 14 0)" />
      </g>
    </svg>
  );
};
