import { Pagination } from "shared/types";
import { BaseRepository } from "shared/repositories/BaseRepository";

export class TelWaitingFortuneTellerRepository extends BaseRepository {
  constructor() {
    super({ version: "v1" });
  }

  public getTelWaitingFortuneTellerListPath() {
    return `/${this.version}/tel_waiting_fortune_tellers`;
  }

  public getTelWaitingFortuneTellerList(params?: Pagination) {
    return this.clients
      .miror()
      .get(this.getTelWaitingFortuneTellerListPath(), { params })
      .then((res) => res.data);
  }
}
