import dayjs, { Dayjs } from "dayjs";
import FormData from "form-data";
import mapKeys from "lodash/mapKeys";
import mapValues from "lodash/mapValues";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import floor from "lodash/floor";

const _serviceHostFallback = () =>
  typeof window !== "undefined"
    ? window.location.origin
    : process.env.NEXT_PUBLIC_HOST;

export const serviceHost =
  process.env.NEXT_PUBLIC_SERVICE_HOST ?? _serviceHostFallback();

export const mapKeysDeep: any = (data: any, callback: any) => {
  if (data instanceof FormData) {
    return data;
  }
  if (isArray(data)) {
    return data.map((innerData: any) => mapKeysDeep(innerData, callback));
  } else if (isObject(data)) {
    return mapValues(mapKeys(data, callback), (val: any) =>
      mapKeysDeep(val, callback)
    );
  } else {
    return data;
  }
};

export const mapKeysCamelCase = (data: any) =>
  mapKeysDeep(data, (_value: any, key: any) => camelCase(key));

export const mapKeysSnakeCase = (data: any) =>
  mapKeysDeep(data, (_value: any, key: any) => snakeCase(key));

export const registerMock = (name: string) => ({
  onChange: (): void => null,
  onBlur: (): void => null,
  ref: (): void => null,
  name,
});

export const toAmbiguousAge = (date: Dayjs): number => {
  return floor(dayjs().diff(date, "year"), -1);
};

export const convertPlainUrlToHtml = (text: string) => {
  if (!text) return;

  // 既にaタグであるものはURLであっても置換しない
  if (
    text.replace(/\r?\n/g, "").match(/<a\s+.*?href=['"].*?['"].*?>.*?<\/a>/g)
  ) {
    return text;
  } else {
    const regexpUrl = /(https:\/\/(.+?\.)?miror\.jp.*)/g;
    let linkedComment = text.replace(
      regexpUrl,
      '<a href="$1" style="text-decoration: underline;">$1</a>'
    );

    return linkedComment;
  }
};

export const removeHtmlTags = (text: string) => {
  if (!text) return;
  return text.replace(/<(".*?"|'.*?'|[^'"])*?>/g, "");
};

export * from "./deviceInfo";
