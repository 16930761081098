import { forwardRef, ReactNode } from "react";
import { css } from "styled-components";

import { zIndex } from "../../styles";

interface Props {
  children: ReactNode;
  height?: number;
  background?: string;
  className?: string;
}

export const NavigationBase = forwardRef<HTMLDivElement, Props>(
  ({ children, height, background, className }, ref) => {
    return (
      <nav
        ref={ref}
        tw="flex fixed bottom-0 w-full border-t-[0.5px] border-solid border-gray max-w-screen-sm bg-white"
        css={css({
          height,
          zIndex: zIndex.fixed,
          background,
        })}
        className={className}
      >
        {children}
      </nav>
    );
  }
);

NavigationBase.displayName = "NavigationBase";
