import { useRouter } from "next/router";
import dayjs from "dayjs";
import { keys, setCookie } from "../utils/cookie";

const useTracking = () => {
  const { query } = useRouter();
  const clid = query.clid as string;
  if (clid) {
    setCookie(
      null,
      keys.campaign,
      JSON.stringify({
        id: clid,
        timestamp: dayjs().unix(),
      })
    );
  }
};

export default useTracking;
