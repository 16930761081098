import { repository } from "repositories";

import { GetFortuneTellerRes, ID, SWRResponse } from "shared/types";
import useSWR from "swr";

export const useFortuneTeller = (id: ID): SWRResponse<GetFortuneTellerRes> => {
  const repo = repository.fortuneTeller.getObject(id);
  const { data, error, mutate } = useSWR(repo.path, repo.data);

  return {
    fortuneTeller: data?.fortuneTeller,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
