import { repository } from "repositories";
import { GetTagTopicListRes, SWRResponse } from "shared/types";
import useSWR from "swr";

export const useTagTopicList = (
  tagId: number | string | string[]
): SWRResponse<GetTagTopicListRes> => {
  const repo = repository.tagTopic.getList(tagId);
  const { data, error, mutate } = useSWR([repo.path, tagId], repo.data);

  return {
    topics: data?.topics,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
