import { FortuneTeller, Menu, Tag, ChatRoom, User, Category } from ".";
import { MenuTypeV2, Review } from "../types";

export default class Fortune extends Menu {
  type: MenuTypeV2;
  id: number;
  title: string;
  price: number;
  imageUrl: string;
  averageScore: number;
  fortuneTeller?: FortuneTeller;
  isFavorited?: boolean;
  isSetNotify?: boolean;
  isBlocked?: boolean;
  isPublished?: boolean;
  salesQuantity?: number;
  averageVolume?: number;
  averageDeliveryDuration?: number;
  content?: string;
  note?: string;
  sampleChatRoom?: ChatRoom;
  divinationTags?: Tag[];
  sceneTags?: Tag[];
  fortuneReviews?: Review[];
  purchasedUsers?: User[];
  category?: Category;
  sceneTagIds?: number[];
  divinationTagIds?: number[];

  constructor(data?: Partial<Fortune>) {
    super(data);
    Object.assign(this, data);

    if (!data) return;

    this.fortuneTeller = data.fortuneTeller
      ? new FortuneTeller(data.fortuneTeller)
      : null;
    this.divinationTags =
      data?.divinationTags?.map((tag: any) => new Tag(tag)) ?? [];
    this.sceneTags = data?.sceneTags?.map((tag: any) => new Tag(tag)) ?? [];
    this.sampleChatRoom = data.sampleChatRoom
      ? new ChatRoom(data.sampleChatRoom)
      : null;
    this.purchasedUsers =
      data?.purchasedUsers?.map((user) => new User(user)) ?? [];
    this.category = data.category ? new Category(data.category) : null;
  }
}
