const AdminHosts: Record<string, string> = {
  development: "http://localhost:3001",
  ngrok: "https://admin-miror.jp.ngrok.io",
  staging: "https://staging-admin.miror.jp",
  production: "https://admin.miror.jp",
};

export const externalLink = {
  /** ヘルプ */
  help: "https://miror.wraptas.site/",
  /** 運営会社 */
  aboutCompany: "https://raps.tokyo/",
  /** チャット鑑定の流れ */
  howToChat: "https://miror.wraptas.site/5ddc43a0871e49a88e168b4c4d9a28d6",
  /** 電話鑑定の流れ */
  howToTel: "https://miror.wraptas.site/04b7e8bf450c451389dccec1434a7fa9",
  /** コンビニ決済でのお支払い手順 */
  howToPaymentByCvs:
    "https://miror.wraptas.site/44134e99a7ee464a99b2dcb4b027689e",
  /** 銀行ATM（Pay-easy）でのお支払い手順 */
  howToPaymentByAtm:
    "https://miror.wraptas.site/90a13ca1f0844034af5ea01a0e8a3bd5",
  /** LINE通知設定がうまくいかない(LINENotifyの設定方法について)（ユーザー用ガイドライン） */
  howToSettingLineNotifyForUser:
    "https://miror.wraptas.site/8dfd04b03a47496cb4daf987a01d4f09",
  /** LINE通知設定がうまくいかない(LINENotifyの設定方法について)（占い師用ガイドライン） */
  howToSettingLineNotifyForFortuneTeller:
    "https://www.notion.so/LINE-LINENotify-1f0f61b18ccf4cd6b45f7ff30c0d3345",
  /** LINE連携設定について */
  howToLinkLineAccount:
    "https://miror.wraptas.site/04353b00794443f1b5eda990ac61ff31",
  /** 受付枠数管理機能について */
  aboutCapacity:
    "https://magical-barge-c53.notion.site/de3df38289394b4d8343e45f5be88355",
  /** 売上・報酬に関するよくある質問 */
  aboutSales:
    "https://magical-barge-c53.notion.site/6e82130ac54a43c18f6e15f61ee9b09b",
  /** キャンセル返金について */
  aboutCancel: "https://miror.wraptas.site/4e531a69e22644de86deaf3d56a679e5",
  /** 占い師登録 */
  registerAsFortuneTeller: `${
    AdminHosts[process.env.NEXT_PUBLIC_APP_ENV]
  }/sign_in`,
  /** 占い師様-ご利用ガイド- */
  fortuneTellerGuide:
    "https://magical-barge-c53.notion.site/5d37c7e4692b41a78a246e39164d02f8",
  /** ユーザーサポート */
  contactAsCustomer: "https://line.me/R/ti/p/%40174ncqbd",
  /** MIROR占い師様サポート */
  contactAsFortuneTeller: "https://line.me/R/ti/p/%40ais7801m",
  /** サポート窓口について */
  aboutSupport: "https://miror.wraptas.site/50483775e3884e2ea441406a0a59395c",
  /** Twitter おひつじ座 */
  twitteraries: "https://twitter.com/uranai_ohitsujj",
  /** Twitter おうし座 */
  twittertaurus: "https://twitter.com/uranai_oushiza",
  /** Twitter ふたご座 */
  twittergemini: "https://twitter.com/uranai_futagoza",
  /** Twitter かに座 */
  twittercancer: "https://twitter.com/uranai_kanizaa",
  /** Twitter しし座 */
  twitterleo: "https://twitter.com/uranai_shishiza",
  /** Twitter おとめ座 */
  twittervirgo: "https://twitter.com/uranai_otomez",
  /** Twitter てんびん座 */
  twitterlibra: "https://twitter.com/uranai_tenbinza",
  /** Twitter さそり座 */
  twitterscorpio: "https://twitter.com/uranai_sasoriza",
  /** Twitter いて座 */
  twittersagittarius: "https://twitter.com/uranai_itezaa",
  /** Twitter やぎ座 */
  twittercapricorn: "https://twitter.com/uranai_yagizaa",
  /** Twitter みずがめ座 */
  twitteraquarius: "https://twitter.com/uranai_mizugaam",
  /** Twitter うお座 */
  twitterpisces: "https://twitter.com/uranai_uozaa",
  /** リニューアルのお知らせ */
  noticeRenewal:
    "https://magical-barge-c53.notion.site/MIROR-36aa4941cbed41fb9cb2be304713b16f",
  /** サブスクLP離脱POPUP繊維先（LP2） */
  subscriptionLP2PopupLink:
    "https://liff.line.me/1532157909-RYdErGxo/landing?follow=%40kmk3717k&lp=CcMtfg&liff_id=1532157909-RYdErGxo",
  /** サブスクLP離脱POPUP繊維先（LP3） */
  subscriptionLP3PopupLink:
    "https://liff.line.me/1532157909-RYdErGxo/landing?follow=%40kmk3717k&lp=VHVJ6A&liff_id=1532157909-RYdErGxo",
  /** サブスクLP離脱POPUP繊維先（LP4） */
  subscriptionLP4PopupLink:
    "https://liff.line.me/1532157909-RYdErGxo/landing?follow=%40kmk3717k&lp=4GF0MO&liff_id=1532157909-RYdErGxo",
  /** サブスクLP離脱POPUP繊維先（LP5） */
  subscriptionLP5PopupLink:
    "https://liff.line.me/1532157909-RYdErGxo/landing?follow=%40kmk3717k&lp=CFE6sL&liff_id=1532157909-RYdErGxo",
  /** サブスクLP離脱POPUP繊維先（LP10） */
  subscriptionLP10PopupLink:
    "https://liff.line.me/1532157909-RYdErGxo/landing?follow=%40kmk3717k&lp=vXcc0f&liff_id=1532157909-RYdErGxo",
  /** 禁止行為について */
  violations:
    "https://magical-barge-c53.notion.site/453ee22f35554cca9e8b8e6413e9b97d",
  /** サブスクプランについて */
  aboutSubscription:
    "https://miror.wraptas.site/0c45e0b23df048b1b0a7e7617f844a2c",
  /** サブスクプランの決済に失敗し、プランが一時停止状態になった */
  aboutSubscriptionPaused:
    "https://magical-barge-c53.notion.site/93f5a77b9e5844c6ad400ebc84ce2aeb",
  /** MIRORからのメールが届かない */
  missingEmail:
    "https://magical-barge-c53.notion.site/MIROR-c70cd8bfebe74a8cb19e7133ec9287c6",
  /** 占い師様 - MIRORからのメールが届かない */
  missingEmailAdmin:
    "https://miror.wraptas.site/6efc47a39aeb4204ba2498c40bf5362b",
  /** 入金に関するヘルプ */
  howToTransfer:
    "https://magical-barge-c53.notion.site/4b932e236096411f909675c5126c7977",
} as const;
