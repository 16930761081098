import { FormData as PasswordFormData } from "shared/hooks/usePasswordForm";
import { FormData as PasswordWithConfirmationFormData } from "shared/hooks/usePasswordWithConfirmationForm";
import clients from "shared/repositories/HttpClient";

const UserPasswordRepository = {
  sendResetPasswordMail: async (email: string): Promise<boolean> => {
    const url = "/me/send_reset_password_email";
    const params = { email };
    const res = await clients.miror.post(url, params);
    return res.status === 200;
  },
  post: async (
    resetPasswordToken: string,
    userPassword: PasswordFormData
  ): Promise<boolean> => {
    const params = { resetPasswordToken, userPassword };
    const res = await clients.miror.post("/me/password", params);
    return res.status === 200;
  },
  patch: async (
    userPassword: PasswordWithConfirmationFormData
  ): Promise<boolean> => {
    const res = await clients.miror.patch("/me/password", { userPassword });
    return res.status === 200;
  },
};

export default UserPasswordRepository;
