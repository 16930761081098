import { Fortune } from "shared/models/index";
import HttpClient from "shared/repositories/HttpClient";
import clients from "shared/repositories/HttpClient";
import {
  WithPaginationMetaResponse,
  Data,
  ID,
  GetFortuneRes,
  GetFortuneListParams,
  GetFortuneListRes,
  GetFortuneMetaDataRes,
} from "shared/types";

export class FortuneRepositoryV2 {
  private getObjectPath(id: ID) {
    return id ? `/fortunes/${id}` : undefined;
  }

  private getListPath() {
    return `/fortunes`;
  }

  private getMetaDataPath(id: ID) {
    return id ? `/fortunes/${id}/meta_data` : undefined;
  }

  public getObject(id: ID) {
    const path = id ?this.getObjectPath(id) : null;

    return {
      path,
      data: () =>
        HttpClient.miror.get<GetFortuneRes>(path).then((res) => res.data),
    };
  }

  public getList(params?: GetFortuneListParams) {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFortuneListRes>(path, { params })
          .then((res) => res.data),
    };
  }

  public getMetaData(id: ID) {
    const path = this.getMetaDataPath(id);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFortuneMetaDataRes>(path)
          .then((res) => res.data),
    };
  }
}

const FortunesRepository = {
  index: async (
    url: string,
    params?: { limit?: number }
  ): Promise<WithPaginationMetaResponse<{ fortunes: Fortune[] }>> => {
    const res = await clients.miror.get(url, { params });
    const fortunes =
      res.data?.fortunes?.map((fortune: Data) => new Fortune(fortune)) ?? [];
    return { fortunes, meta: res.data?.meta };
  },
  show: async (url: string): Promise<Fortune> => {
    const res = await clients.miror.get(url);
    return new Fortune(res.data?.fortune);
  },
};

export default FortunesRepository;
