import AdminOrder from "./admin_order";
import Menu from "./Menu";

import { User } from "./index";
import { FortuneTeller, Order } from "../types";

export const status = {
  unpaid: "unpaid",
  inactive: "inactive",
  activated: "activated",
  closed: "closed",
  standardCanceled: "standard_canceled",
  firstTimeCanceled: "first_time_canceled",
  exceptionalCanceled: "exceptional_canceled",
  automaticallyCanceled: "automatically_canceled",
} as const;

export type Status = typeof status[keyof typeof status];

export default class ChatRoom {
  id: number;
  status: Status;
  createdAt: string;
  fortuneTeller?: FortuneTeller;
  user?: User;
  menu?: Menu;
  order?: Order;
  // for user
  reviewed?: boolean;
  // for admin
  sentResult?: boolean;
  adminOrder?: AdminOrder;
  closable?: boolean;

  constructor(data?: Partial<ChatRoom>) {
    Object.assign(this, data);

    if (!data) return;

    this.user = new User(data.user);
    this.adminOrder = new AdminOrder(data.adminOrder);
  }

  get isEnabled(): boolean {
    return this.status === status.activated || this.status === status.closed;
  }

  get shouldReview(): boolean {
    return this.status === status.closed && !this.reviewed;
  }
}
