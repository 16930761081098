import HttpClient from "shared/repositories/HttpClient";

import {
  GetFreeFortuneContentListParams,
  GetFreeFortuneContentListRes,
  GetFreeFortuneContentRes,
} from "shared/types";

export class FreeFortuneContentRepository {
  private getObjectPath(uuid?: string | string[]) {
    return uuid ? `/free_fortune/contents/${uuid}` : undefined;
  }

  private getListPath() {
    return `/free_fortune/contents`;
  }

  public getObject(uuid: string | string[]) {
    const path = this.getObjectPath(uuid);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneContentRes>(path)
          .then((res) => res.data),
    };
  }

  public getList(params?: GetFreeFortuneContentListParams) {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneContentListRes>(path, { params })
          .then((res) => res.data),
    };
  }
}
