import { AxiosError } from "axios";

import { ja } from "../utils/locale";

import { useFlashContext } from "./useFlashContext";

export const commonErrorMessage = ja.error.default;

const defaultErrorMessages: Record<number, string | undefined> = {
  400: ja.error.badRequest,
  401: ja.error.unauthorized,
  404: ja.error.notFound,
  422: ja.error.unprocessableEntity,
  500: commonErrorMessage,
};

// see: https://pay.jp/docs/api/#error
interface PayjpError {
  message: string;
  status: number;
  code?: string;
  param?: string;
  type?: string;
}

interface UseErrorMessage {
  handleAxiosError: (error?: AxiosError) => void;
  handlePayjpError: (error?: PayjpError) => void;
}

export const useErrorMessage = (): UseErrorMessage => {
  const { toggleFlash } = useFlashContext();

  const handleAxiosError = (
    error?: AxiosError<{ messages?: string[]; message?: string }>
  ) => {
    if (!error) return;

    const messageFromApi =
      error?.response?.data?.messages?.slice(0, 1)[0] ??
      error?.response?.data?.message?.split(",")?.slice(0, 1)[0];

    const message =
      messageFromApi ??
      defaultErrorMessages[error?.response?.status] ??
      commonErrorMessage;

    toggleFlash({ message });
  };

  const handlePayjpError = (error?: PayjpError) => {
    if (!error) return;

    if (error.message) {
      toggleFlash({ message: error.message });
    } else {
      switch (error.code) {
        case "invalid_number":
          toggleFlash({ message: ja.error.payjp.invalidNumber });
          break;
        case "invalid_cvc":
          toggleFlash({ message: ja.error.payjp.invalidCvc });
          break;
        case "invalid_expiration_date":
          toggleFlash({ message: ja.error.payjp.invalidExpirationDate });
          break;
        case "invalid_expiry_month":
          toggleFlash({ message: ja.error.payjp.invalidExpiryMonth });
          break;
        case "invalid_expiry_year":
          toggleFlash({ message: ja.error.payjp.invalidExpiryYear });
          break;
        case "expired_card":
          toggleFlash({ message: ja.error.payjp.expiredCard });
          break;
        case "card_declined":
          toggleFlash({
            message: ja.error.payjp.cardDeclined,
          });
          break;
        case "processing_error":
          toggleFlash({ message: ja.error.payjp.processingError });
          break;
        default:
          toggleFlash({ message: ja.error.payjp.default });
          break;
      }
    }
  };

  return {
    handleAxiosError,
    handlePayjpError,
  };
};
