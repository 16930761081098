import dayjs, { Dayjs } from "dayjs";
import { MenuTypeV2 } from "../types";

import { Status as chatStatus } from "./ChatRoom";
import { TelFortuneChatRoomStatus } from "./TelFortuneChatRoom";
import { BloodType, Gender } from "./user";

export default class AdminOrder {
  id: number;
  type: MenuTypeV2;
  menuTitle: string;
  menuPrice: number;
  menuThumbnail: string;
  status: string;
  chatRoomId: number;
  chatRoomStatus: chatStatus | TelFortuneChatRoomStatus;
  consultContent: string;
  cancellable: boolean;
  canBlock: boolean;
  userId: number;
  orderedAt: Dayjs;
  orderedWhileWaiting: boolean;
  name: string;
  birthday: Dayjs;
  gender: Gender;
  bloodtype: BloodType;
  twilioPhoneNumber: string;

  constructor(data?: Partial<AdminOrder>) {
    Object.assign(this, data);

    this.orderedAt = data?.orderedAt && dayjs(data.orderedAt);
    this.birthday = data?.birthday && dayjs(data.birthday);
  }

  get typeLabel(): string {
    return this.type === "chat" ? "チャット" : "電話";
  }
}
