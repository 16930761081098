import { Category } from "shared/models/index";
import clients from "shared/repositories/HttpClient";
import { Data } from "shared/types";

const CategoriesRepository = {
  index: async (url: string): Promise<Category[]> => {
    const res = await clients.miror.get(url);
    return res.data?.categories?.map((tag: Data) => new Category(tag)) ?? [];
  },
  show: async (url: string): Promise<Category> => {
    const res = await clients.miror.get(url);
    return new Category(res.data?.category);
  },
};

export default CategoriesRepository;
