import { useRouter } from "next/router";
import { forwardRef } from "react";
import { useUnreadMessages } from "repositories/hooks/useUnreadMessages";
import OutlinedPhoneIcon from "shared/assets/icons/OutlinedPhone.svg";
import { HomeIcon } from "shared/assets/icons/HomeIcon";
import { MessageIcon } from "shared/assets/icons/MessageIcon";
import UserIcon from "shared/assets/icons/UserIcon";
import {
  AppNavigation,
  NavIcon,
} from "shared/components/organisms/AppNavigation";
import tw from "twin.macro";
import { Tooltip } from "shared/components/atoms/Tooltip";
import { Paths } from "utils/routes";
import { useSession } from "next-auth/client";

const inactiveIconCss = tw`svg text-secondary`;

const NavIndexes = {
  HOME: 0,
  CHAT_ROOM: 1,
  NOW: 2,
  MYPAGE: 3,
};

interface IconProps {
  active?: boolean;
}

const ChatRoomIcon = (props: IconProps) => {
  const { unreadMessages } = useUnreadMessages();
  return (
    <NavIcon
      icon={<MessageIcon css={!props.active && inactiveIconCss} {...props} />}
      badgeNumber={unreadMessages.count}
    />
  );
};

const PhoneIcon = (props: IconProps) => {
  return (
    <div tw="relative">
      <OutlinedPhoneIcon
        tw="svg"
        css={!props.active && inactiveIconCss}
        {...props}
      />
      {!props.active && (
        <Tooltip
          tw="absolute -top-8 -right-16"
          color="#FE4FD0"
          triangleProps={{
            direction: "bottom",
            width: 18,
            height: 5,
            style: { position: "absolute", bottom: -10, right: 56 },
          }}
        >
          いますぐ電話で相談OK✨
        </Tooltip>
      )}
    </div>
  );
};

interface ActivateNavDictionary {
  [key: string]:
    | typeof NavIndexes.HOME
    | typeof NavIndexes.CHAT_ROOM
    | typeof NavIndexes.NOW
    | typeof NavIndexes.MYPAGE;
}

const activateNavDictionary: ActivateNavDictionary = {
  "/": NavIndexes.HOME,
  "/chat_rooms": NavIndexes.CHAT_ROOM,
  "/favorites": NavIndexes.MYPAGE,
  "/mypage": NavIndexes.MYPAGE,
  "/categories/[alias]": NavIndexes.HOME,
  "/tags/[id]": NavIndexes.HOME,
  "/tags/[id]/fortunes": NavIndexes.HOME,
  "/tags/[id]/tel_fortunes": NavIndexes.HOME,
  "/fortune_tellers/list": NavIndexes.HOME,
  "/fortune_tellers/[id]/reviews": NavIndexes.HOME,
  "/announcements": NavIndexes.HOME,
  "/announcements/[id]": NavIndexes.HOME,
  "/profile/edit": NavIndexes.MYPAGE,
  "/point_histories": NavIndexes.MYPAGE,
  "/coupon_histories": NavIndexes.MYPAGE,
  "/purchase_histories": NavIndexes.MYPAGE,
  "/users/delete_account": NavIndexes.MYPAGE,
  "/users/delete_account/confirm": NavIndexes.MYPAGE,
  "/now": NavIndexes.NOW,
};

export const UserAppNavigation = forwardRef<HTMLDivElement>((_, ref) => {
  const [session] = useSession();
  const router = useRouter();
  const { pathname: currentPath } = router;

  const activeNavIndex = activateNavDictionary[currentPath];

  // iconのpropsの渡し方冗長なので工夫したい
  const navigations = [
    {
      label: "ホーム",
      icon: (props: IconProps) => {
        return <HomeIcon css={!props.active && inactiveIconCss} {...props} />;
      },
      pathname: Paths.home(),
      index: NavIndexes.HOME,
    },
    {
      label: "チャットルーム",
      icon: ChatRoomIcon,
      pathname: Paths.chatRooms.index(),
      index: NavIndexes.CHAT_ROOM,
    },
    {
      label: "今すぐ相談",
      icon: PhoneIcon,
      pathname: session
        ? Paths.now.callHistory()
        : Paths.now.telWaitingFortuneTellerList(),
      index: NavIndexes.NOW,
    },
    {
      label: "マイページ",
      pathname: Paths.mypage(),
      icon: (props: IconProps) => {
        return <UserIcon css={!props.active && inactiveIconCss} {...props} />;
      },
      index: NavIndexes.MYPAGE,
      authRequired: true,
    },
  ];

  return (
    <AppNavigation
      ref={ref}
      navigations={navigations}
      activeNavIndex={activeNavIndex}
    />
  );
});

UserAppNavigation.displayName = "UserAppNavigation";
