import DeviceDetector from "device-detector-js";

export const isClient = typeof window !== "undefined";

const userAgent = isClient ? window.navigator.userAgent : undefined;
const deviceDetector = new DeviceDetector();
const detectedInfo = deviceDetector.parse(userAgent);

const isSafari = !!detectedInfo.client?.name.match(/Safari/);
const isChrome = !!detectedInfo.client?.name.match(/Chrome/);
const isLine = !!detectedInfo.client?.name.match(/Line/);
const isInstagram = !!detectedInfo.client?.name.match(/Instagram App/);
/// 本番環境以外では推奨環境に関するメッセージを常に表示
const isSupportedBrowser =
  process.env.NEXT_PUBLIC_APP_ENV === "production"
    ? isChrome || isSafari || isLine || isInstagram
    : false;

const isMobile = detectedInfo.device?.type === "smartphone";

export const deviceInfo = {
  ...detectedInfo,
  client: {
    ...detectedInfo.client,
    isSafari,
    isChrome,
    isLine,
    isInstagram,
    isSupportedBrowser,
  },
  device: {
    ...detectedInfo.device,
    isMobile,
  },
};

export type DeviceInfo = typeof deviceInfo;
