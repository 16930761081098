import { ZodiacSign, LuckType } from "../types";
import { LocalizeValue } from ".";

export const zodiacs = {
  aries: { localized: "おひつじ座", period: "Mar.21 ~ Apr.19" },
  taurus: { localized: "おうし座", period: "Apr.20 ~ May.20" },
  gemini: { localized: "ふたご座", period: "May.21 ~ Jun.21" },
  cancer: { localized: "かに座", period: "Jun.22 ~ Jul.22" },
  leo: { localized: "しし座", period: "Jul.23 ~ Aug.22" },
  virgo: { localized: "おとめ座", period: "Aug.23 ~ Sep.22" },
  libra: { localized: "てんびん座", period: "Sep.23 ~ Oct.23" },
  scorpio: { localized: "さそり座", period: "Oct.24 ~ Nov.22" },
  sagittarius: { localized: "いて座", period: "Nov.23 ~ Dec.21" },
  capricorn: { localized: "やぎ座", period: "Dec.22 ~ Jan.19" },
  aquarius: { localized: "みずがめ座", period: "Jan.20 ~ Feb.18" },
  pisces: { localized: "うお座", period: "Feb.19 ~ Mar.20" },
};

class Content {
  id: number;
  luckType: LocalizeValue<LuckType>;
  rate: number;
  body: string;

  static localizeLuckType(value: LuckType): string {
    switch (value) {
      case "total":
        return "総合運";
      case "love":
        return "恋愛運";
      case "money":
        return "金運";
      case "work":
        return "仕事運";
    }
  }

  constructor(data?: Partial<Content> & { luckType: LuckType }) {
    Object.assign(this, data);

    if (!data) return;

    this.luckType = new LocalizeValue(data.luckType, Content.localizeLuckType);
  }
}

export default class DailyHoloscope {
  id: number;
  title: string;
  rank: number;
  zodiacSign: LocalizeValue<ZodiacSign>;
  contents: Content[];

  static localizeZodiacSign(zodiacSign: ZodiacSign): string {
    return zodiacs[zodiacSign].localized;
  }

  constructor(data?: Partial<DailyHoloscope> & { zodiacSign: ZodiacSign }) {
    Object.assign(this, data);

    this.contents = data.contents.map((content: any) => new Content(content));
    this.zodiacSign = new LocalizeValue(
      data.zodiacSign,
      DailyHoloscope.localizeZodiacSign
    );
  }

  get period(): string {
    return zodiacs[this.zodiacSign.rawValue].period;
  }
}
