import { repository } from "repositories";
import {
  GetCategoryFortuneListParams,
  GetFortuneListRes,
  SWRResponse,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWR from "swr";

export const useCategoryFortuneList = (
  alias: string,
  params: GetCategoryFortuneListParams
): SWRResponse<WithPaginationMetaResponse<GetFortuneListRes>> => {
  const repo = repository.categoryFortune.getList(alias, params);
  const key = alias ? [repo.path, ...Object.values(params)] : null;
  const { data, error, mutate } = useSWR(key, repo.data, {
    fallbackData: repo.fallbackData,
  });

  return {
    ...data,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
