export const resultTypes = {
  default: "default",
  question: "question",
  subscription_for_line: "subscription_for_line",
  subscription_for_web: "subscription_for_web",
  subscription_with_trial_for_web: "subscription_with_trial_for_web",
  subscription_from_media: "subscription_from_media",
  for_tel_fortune: "for_tel_fortune",
  for_tel_fortune_v2: "for_tel_fortune_v2",
  for_tel_fortune_v3: "for_tel_fortune_v3",
} as const;

export type ResultType = keyof typeof resultTypes;

export default class FreeFortuneChatQuestion {
  id: number;
  freeFortuneThemeId: number;
  resultType: ResultType;
  body: string;
  description: string;
  imageUrl: string;
  thumbnailUrl: string;

  constructor(data?: Partial<FreeFortuneChatQuestion>) {
    Object.assign(this, data);

    if (!data) return;
  }
}
