import { VFC } from "react";
import tw from "twin.macro";

interface Props {
  amount: number;
  className?: string;
}

export const Badge: VFC<Props> = ({ amount, className }) => {
  return (
    <div
      tw="min-w-[19px] h-[19px] rounded-full bg-gradient-theme-to-l border-2 border-solid border-white flex-center text-base text-2xs leading-none text-white whitespace-nowrap"
      css={amount >= 10 && tw`px-0.5`}
      className={className}
    >
      <span tw="mt-[1px]">{amount >= 100 ? "99+" : amount}</span>
    </div>
  );
};
