import { FC } from "react";
import styled, { css } from "styled-components";

const elevationExtraThinCss = css({
  boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.04)",
  border: "none",
});

const elevationThinCss = css({
  boxShadow: "0 0 20px 0 rgba(207, 206, 206, .5)",
  border: "none",
});

const elevationMidCss = css({
  boxShadow: "0 0 4px 0 rgba(131, 141, 155, .35)",
  border: "none",
});

const elevationBoldCss = css({
  boxShadow: "0px 2px 27px rgba(179, 176, 176, .5)",
  border: "none",
});

const elevationExtraBoldCss = css({
  boxShadow: "0 2px 27px 0 rgba(143, 143, 143, 0.5)",
  border: "none",
});

interface CardContentProps {
  className?: string;
}

export const CardContent: FC<CardContentProps> = ({ children, ...rest }) => {
  return (
    <div tw="p-[11px]" {...rest}>
      {children}
    </div>
  );
};

export interface CardProps {
  elevation?: 1 | 2 | 3 | 4 | 5;
  className?: string;
}

const Base = styled.div`
  background: #ffffff;
  border: 1px solid #f1f3f7;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  position: relative;
`;

/**
 * NOTE:
 *  - カードみたいな汎用的なUIパーツは3種類くらいにしておきたい感あるのでこれ以上増えるようならデザインと相談
 *  - 増えるとしたら色は同じで透明度を可変にするとか、shadow-radiusを可変にするとか、それも増えるとデザイン的な一貫性損なわれる気がするけど全てがバラバラよりはマシな気が
 */
export const Card: FC<CardProps> = ({ children, elevation, ...rest }) => {
  switch (elevation) {
    case 1:
      return (
        <Base css={elevationExtraThinCss} {...rest}>
          {children}
        </Base>
      );
    case 2:
      return (
        <Base css={elevationThinCss} {...rest}>
          {children}
        </Base>
      );
    case 3:
      return (
        <Base css={elevationMidCss} {...rest}>
          {children}
        </Base>
      );
    case 4:
      return (
        <Base css={elevationBoldCss} {...rest}>
          {children}
        </Base>
      );
    case 5:
      return (
        <Base css={elevationExtraBoldCss} {...rest}>
          {children}
        </Base>
      );
    default:
      return <Base {...rest}>{children}</Base>;
  }
};
