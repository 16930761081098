import { useModal } from "mui-modal-provider";
import { BaseModalProps } from "shared/components/organisms/modal/BaseModal";
import { useCallback, useRef } from "react";
import { HasSubscriptionsModal } from "../components/HasSubscriptionsModal";

export const useHasSubscriptionsModal = () => {
  const { showModal, destroyModal, ...rest } = useModal();
  const modal = useRef(null);

  const handleDismiss = useCallback(() => {
    destroyModal(modal.current.id);
    modal.current = null;
  }, [destroyModal]);

  const showHasSubscriptionsModal = () => {
    modal.current = showModal<BaseModalProps>(HasSubscriptionsModal, {
      onDismiss: handleDismiss,
    });
  };

  return {
    showModal: showHasSubscriptionsModal,
    ...rest,
  };
};
