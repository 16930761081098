import { repository } from "repositories";
import {
  GetCategoryFortuneListParams,
  GetTelFortuneListRes,
  ID,
  SWRResponse,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWR from "swr";

interface Params extends GetCategoryFortuneListParams {
  tagId: ID;
}

export const useTagTelFortuneList = ({
  tagId,
  ...params
}: Params): SWRResponse<WithPaginationMetaResponse<GetTelFortuneListRes>> => {
  const repo = repository.tagTelFortune.getList(tagId, params);
  const { data, error, mutate } = useSWR(
    [repo.path, ...Object.values(params)],
    repo.data
  );

  return {
    ...data,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
