import { repository } from "repositories";
import HttpClient from "shared/repositories/HttpClient";
import {
  GetFortuneTellerReviewListRes,
  ID,
  Pagination,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWRInfinite from "swr/infinite";

export const useFortuneTellerReviewList = (
  fortuneTellerId: ID,
  pagination?: Pagination
) => {
  const getKey = (
    pageIndex: number,
    previousPageData: WithPaginationMetaResponse<GetFortuneTellerReviewListRes>
  ) => {
    if (previousPageData && !previousPageData.reviews.length) return null;

    const repo = repository.fortuneTellerReview.getList(fortuneTellerId, {
      page: pageIndex + 1,
      limit: pagination?.limit,
    });

    return repo.path;
  };

  return useSWRInfinite(getKey, (path: string) =>
    HttpClient.miror
      .get<GetFortuneTellerReviewListRes>(path)
      .then((res) => res.data)
  );
};
