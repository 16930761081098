import { MessageParams } from "yup/lib/types";

const validation = (str: string) => {
  str = str == null ? "" : str;
  return !!str.match(/^\d+-\d+-\d+$/);
};

const error = ({ label }: MessageParams): string =>
  `${label}のフォーマットに問題があります`;

export const dateValidator = {
  name: "date" as const,
  validation: function () {
    return this.test(null, error, validation);
  },
};
