import { forwardRef } from "react";
import { Triangle, TriangleProps } from "./Triangle";

interface Props extends TriangleProps {
  color?: TriangleProps["color"];
  triangleProps?: Omit<TriangleProps, "color">;
}

/**
 * @param color TriangleProps["color"]
 * @param triangleProps Omit<TriangleProps, "color" | "direction">
 */
export const Tooltip = forwardRef<HTMLDivElement, Props>(
  ({ children, className, color = "#E271B6", triangleProps }, ref) => {
    const size = triangleProps?.size || 4;

    return (
      <div
        tw="bg-theme rounded text-white text-[11px] tracking-[0.29px] leading-none px-3 py-[5px]"
        className={className}
        css={{ background: color }}
        ref={ref}
      >
        <div tw="relative">
          <p tw="mt-0.5 whitespace-nowrap">{children}</p>
          <div tw="absolute w-full flex-center">
            <Triangle color={color} size={size} {...triangleProps} />
          </div>
        </div>
      </div>
    );
  }
);

Tooltip.displayName = "Tooltip";
