import HttpClient from "shared/repositories/HttpClient";
import { GetTopicCategoryListRes } from "shared/types";

export class TopicCategoryRepository {
  private getListPath() {
    return `/media/categories`;
  }

  public getList(mediaType: "press" | "miracle") {
    const path = this.getListPath();
    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTopicCategoryListRes>(path, { params: { mediaType } })
          .then((res) => res.data),
    };
  }
}
