import {
  queries as sharedQueries,
  Queries as SharedQueryType,
} from "shared/utils/locale";
import { ja } from "utils/locale";

export const queries = {
  keys: {
    ...sharedQueries.keys,
    email: "email",
    password: "password",
    receiveCoupon: "receiveCoupon",
    deleteAccount: "deleteAccount",
    socialAccount: "socialAccount",
  },
  values: {
    ...sharedQueries.values,
    email: {
      sendConfirmation: "sendConfirmation",
      confirmed: "confirmed",
      confirmationFailed: "confirmationFailed",
    },
    password: {
      updated: "updated",
      reset: "reset",
      sentSetPasswordMail: "sentSetPasswordMail",
    },
    receiveCoupon: {
      success: "success",
      registered: "registered",
      noCampaignId: "noCampaignId",
    },
    deleteAccount: {
      success: "success",
    },
    socialAccount: {
      linkedToLoggedInUser: "linkedToLoggedInUser",
    },
  },
  messages: {
    ...sharedQueries.messages,
    email: {
      sendConfirmation: ja.email.sendConfirmation,
      confirmed: ja.email.confirmed,
      confirmationFailed: ja.email.confirmationFailed,
    },
    password: {
      updated: ja.password.updated,
      reset: ja.password.reset,
      sentSetPasswordMail: ja.password.sentSetPasswordMail,
    },
    receiveCoupon: {
      success: ja.coupon.received,
      registered: ja.account.registered,
      noCampaignId: ja.coupon.noCampaignId,
    },
    deleteAccount: {
      success: ja.account.deleted,
    },
    socialAccount: {
      linkedToLoggedInUser: ja.socialAccount.linkedToLoggedInUser,
    },
  },
} as const;

interface UserQueryType {
  email: keyof typeof queries.values[typeof queries.keys.email];
  password: keyof typeof queries.values[typeof queries.keys.password];
  receiveCoupon: keyof typeof queries.values[typeof queries.keys.receiveCoupon];
  deleteAccount: keyof typeof queries.values[typeof queries.keys.deleteAccount];
  socialAccount: keyof typeof queries.values[typeof queries.keys.socialAccount];
}

export type Queries = UserQueryType & SharedQueryType;
