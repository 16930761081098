import HttpClient from "shared/repositories/HttpClient";
import {
  EmailSignupParams,
  GetSubscriptionListRes,
  ID,
  KarteFormData,
  Pagination,
  SignInUser,
  Subscription,
} from "shared/types";
import { UserFormData } from "./users";

class SubscriptionKarteFormData extends UserFormData {
  constructor({ consultContent, ...userParams }: KarteFormData) {
    super(userParams);
    this.append(`subscription[consult_content]`, consultContent);
  }
}

interface SubscribePlanParams extends Partial<KarteFormData> {
  subscriptionPlanId: ID;
  campaignId?: string;
  prorate?: string;
}

interface CreateSubscriptionRes {
  subscription: Subscription;
}

export class SubscriptionRepository {
  private subscribeWithSignupPath() {
    return `/subscriptions/subscribe_with_signup`;
  }

  private subscribePlanPath() {
    return `/subscriptions`;
  }

  private getListPath() {
    return `/subscriptions`;
  }

  private updateSubscriptionPath(id: ID) {
    return id ? `/subscriptions/${id}` : null;
  }

  private deleteSubscriptionPath(id: ID) {
    return id ? `/subscriptions/${id}/delete` : null;
  }

  private reserveDeleteSubscriptionPath(id: ID) {
    return id ? `/subscriptions/${id}/reserve_delete` : null;
  }

  public subscribeWithSignup(
    payjpToken: string,
    subscribePlanParams: SubscribePlanParams,
    emailSignupParams: EmailSignupParams
  ) {
    const path = this.subscribeWithSignupPath();
    const req = async () =>
      await HttpClient.miror.post<{
        user: SignInUser;
        subscription: Subscription;
      }>(path, {
        payjpToken,
        subscription: subscribePlanParams,
        user: emailSignupParams,
      });

    return {
      path,
      req,
    };
  }

  public subscribePlan(params: SubscribePlanParams) {
    const path = this.subscribePlanPath();
    const req = HttpClient.miror.post<CreateSubscriptionRes>(path, {
      subscription: params,
    });

    return {
      path,
      req,
    };
  }

  public getList(params?: Pagination) {
    const path = this.getListPath();
    const fetcher = async () =>
      await HttpClient.miror
        .get<GetSubscriptionListRes>(path, { params })
        .then((res) => res.data);

    const fallbackData = { subscriptions: [] as Subscription[] };

    return {
      path,
      fetcher,
      fallbackData,
    };
  }

  public updateSubscription(id: ID, params: KarteFormData) {
    const path = this.updateSubscriptionPath(id);
    const req = async () =>
      HttpClient.miror
        .patch(path, new SubscriptionKarteFormData(params))
        .then((res) => res.data);

    return {
      path,
      req,
    };
  }

  public deleteSubscription(id: ID) {
    const path = this.deleteSubscriptionPath(id);
    const req = async () => {
      return HttpClient.miror.patch(path).then((res) => res.data);
    };

    return {
      path,
      req,
    };
  }

  public reserverDeleteSubscription(id: ID) {
    const path = this.reserveDeleteSubscriptionPath(id);
    const req = async () => {
      return HttpClient.miror.patch(path).then((res) => res.data);
    };

    return {
      path,
      req,
    };
  }
}
