import { useSession } from "next-auth/client";
import { UserCoupons } from "repositories";
import { UserCoupons as UserCouponsType } from "repositories/user_coupons";
import { SWRResponse } from "shared/types";
import useSWR from "swr";

export const useUserCoupons = (): SWRResponse<UserCouponsType> => {
  const [session] = useSession();
  const { data, error, mutate, isValidating } = useSWR(
    session ? "/me/user_coupons" : null,
    UserCoupons.index,
    { fallbackData: { validCoupons: [], expiredCoupons: [] } }
  );
  return {
    ...data,
    isLoading: isValidating,
    isError: error,
    mutate,
  };
};
