import dayjs from "dayjs";
import { useRouter } from "next/router";
import { parseCookies } from "nookies";

/**
 * NextAuthを利用したソーシャルログインではブラウザのcookieをAPIサーバーに送信することができないため、
 * NextAuthのSignIn関数のcallbackUrlに指定した空ページでPOSTリクエストを送信する
 * @returns
 *  - topicId [string] 閲覧したメディアの記事ID
 *  - readTopicAt [string] メディア記事を閲覧した日時
 *  - campaignId [string] 獲得ページのURLにクエリパラメータとして付属するキャンペーンID
 *  - clickedCampaignAt [string] 獲得ページを閲覧した日時
 *  - channelTalkUuid [string] 非ログイン状態でチャネルトークが起動した際に生成されるUUID
 */
export const useAcquisitionData = () => {
  const { query } = useRouter();
  const cookies = parseCookies();

  const campaign = cookies["miror.campaign"];
  const topicId = cookies["topic_id"];
  const readTopicAt = cookies["date"];
  const channelTalkUuid = cookies["miror.channelTalk"];

  const campaignId = campaign
    ? campaign.match(/"id":"(.*?)"/)[1]
    : (query.clid as string);
  const clickedCampaignAt = campaign
    ? campaign.match(/"timestamp":(.*?)}/)[1]
    : null;

  return {
    topicId,
    readTopicAt,
    campaignId,
    clickedCampaignAt: clickedCampaignAt
      ? dayjs.unix(Number(clickedCampaignAt)).toISOString()
      : null,
    channelTalkUuid,
  };
};
