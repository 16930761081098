import User from "./user";

export default class BlockList {
  id: number;
  user: User;
  isEnabled: boolean;

  constructor(data?: Partial<BlockList>) {
    Object.assign(this, data);

    this.user = new User(data.user);
  }
}
