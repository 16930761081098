import { repository } from "repositories";
import { ID, GetFortuneRes } from "shared/types";
import useSWR from "swr";
import { SWRResponse } from "shared/types";

export const useFortune = (id: ID): SWRResponse<GetFortuneRes> => {
  const repo = repository.fortune.getObject(id);
  const { data, error, mutate } = useSWR(repo.path, repo.data);

  return {
    fortune: data?.fortune,
    isLoading: !data && !error,
    isError: error,
    mutate,
  };
};
