import { ID, Pagination, PaginationMetaDataV2 } from "shared/types";
import { BaseRepository } from "shared/repositories/BaseRepository";

export interface GetCallEndNotificationListParams extends Pagination {
  fortuneTellerIds?: ID[];
}

interface SubscribeCallEndNotificationParams {
  fortuneTellerId: number;
}

interface CallEndNotification {}

interface GetCallEndNotificationListRes {
  callEndNotifications: CallEndNotification[];
  meta: PaginationMetaDataV2;
}

export class CallEndNotificationRepository extends BaseRepository {
  constructor() {
    super({ version: "v1" });
  }

  public getCallEndNotificationListPath() {
    return `/${this.version}/call_end_notifications`;
  }

  public getCallEndNotificationPath(id: number) {
    return id ? `/${this.version}/call_end_notifications/${id}` : null;
  }

  public getCallEndNotificationList(params?: GetCallEndNotificationListParams) {
    return this.clients
      .miror()
      .get<GetCallEndNotificationListRes>(
        this.getCallEndNotificationListPath(),
        { params }
      )
      .then((res) => res.data);
  }

  public getCallEndNotification(id: number) {
    return this.clients.miror().get(this.getCallEndNotificationPath(id));
  }

  async subscribeCallEndNotification(
    params: SubscribeCallEndNotificationParams
  ) {
    return await this.clients
      .miror()
      .post(`/${this.version}/call_end_notifications`, params);
  }
}
