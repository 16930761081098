import { FortuneTeller, Order } from "../types";
import AdminOrder from "./admin_order";
import Menu from "./Menu";
import User from "./user";

export const telFortuneChatRoomStatus = {
  /** 鑑定開始前 */
  inactive: "inactive",
  /** 日程調整中 */
  fixingSchedule: "fixing_schedule",
  /** 日程再調整中 */
  rescheduling: "rescheduling",
  /** 日程確定 */
  fixedSchedule: "fixed_schedule",
  /** 呼び出し中 */
  ringing: "ringing",
  /** 占い師が電話に応答 */
  adminAnswered: "admin_answered",
  /** 占い師に原因があり呼び出しが中止されたとき */
  adminStoppedRinging: "admin_stopped_ringing",
  /** ユーザーに原因があり呼び出しが中止されたとき */
  userStoppedRinging: "user_stopped_ringing",
  /** twilioからレコーディングコールバックが返ってきたとき */
  endPhoneCall: "end_phone_call",
  /** 「途中で電話が切れてしまった」ボタンが押された時 */
  cutOff: "cut_off",
  /** 「着信があったが出られなかった」ボタンが押された時 */
  adminMissCall: "admin_miss_call",
  /** 「繋がったが会話できなかった」ボタンが押された時 */
  failedTalking: "failed_talking",
  /** 「無事、電話鑑定が完了した」ボタンが押された時 */
  succeededTalking: "succeeded_talking",
  /** 鑑定終了 */
  closed: "closed",
  /** キャンセル */
  standardCanceled: "standard_canceled",
  /** 鑑定終了後のキャンセル */
  afterEndCanceled: "after_end_canceled",
  /** 例外キャンセル */
  exceptionalCanceled: "exceptional_canceled",
  /** 自動キャンセル */
  automaticallyCanceled: "automatically_canceled",
} as const;

export type TelFortuneChatRoomStatus =
  typeof telFortuneChatRoomStatus[keyof typeof telFortuneChatRoomStatus];

export default class TelFortuneChatRoom {
  id: number;
  status: TelFortuneChatRoomStatus;
  createdAt: string;
  orderId?: number;
  fortuneTeller?: FortuneTeller;
  user?: User;
  menu?: Menu;
  // for user
  order?: Order;
  reviewed?: boolean;
  // for admin
  adminOrder?: AdminOrder;
  closable?: boolean;
  sentResult?: boolean;

  constructor(data?: Partial<TelFortuneChatRoom>) {
    Object.assign(this, data);

    if (!data) return;

    this.user = new User(data.user);
    this.adminOrder = new AdminOrder(data.adminOrder);
  }

  get isEnabled(): boolean {
    return !Array<TelFortuneChatRoomStatus>(
      telFortuneChatRoomStatus.standardCanceled,
      telFortuneChatRoomStatus.afterEndCanceled,
      telFortuneChatRoomStatus.exceptionalCanceled,
      telFortuneChatRoomStatus.automaticallyCanceled
    ).includes(this.status);
  }

  get shouldReview(): boolean {
    return this.status === telFortuneChatRoomStatus.closed && !this.reviewed;
  }
}
