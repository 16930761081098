import { useModal } from "mui-modal-provider";
import { HasUnpaidOrdersModal } from "../components/HasUnpaidOrdersModal";
import { BaseModalProps } from "shared/components/organisms/modal/BaseModal";
import { useCallback, useRef } from "react";

export const useHasUnpaidOrdersModal = () => {
  const { showModal, destroyModal, ...rest } = useModal();
  const modal = useRef(null);

  const handleDismiss = useCallback(() => {
    destroyModal(modal.current.id);
    modal.current = null;
  }, [destroyModal]);

  const showHasUnpaidOrdersModal = () => {
    modal.current = showModal<BaseModalProps>(HasUnpaidOrdersModal, {
      onDismiss: handleDismiss,
    });
  };

  return {
    showModal: showHasUnpaidOrdersModal,
    ...rest,
  };
};
