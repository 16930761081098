import clients from "shared/repositories/HttpClient";
import { KarteFormData } from "shared/types";

const FortuneApplicationsRepository = {
  patch: async (
    id: string | string[] | number,
    params: KarteFormData
  ): Promise<boolean> => {
    const path = `/fortune_applications/${id}`;
    const data = { fortuneApplication: params };

    const res = await clients.miror.patch(path, data);
    return res.status === 200;
  },
};

export default FortuneApplicationsRepository;
