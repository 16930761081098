import { useSession } from "next-auth/client";
import dynamic from "next/dynamic";
import Link from "next/link";
import { forwardRef, ReactNode } from "react";
import CouponIcon from "shared/assets/icons/coupon.svg";
import AnnouncementIcon from "shared/assets/icons/announcement.svg";
import UserIcon from "shared/assets/icons/UserIcon";
import { Header, HeaderProps } from "shared/components/atoms/Header";
import { Logo } from "shared/components/atoms/Logo";
import tw, { css } from "twin.macro";
import { Paths } from "utils/routes";
import { Avatar } from "shared/components/atoms/Avatar";
import { useMe } from "repositories/hooks";

const Button = dynamic(
  async () => (await import("shared/components/atoms/Button")).Button
);

const buttonCss = tw`h-8! text-xs! leading-none!`;

const iconButtonCss = tw`flex-center flex-col font-base text-[6px] text-center`;

const iconLabelCss = tw`font-semibold`;

const transparentHeaderCss = tw`bg-[transparent] border-[transparent]`;

const IconWrapper = tw.div`relative mb-1`;

const gradient = css({
  background: "radial-gradient(circle at top, #FF4CE1, #FF9892)",
});

const Badge = tw.div`w-3 h-3 rounded-full border-2 border-solid border-white absolute bottom-0 -right-1`;

interface Props extends Omit<HeaderProps, "children"> {
  disabled?: boolean;
  transparent?: boolean;
  children?: ReactNode;
}

const DefaultParts = ({ transparent = false }: Props) => {
  const [session] = useSession();
  const { me } = useMe();

  return (
    <>
      {!session ? (
        <>
          <Link href={Paths.users.signIn.index()} passHref>
            <a tw="mr-1">
              <Button
                variant="outline"
                css={[
                  buttonCss,
                  transparent &&
                    tw`bg-[transparent]! border-white! text-white!`,
                ]}
              >
                ログイン
              </Button>
            </a>
          </Link>
          <Link href={Paths.users.signIn.index()}>
            <a>
              <Button
                tw="bg-theme!"
                css={[buttonCss, transparent && tw`bg-white! text-theme!`]}
              >
                新規登録
              </Button>
            </a>
          </Link>
        </>
      ) : (
        <>
          <Link href={Paths.couponHistories.index()} passHref>
            <a tw="relative" css={[iconButtonCss, iconLabelCss]}>
              <IconWrapper>
                <CouponIcon />
                <Badge css={gradient} />
              </IconWrapper>
              保有クーポン
            </a>
          </Link>
          <Link href={Paths.announcements.index()} passHref>
            <a tw="ml-3" css={[iconButtonCss, iconLabelCss]}>
              <IconWrapper>
                <AnnouncementIcon />
              </IconWrapper>
              お知らせ
            </a>
          </Link>
          <Link href={Paths.mypage()} passHref>
            <a tw="ml-3" css={[iconButtonCss, iconLabelCss]}>
              {me?.imageUrl ? (
                <Avatar src={me.imageUrl} size={36} tw="mb-0.5" />
              ) : (
                <>
                  <IconWrapper>
                    <UserIcon tw="svg text-black" />
                    <Badge tw="-bottom-0.5" css={gradient} />
                  </IconWrapper>
                  アカウント
                </>
              )}
            </a>
          </Link>
        </>
      )}
    </>
  );
};

export const AppHeader = forwardRef<HTMLDivElement, Props>(
  ({ transparent = false, disabled, children, ...headerProps }, ref) => {
    return (
      <Header
        ref={ref}
        tw="h-14 py-0 px-4"
        css={[transparent && transparentHeaderCss]}
        {...headerProps}
      >
        <Logo color={transparent ? "white" : "theme"} disabled={disabled} />
        <div tw="flex flex-1 justify-end items-end">
          {children ? children : <DefaultParts transparent={transparent} />}
        </div>
      </Header>
    );
  }
);

AppHeader.displayName = "AppHeader";
