import { valueOf } from "../utility";

export const fortuneTellerTelFortuneListSortOrder = {
  salesQuantity: "sales_quantity",
  costPerMinute: "cost_per_minute",
} as const;

export type FortuneTellerTelFortuneListSortOrder = valueOf<typeof fortuneTellerTelFortuneListSortOrder>;

export interface GetFortuneTellerTelFortuneListParams {
  limit?: number;
  orderBy?: FortuneTellerTelFortuneListSortOrder;
}
