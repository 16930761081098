import { useEffect, useState } from "react";
import ChatRoomsRepository from "repositories/chat_rooms";
import MessagesRepository from "repositories/messages";
import SampleChatRoomMessagesRepository from "repositories/sample_chat_room_messages";
import SampleChatRoomsRepository from "repositories/sample_chat_rooms";
import TelFortuneChatRoomsRepository from "repositories/tel_fortune_chat_rooms";
import TelFortuneMessagesRepository from "repositories/tel_fortune_messages";
import {
  ChatRoom,
  Message,
  SampleChatRoom,
  TelFortuneChatRoom,
  TelFortuneMessage,
} from "shared/models/index";
import { ChatRoomType, ID, SWRResponse, TODO_Transfer } from "shared/types";
import useSWR, { SWRConfiguration, MutatorCallback } from "swr";
import { useSwrConfig } from "shared/lib/swr/config";

interface Response<T, U> {
  chatRoom: T;
  messages: U;
}

type ChatRoomGenerics = ChatRoom | TelFortuneChatRoom | SampleChatRoom;
type MessageGenerics = Message[] | TelFortuneMessage[];
type ChatRoomSWRResponse<T, U> = SWRResponse<Response<T, U>> & {
  mutateChatRoom: MutatorCallback;
  mutateMessage: (nextMessages?: Message[]) => void;
};

export const useChatRoom = <T = ChatRoomGenerics, U = MessageGenerics>(
  id: ID,
  type: ChatRoomType,
  config?: SWRConfiguration
): ChatRoomSWRResponse<T, U> => {
  const keyAndFetcher = {
    chat: {
      key: id ? [`/chat_rooms`, id] : null,
      fetcher: {
        room: () => ChatRoomsRepository.show(id),
        messages: () => MessagesRepository.get(id),
      },
    },
    tel: {
      key: id ? [`/tel_fortune_chat_rooms`, id] : null,
      fetcher: {
        room: () => TelFortuneChatRoomsRepository.show(id),
        messages: () => TelFortuneMessagesRepository.get(id),
      },
    },
    sample: {
      key: id ? [`/sample_chat_rooms`, id] : null,
      fetcher: {
        room: () => SampleChatRoomsRepository.show(id),
        messages: () => SampleChatRoomMessagesRepository.get(id),
      },
    },
  };

  const { key, fetcher } = keyAndFetcher[type];

  const defaultConfig = useSwrConfig();
  const chatRoom = useSWR(key, fetcher.room, { ...defaultConfig, ...config });
  const messages = useSWR(id ? ["/messages", id] : null, fetcher.messages, {
    ...defaultConfig,
    ...config,
  });

  const [localMessages, setLocalMessages] = useState(messages.data);

  const mutateMessage = (nextMessages?: Message[]) => {
    messages.mutate(nextMessages);
    if (nextMessages) setLocalMessages(nextMessages);
  };

  /**
   * - ローカルミューテーションとポーリングの相性が悪い
   * - mutateで再検証の結果を待たずにローカルデータを更新しても、直前のポーリング結果によって上書きされてしまう
   *   - ① データを取得する(例としてサーバーには30件のデータが存在するものとする)
   *   - ② ポーリング(サーバー上のデータに変更はないものとする)
   *   - ③ ②のポーリングが完了する前にローカルミューテーションでメッセージオブジェクトを配列末尾に追加する(ローカルのデータ配列は31件となる)
   *   - ④ ②のポーリングが完了し、ローカルデータが更新される(②時点ではサーバー上のデータは30件なのでローカルデータも30件に上書きされる)
   * - 以上の相性を踏まえて、ローカルデータをstateとして持ち、ポーリングの結果としてリモートデータの数がローカルデータの数よりも少ない時は、ローカルデータを更新しないようにする
   */
  useEffect(() => {
    if (!localMessages || localMessages?.length <= messages.data?.length) {
      setLocalMessages(messages.data);
    }
  }, [messages, localMessages]);

  const isError = !!chatRoom.error || !!messages.error;
  const isLoading = !isError && (!chatRoom.data || !messages.data);

  return {
    chatRoom: chatRoom.data,
    messages: localMessages,
    isLoading,
    isError,
    mutateChatRoom: chatRoom.mutate,
    mutateMessage,
    mutate: mutateMessage as TODO_Transfer,
  };
};
