import {
  ID,
  SubscriptionChatRoom,
  SubscriptionPlan,
  WithPaginationMetaResponseV2,
} from "..";
import { FortuneTeller } from "./FortuneTellerResponse";
import { valueOf } from "../utility";
import { User } from "../../models";

export const SubscriptionStatus = {
  trial: "trial",
  active: "active",
  canceled: "canceled",
  paused: "paused",
} as const;

export interface PayjpCharge {
  id: string;
  created: number;
  amount: number;
  refunded: boolean;
}

export interface Subscription {
  id: ID;
  protate: string;
  status: valueOf<typeof SubscriptionStatus>;
  canDelete: boolean;
  hasDeleteReservation: boolean;
  isEnabled: boolean;
  isDeleted: boolean;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  trialStart: number;
  trialEnd: number;
  createdAt: string;
  pausedAt: string;
  resumedAt: string;
  deletedAt: string;
  fortuneTeller: FortuneTeller;
  user?: User;
  subscriptionChatRoom?: SubscriptionChatRoom;
  subscriptionPlan?: SubscriptionPlan;
  payjpCharges?: PayjpCharge[];
  nextBillingDate?: string;
  consultContent?: string;
  withTrial?: boolean;
}

export interface GetSubscriptionRes {
  subscription: Subscription;
}

export type GetSubscriptionListRes = WithPaginationMetaResponseV2<{
  subscriptions: Subscription[];
}>;
