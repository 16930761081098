import { FormData } from "features/review/hooks/useReviewForm";
import HttpClient from "shared/repositories/HttpClient";
import { ID, GetTelFortuneReviewListRes } from "shared/types";

export class TelFortuneReviewRepository {
  private getListPath(telFortuneId: ID, page: number) {
    return telFortuneId
      ? `/tel_fortunes/${telFortuneId}/reviews?page=${page + 1}`
      : undefined;
  }

  private postReviewPath(telFortuneApplicationId: ID) {
    return `/tel_fortune_applications/${telFortuneApplicationId}/tel_fortune_reviews`;
  }

  public getList(telFortuneId: ID, page = 0) {
    const path = this.getListPath(telFortuneId, page);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTelFortuneReviewListRes>(path)
          .then((res) => res.data),
    };
  }

  public postReview(telFortuneApplicationId: ID, formData: FormData) {
    const path = this.postReviewPath(telFortuneApplicationId);

    return {
      path,
      req: () =>
        HttpClient.miror.post<any>(path, formData).then((res) => res.data),
    };
  }
}
