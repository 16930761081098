import dynamic from "next/dynamic";
import { forwardRef, ReactNode } from "react";
import { CloseIcon } from "../../assets/icons/CloseIcon";

const IconButton = dynamic(
  async () => (await import("./IconButton")).IconButton
);

interface Props {
  children: ReactNode;
  onClose?: () => void;
  className?: string;
}

export const NotificationBar = forwardRef<HTMLDivElement, Props>(
  ({ children, onClose, className }, ref) => {
    return (
      <div
        ref={ref}
        tw="flex-center w-full bg-theme p-2 text-center max-w-screen-sm"
        className={className}
      >
        <p tw="font-base text-xs text-white">{children}</p>
        {onClose && (
          <IconButton
            tw="ml-2"
            icon={<CloseIcon css={{ stroke: "#fff" }} />}
            onClick={onClose}
          />
        )}
      </div>
    );
  }
);

NotificationBar.displayName = "NotificationBar";
