import { FreeFortuneChatQuestion } from "../models";

export default class FreeFortuneTheme {
  id: number;
  name: string;
  order: number;
  freeFortuneChatQuestions: FreeFortuneChatQuestion[];

  constructor(data?: Partial<FreeFortuneTheme>) {
    Object.assign(this, data);

    if (!data) return;

    this.freeFortuneChatQuestions = data?.freeFortuneChatQuestions?.map(data => new FreeFortuneChatQuestion(data)) ?? [];
  }
}
