import { Message } from "shared/models/index";
import client from "shared/repositories/HttpClient";

const SampleChatRoomMessagesRepository = {
  get: async (
    fortuneTellerId: string | string[] | number
  ): Promise<Message[]> => {
    const url = `/fortune_tellers/${fortuneTellerId}/sample_chat_room/messages`;
    const res = await client.miror.get(url);
    const messages =
      res.data?.messages?.map((message: Message) => new Message(message)) ?? [];
    return messages;
  },
};

export default SampleChatRoomMessagesRepository;
