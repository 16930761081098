import { TopBanners } from "repositories";
import { TopBanner } from "shared/models/index";
import { SWRResponse, TODO_Transfer } from "shared/types";
import useSWRImmutable from "swr/immutable";

export const useTopBanners = (): SWRResponse<{ topBanners: TopBanner[] }> => {
  const { data, error, mutate } = useSWRImmutable(
    "/top_banners",
    TopBanners.index
  );

  return {
    topBanners: data ?? [],
    isLoading: !error && !data,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};
