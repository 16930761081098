import { repository } from "repositories";
import { GetFortuneListRes, ID, SWRResponse } from "shared/types";
import useSWR from "swr";

export const useFortuneTellerFortuneList = (
  fortuneTellerId: ID
): SWRResponse<GetFortuneListRes> => {
  const repo = repository.fortuneTellerFortune.getList(fortuneTellerId);
  const { data, error, mutate } = useSWR(repo.path, repo.data, {
    fallbackData: repo.fallbackData,
  });

  return {
    fortunes: data?.fortunes,
    meta: data?.meta,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
