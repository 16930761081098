import { UserCoupon } from "shared/models/index";
import clients from "shared/repositories/HttpClient";
import { Data } from "shared/types";

export type UserCoupons = {
  validCoupons: UserCoupon[];
  expiredCoupons: UserCoupon[];
};

const UserCouponsRepository = {
  index: async (url: string): Promise<UserCoupons> => {
    const res = await clients.miror.get(url);
    const validCoupons = res.data.validCoupons.map(
      (coupon: Data) => new UserCoupon(coupon)
    );
    const expiredCoupons = res.data.expiredCoupons.map(
      (coupon: Data) => new UserCoupon(coupon)
    );
    return { validCoupons, expiredCoupons };
  },
  post: async (campaignId: string): Promise<boolean> => {
    const url = `/me/user_coupons`;
    const data = { campaignId };
    const res = await clients.miror.post(url, data);
    return res.status === 201;
  },
};

export default UserCouponsRepository;
