import HttpClient from "shared/repositories/HttpClient";
import {
  GetAnnouncementDataRes,
  GetAnnouncementListRes,
  ID,
  SWRConfig,
} from "shared/types";

export class AnnouncementRepository {
  private getListPath() {
    return `/announcements`;
  }

  private getDataPath(id: ID) {
    return `/announcements/${id}`;
  }

  public getList(): SWRConfig<GetAnnouncementListRes> {
    const path = this.getListPath();
    const fetcher = () =>
      HttpClient.miror
        .get<GetAnnouncementListRes>(path)
        .then((res) => res.data);

    return {
      path,
      key: path,
      fetcher,
      fallbackData: [],
    };
  }

  public getData(id: ID): SWRConfig<GetAnnouncementDataRes> {
    const path = this.getDataPath(id);
    const key = [path, id];
    const fetcher = () =>
      HttpClient.miror
        .get<GetAnnouncementDataRes>(path)
        .then((res) => res.data);

    return {
      path,
      key,
      fetcher,
    };
  }
}
