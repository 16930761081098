/**
 * X以上の数値からYの倍数の数値を配列で返す
 *
 * @param x 探索範囲
 * @param y 倍数
 * @returns
 */
export const findMultiples = (x: number, y: number) => {
  let multiples = [];
  let currentNum = x;

  while (currentNum % y !== 0) {
    currentNum++;
  }

  multiples.push(currentNum);

  while (currentNum + y <= x) {
    currentNum += y;
    multiples.push(currentNum);
  }

  return multiples;
};
