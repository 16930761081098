import HttpClient from "shared/repositories/HttpClient";
import { GetCouponHistoryListResponse } from "shared/types";

export class CouponHistoryRepository {
  private getListPath() {
    return "/me/coupon_histories";
  }

  public getList() {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetCouponHistoryListResponse>(path)
          .then((res) => res.data),
      fallbackData: {
        couponHistories: [],
      } as GetCouponHistoryListResponse,
    };
  }
}
