import Layout from "components/layout";

import { NextScriptTag } from "components/utils/Scripts";
import { useStampCardModal } from "features/call-logs/hooks/useStampCardModal";
import { Sentry } from "lib/sentry";
import { AppProps } from "pages/_app";
import { FC, ReactNode, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useMe } from "repositories/hooks";
import { Error } from "shared/components/templates/Error";
import { GTM_CONTAINER_ID } from "shared/lib/google";

const Root: FC<Pick<AppProps, "Component">> = ({ children, Component }) => {
  const { me, isLoading } = useMe();

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout || ((page: ReactNode) => <Layout>{page}</Layout>);

  useEffect(() => {
    if (!isLoading && me) {
      Sentry.setUser({
        id: me.id.toString(),
        username: me.name,
        email: me.email,
      });
    }
  }, [isLoading, me]);

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_CONTAINER_ID });
  }, []);

  useStampCardModal();

  return (
    <>
      <NextScriptTag />
      {getLayout(<>{children}</>)}
    </>
  );
};

export default Sentry.withErrorBoundary(Root, {
  fallback: () => {
    return (
      <Layout>
        <Error
          title="問題が発生しました🙇‍♂️"
          description="大変申し訳ございませんが、運営までお問い合わせください"
        />
      </Layout>
    );
  },
});
