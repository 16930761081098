import { Skeleton } from "./Skeleton";
import { VFC } from "react";
import { css } from "styled-components";
import FilledImageIcon from "../../assets/icons/FilledImage.svg";
import { useClientRect } from "../../hooks/useClientRect";

interface ImageSkeletonProps {
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const ImageSkeleton: VFC<ImageSkeletonProps> = ({
  width,
  height,
  className,
}) => {
  const {
    ref,
    width: skeletonWidth,
    height: skeletonHeight,
  } = useClientRect([]);

  const scaleRatio = width && height ? skeletonWidth / skeletonHeight : 1.6;

  return (
    <div tw="relative" css={{ width, height }} className={className}>
      <Skeleton
        tw="w-full"
        ref={ref}
        width={width}
        height={height}
        className={className}
      />
      <div tw="absolute inset-0 flex-center flex-col">
        <FilledImageIcon
          tw="svg text-white"
          css={css({ transform: `scale(${scaleRatio})` })}
        />
        <div
          tw="font-base text-2xs text-white"
          css={css({ marginTop: scaleRatio === 1 ? 4 : scaleRatio * 10 })}
        >
          No Image
        </div>
      </div>
    </div>
  );
};
