export const keys = {
  resoilPersist: "recoil-persist",
  ["free_fortune/data"]: "free_fortune/data",
  clid: "clid",
  chatRoomInput: "chatRoomInput",
  reviewContent: "reviewContent",
  ["admin/menu"]: "admin/menu",
  ["admin/reviewOrder"]: "admin/reviewOrder",
  ["admin/chatRoomList/searchWord"]: "admin/chatRoomList/searchWord",
  ["admin/chatRoomList/tabIndex"]: "admin/chatRoomList/tabIndex",
} as const;

type PrimaryKeys = typeof keys[keyof typeof keys];
type FlexibleKeys = Extract<PrimaryKeys, "chatRoomInput">;
type CustomKeys = `${FlexibleKeys}/${string}`;
export type Keys = PrimaryKeys | CustomKeys;

export const makeCustomKey = (
  key: FlexibleKeys,
  identifier: string
): CustomKeys => {
  return `${key}/${identifier}` as const;
};

const StorageType = {
  localStorage: "localStorage",
  sessionStorage: "sessionStorage",
} as const;

// see: https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
const isStorageAvailable = (type: keyof typeof StorageType) => {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

const isLocalStorageAvailable = async () =>
  await isStorageAvailable("localStorage");

export interface SafeLocalStorage {
  getSerializedItem: <T>(key: Keys) => T | undefined;
  setItemAsString: (key: Keys, value: any) => Promise<void>;
  removeItem: (key: Keys) => void;
}

const safeLocalStorage: SafeLocalStorage = {
  getSerializedItem: (key) => {
    if (typeof window === "undefined") return;
    if (!isLocalStorageAvailable()) return;
    const json = window.localStorage.getItem(key);
    return json && json !== "undefined" ? JSON.parse(json) : undefined;
  },
  setItemAsString: async (key, value) => {
    if (typeof window === "undefined") return;
    if (!isLocalStorageAvailable()) return;
    const json = JSON.stringify(value);
    try {
      window.localStorage.setItem(key, json);
    } catch {
      // ローカルストレージの容量が最大の場合例外が発生する
    }
  },
  removeItem: (key) => {
    if (typeof window === "undefined") return;
    if (!isLocalStorageAvailable()) return;
    window.localStorage.removeItem(key);
  },
};

export default safeLocalStorage;
