import { NumberSchema, StringSchema } from "yup";
import { OptionalArraySchema } from "yup/lib/array";
import { AnyObject } from "yup/lib/types";

export const rangeValidator = {
  name: "range",
  validation: function (min: number, max: number) {
    return (
      this as
        | OptionalArraySchema<any, AnyObject, any[]>
        | NumberSchema<number, AnyObject, number>
        | StringSchema<string, AnyObject, string>
    )
      .min(min)
      .max(max);
  },
};
