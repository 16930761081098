import { CategoryTopicRepository } from "repositories/CategoryTopicRepository";
import { FortuneTellerRepository } from "./FortuneTellerRepository";
import { TopicCategoryRepository } from "./TopicCategoryRepository";
import { TopicRepositoryV2 } from "./TopicRepository";
import { FreeFortuneContentRepository } from "./FreeFortuneContentRepository";
import { FreeFortuneGenreRepository } from "./FreeFortuneGenreRepository";
import { FreeFortuneKanteiRepository } from "./FreeFortuneKanteiRepository";
import { FreeFortuneResultContentRepository } from "./FreeFortuneResultContentRepository";
import { FortuneRepositoryV2 } from "./FortuneRepository";
import { TelFortuneRepositoryV2 } from "./TelFortuneRepository";
import { FortuneTellerReviewRepository } from "./FortuneTellerReviewRepository";
import { FortuneReviewRepository } from "./FortuneReviewRepository";
import { TelFortuneReviewRepository } from "./TelFortuneReviewRepository";
import { CategoryFortuneRepository } from "./CategoryFortuneRepository";
import { CategoryTelFortuneRepository } from "./CategoryTelFortuneRepository";
import { TagTopicRepositoryV2 } from "./TagTopicRepository";
import { FortuneTellerFortuneRepository } from "./FortuneTellerFortuneRepository";
import { FortuneTellerTelFortuneRepository } from "./FortuneTellerTelFortuneRepository";
import { TagFortuneRepositoryV2 } from "./TagFortuneRepository";
import { TagTelFortuneRepositoryV2 } from "./TagTelFortuneRepository";
import { CouponHistoryRepository } from "./CouponHistoryRepository";
import { AcquisitionDataRepository } from "./AcquisitionDataRepository";
import { KarteRepository } from "./KarteRepository";
import { AnnouncementRepository } from "./AnnouncementRepository";
import { PurchaseHistoryRepository } from "./PurchaseHistoryRepository";
import { OrderInfoRepository } from "./OrderInfoRepository";
import { MediaSitemapRepository } from "./MediaSitemapRepository";
import { MirorSitemapRepository } from "./MirorSitemapRepository";
import { SubscriptionPlanRepository } from "./SubscriptionPlanRepository";
import { SubscriptionRepository } from "./SubscriptionRepository";
import { FreeFortuneCategoryRepository } from "./FreeFortuneCategoryRepository";
import { FreeFortuneTagRepository } from "./FreeFortuneTagRepository";
import { FreeFortuneDivinationRepository } from "./FreeFortuneDivinationRepository";
import { UserNotificationRepository } from "./UserNotificationRepository";
import { UserRepositoryV2 } from "./users";
import { PointHistoryRepository } from "./PointHistoryRepository";
import { CallEndNotificationRepository } from "./CallEndNotificationRepository";
import { CalledFortuneTellerRepository } from "./CalledFortuneTellerRepository";
import { TelWaitingFortuneTellerRepository } from "./TelWaitingFortuneTellerRepository";

/**
 * [repository V2]
 * jsonレスポンスのclassインスタンス化をしないrepository群
 *
 * - classインスタンス化されたオブジェクトはlocalStorageに保存することができない
 *   - SWRのキャッシュプロバイダーをlocalStorageにできない
 *   - データをGlobalStateとして持つときにRecoilPersistが利用できない
 * - データフェッチ時のオーバーヘッドが発生することによるパフォーマンス懸念
 */
export const repository = {
  fortuneTeller: new FortuneTellerRepository(),
  topic: new TopicRepositoryV2(),
  categoryTopic: new CategoryTopicRepository(),
  topicCategory: new TopicCategoryRepository(),
  freeFortuneContent: new FreeFortuneContentRepository(),
  freeFortuneCategory: new FreeFortuneCategoryRepository(),
  freeFortuneDivination: new FreeFortuneDivinationRepository(),
  freeFortuneTag: new FreeFortuneTagRepository(),
  freeFortuneGenre: new FreeFortuneGenreRepository(),
  freeFortuneKantei: new FreeFortuneKanteiRepository(),
  freeFortuneResultContent: new FreeFortuneResultContentRepository(),
  mediaSitemap: new MediaSitemapRepository(),
  mirorSitemap: new MirorSitemapRepository(),
  fortune: new FortuneRepositoryV2(),
  telFortune: new TelFortuneRepositoryV2(),
  fortuneTellerReview: new FortuneTellerReviewRepository(),
  fortuneReview: new FortuneReviewRepository(),
  telFortuneReview: new TelFortuneReviewRepository(),
  categoryFortune: new CategoryFortuneRepository(),
  categoryTelFortune: new CategoryTelFortuneRepository(),
  tagTopic: new TagTopicRepositoryV2(),
  fortuneTellerFortune: new FortuneTellerFortuneRepository(),
  fortuneTellerTelFortune: new FortuneTellerTelFortuneRepository(),
  tagFortune: new TagFortuneRepositoryV2(),
  tagTelFortune: new TagTelFortuneRepositoryV2(),
  couponHistory: new CouponHistoryRepository(),
  acquisitionData: new AcquisitionDataRepository(),
  karte: new KarteRepository(),
  announcement: new AnnouncementRepository(),
  purchaseHistory: new PurchaseHistoryRepository(),
  orderInfo: new OrderInfoRepository(),
  subscriptionPlan: new SubscriptionPlanRepository(),
  subscription: new SubscriptionRepository(),
  userNotification: new UserNotificationRepository(),
  callEndNotification: new CallEndNotificationRepository(),
  user: new UserRepositoryV2(),
  pointHistory: new PointHistoryRepository({ version: "v2" }),
  calledFortuneTeller: new CalledFortuneTellerRepository(),
  telWaitingFortuneTeller: new TelWaitingFortuneTellerRepository(),
};

/**
 * @deprecated V2のrepositoryに移行したい
 * MIROR
 */
export { default as TopBanners } from "./top_banners";
export { default as Fortunes } from "./FortuneRepository";
export { default as TagTelFortunes } from "./TagTelFortuneRepository";
export { default as Categories } from "./categories";
export { default as Favorites } from "./favorites";
export { default as ChatRoomList } from "./chat_room_list";
export { default as ChatRoom } from "./chat_rooms";
export { default as TelFortuneChatRoom } from "./tel_fortune_chat_rooms";
export { default as SampleChatRoom } from "./sample_chat_rooms";
export { default as Users } from "./users";
export { default as PointHistories } from "./PointHistoryRepository";
export { default as TotalPoint } from "./total_point";
export { default as UserCoupons } from "./user_coupons";
export { default as UserEmail } from "./UserEmail";
export { default as UserPassword } from "./UserPassword";
export { default as DailyHoloscopes } from "./daily_holoscopes";
export { default as PayjpCardInformation } from "./payjp_card_information";
export { default as OrderPayments } from "./OrderInfoRepository";
export { default as FreeFortuneThemes } from "./free_fortune_themes";
export { default as FreeFortuneChatReplies } from "./free_fortune_chat_replies";
export { default as PaymentWithSignup } from "./PaymentWithSignup";
export { default as FortuneApplications } from "./fortune-applications";
export { default as UserFreeFortuneChatQuestions } from "./user_free_fortune_chat_questions";

/**
 * @deprecated V2のrepositoryに移行したい
 * Media
 */
export { default as TagTopics } from "./TagTopicRepository";
