import { useRouter } from "next/router";
import { ReactNode } from "react";

import { Button } from "../atoms/Button";
import { Image } from "../atoms/Image";
import { PageTitle } from "../molecules/PageTitle";

interface Props {
  title: string;
  description?: string;
  button?: ReactNode;
}

export const Error = ({ title, description, button }: Props) => {
  const router = useRouter();
  const handleClick = async () => {
    await router.push("/");
    await router.reload();
  };
  return (
    <div tw="flex flex-col items-center pt-6 px-5 pb-14 w-full">
      <PageTitle
        title={title}
        description={description}
        tw="whitespace-pre-wrap"
      />
      <div tw="w-36">
        <Image
          src={`${process.env.NEXT_PUBLIC_CDN_V2}/error.gif`}
          width={1}
          height={1}
          layout="responsive"
        />
      </div>
      {button ? (
        button
      ) : (
        <Button tw="bg-gradient-theme-to-l w-full mt-5" onClick={handleClick}>
          トップページに戻る
        </Button>
      )}
    </div>
  );
};
