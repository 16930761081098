import { AxiosResponse } from "axios";
import { TotalPoint } from "shared/models/index";
import clients from "shared/repositories/HttpClient";

const TotalPointRepository = {
  show: async (url: string): Promise<TotalPoint> => {
    const res: AxiosResponse = await clients.miror.get(url);
    return new TotalPoint(res.data.totalPoint);
  },
};

export default TotalPointRepository;
