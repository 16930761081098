import HttpClient from "shared/repositories/HttpClient";
import { GetTopicListRes, GetTopicRes, ID } from "shared/types";
import { ParsedUrlQueryInput } from "querystring";

export const Media = {
  press: "press",
  miracle: "miracle",
} as const;

export type MediaType = keyof typeof Media;

export const Order = {
  publishedAt: "published_at",
  lastmod: "lastmod",
  session: "session",
} as const;

export type OrderType = typeof Order[keyof typeof Order];

export class TopicRepositoryV2 {
  private getObjectPath(id?: ID) {
    return id ? `/media/topics/${id}` : undefined;
  }

  private getListPath() {
    return `/media/topics`;
  }

  public getObject(id: ID) {
    const path = this.getObjectPath(id);
    return {
      path,
      data: () =>
        HttpClient.miror.get<GetTopicRes>(path).then((res) => res.data),
    };
  }

  public getList(mediaType: "press" | "miracle", params?: ParsedUrlQueryInput) {
    const path = this.getListPath();
    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTopicListRes>(path, { params: { mediaType, ...params } })
          .then((res) => res.data),
    };
  }
}
