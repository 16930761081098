import uniq from "lodash/uniq";
import { useModal } from "mui-modal-provider";
import dynamic from "next/dynamic";
import NextImage, {
  ImageLoaderProps,
  ImageProps as NextImageProps,
  StaticImageData,
} from "next/image";
import { useMemo, useRef, useState } from "react";
import tw from "twin.macro";

import { DynamicNextImage } from "./DynamicImage";
import { ImageSkeleton } from "./ImageSkeleton";

const imageLoader = ({ src, width, quality }: ImageLoaderProps) =>
  `${src}?w=${width}&q=${quality || 75}`;

const ImageViewer = dynamic(
  async () => (await import("./ImageViewer")).ImageViewer
);

/** @deprecated 独自実装で公式でサポートされているわけではないので、なるべく控える */
type Dynamic = "dynamic";

export interface ImageProps extends Omit<NextImageProps, "layout"> {
  layout?: NextImageProps["layout"] | Dynamic;
  fallback?: string | StaticImageData | (string | StaticImageData)[];
  viewerEnable?: boolean;
}

/**
 * NOTE: next/imageを拡張したコンポーネント
 *  - fallback（代替画像URL）プロパティの追加
 *  - onErrorのハンドリング
 *    - fallbackがあれば代替画像を表示、なければスケルトンを表示する
 *  - アスペクト比が不明な画像を表示するためのlayout="dynamic"をサポート（独自実装）
 *    - e.g. チャットルーム内メッセージの画像など
 *  - viewerEnableプロパティの追加
 *    - 画像をクリックしてビューワーを表示する（デフォルトはfalse）
 *  - デフォルトでpointer-events: noneを付与して長押し・右クリック操作を不可に
 *    - 保存等の操作を許可する場合はpropsでスタイルを渡し、pointer-eventsを有効化するかImageViewerを開く
 */
export const Image = ({
  src,
  width,
  height,
  layout,
  onClick,
  className,
  fallback = [],
  viewerEnable,
  ...props
}: ImageProps) => {
  const [errorCount, setErrorCount] = useState(0);
  const divRef = useRef<HTMLDivElement>();

  const handleError = () => {
    setErrorCount(errorCount + 1);
  };

  const { showModal } = useModal();

  // onClick関数が渡された場合はviewerEnableよりも優先する
  const handleClickImage = useMemo(() => {
    if (onClick) {
      return onClick;
    } else if (viewerEnable) {
      return () => {
        showModal(ImageViewer, {
          imageProps: { src, width, height, layout, className, ...props },
        });
      };
    } else {
      return null;
    }
  }, [
    onClick,
    viewerEnable,
    showModal,
    src,
    width,
    height,
    layout,
    className,
    props,
  ]);

  /** 同じURLはonErrorでハンドリングできないので取り除く */
  const sanitizedFallback =
    fallback instanceof Array
      ? uniq(fallback.filter((fallbackSrc) => fallbackSrc !== src))
      : [fallback];

  const isError = errorCount > 0;
  const fallbackSrc = sanitizedFallback[errorCount - 1];
  const isEmpty = !src && !fallbackSrc;
  const canFallback = src && fallbackSrc;
  const isGif = typeof src === "string" && /\.gif/.test(src);

  const loader = useMemo(() => {
    if (typeof src === "string" && /https:\/\/cdn.miror.jp/.test(src)) {
      return imageLoader;
    } else {
      return null;
    }
  }, [src]);

  /**
   * NOTE: 以下の３パターンでスケルトンを表示する
   *  ①srcとfallbackがどちらもfalsyな場合
   *  ②フォールバックが不可能な状態でエラーが発生した場合
   *  ③フォールバック用の画像がすべて404になった場合
   */
  if (
    isEmpty ||
    (isError && !canFallback) ||
    errorCount > sanitizedFallback.length
  ) {
    return (
      <ImageSkeleton width={width} height={height} className={className} />
    );
  }

  if (layout === "dynamic") {
    return (
      <DynamicNextImage
        src={isError && fallbackSrc ? fallbackSrc : src || fallbackSrc}
        loader={loader}
        onClick={handleClickImage}
        onError={handleError}
        className={className}
        {...props}
      />
    );
  }

  // clickableな画像でもpointer-eventsの有効化はラッパーのdivのみにする(clickable ≠ 保存等の操作OK)
  return (
    <div
      ref={divRef}
      className={className}
      onClick={handleClickImage}
      css={handleClickImage && tw`cursor-pointer pointer-events-auto`}
    >
      <NextImage
        src={isError && fallbackSrc ? fallbackSrc : src || fallbackSrc}
        width={width}
        height={height}
        loader={loader}
        layout={layout}
        onError={handleError}
        unoptimized={isGif}
        tw="pointer-events-none"
        className={className}
        {...props}
      />
    </div>
  );
};
