import { TelFortuneChatRoom } from "shared/models/index";
import clients from "shared/repositories/HttpClient";

const TelFortuneChatRoomsRepository = {
  show: async (
    chatRoomId: string | string[] | number
  ): Promise<TelFortuneChatRoom> => {
    const res = await clients.miror.get(
      `/tel_fortune_chat_rooms/${chatRoomId}`
    );
    return new TelFortuneChatRoom(res.data?.telFortuneChatRoom);
  },
};

export default TelFortuneChatRoomsRepository;
