import { repository } from "repositories";
import {
  GetTelFortuneListParams,
  GetTelFortuneListRes,
  SWROptions,
  SWRResponse,
} from "shared/types";
import useSWR from "swr";

export const useTelFortunes = (
  params: GetTelFortuneListParams = {},
  options?: SWROptions
): SWRResponse<GetTelFortuneListRes> => {
  const repo = repository.telFortune.getList(params);

  const { data, error, mutate } = useSWR(
    options?.skip ? null : [repo.path, ...Object.values(params)],
    repo.data,
    {
      fallbackData: {
        telFortunes: [],
        meta: { totalPages: 1, totalCounts: 0 },
      },
    }
  );

  return {
    ...data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
