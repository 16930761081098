import HttpClient from "shared/repositories/HttpClient";
import {
  GetFreeFortuneResultContentListRes,
  GetFreeFortuneResultContentRes,
} from "shared/types";

export class FreeFortuneResultContentRepository {
  private getObjectPath(id: string | number) {
    return `/free_fortune/result_contents/${id}`;
  }

  private getListPath(contentId: string | number) {
    return `/free_fortune/contents/${contentId}/result_contents`;
  }

  public getObject(id: string | number) {
    const path = this.getObjectPath(id);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneResultContentRes>(path)
          .then((res) => res.data),
    };
  }

  public getList(contentId: string | number) {
    const path = this.getListPath(contentId);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneResultContentListRes>(path)
          .then((res) => res.data),
    };
  }
}
