import FortuneApplication from "shared/models/fortune_application";
import TelFortuneApplication from "shared/models/tel_fortune_application";
import HttpClient from "shared/repositories/HttpClient";
import clients from "shared/repositories/HttpClient";
import {
  GetOrderInfoObjectParams,
  GetOrderInfoObjectRes,
  ID,
  MenuTypeV2,
} from "shared/types";

interface PostOrderParams {
  _type: MenuTypeV2;
  menuId: ID;
  name: string;
  birthday: string;
  gender: string;
  bloodtype: string;
  consultContent: string;
  phoneNumber: string;
  paymentMethod: string;
  usePoint: boolean;
  useCoupon: boolean;
  useCouponId: string;
  useUserCouponOwnershipId: string;
  cvsName: string; // TODO: enum
  firstName: string;
  lastName: string;
  lastNameKana: string;
  fromNow: boolean;
}

export class OrderInfoRepository {
  private getObjectPath({ menuId, menuType }: GetOrderInfoObjectParams) {
    return menuId && menuType ? `/orders/info/${menuType}/${menuId}` : null;
  }

  public getObject(params: GetOrderInfoObjectParams) {
    const path = this.getObjectPath(params);

    return {
      path,
      swrKey: path ? [path, ...Object.values(params)] : null,
      fetcher: () => {
        return HttpClient.miror
          .get<GetOrderInfoObjectRes>(path, { params })
          .then((res) => res.data);
      },
    };
  }
}

const OrderPaymentRepository = {
  post: async (orderPayment: PostOrderParams): Promise<FortuneApplication> => {
    const url = `/orders/payments`;
    const params = { orderPayment };
    const res = await clients.miror.post(url, params);

    if (orderPayment._type === "chat") {
      return new FortuneApplication(res.data.fortuneApplication);
    } else if (orderPayment._type === "tel") {
      return new TelFortuneApplication(res.data.telFortuneApplication);
    }
  },
};

export default OrderPaymentRepository;
