import NextImage from "next/image";
import styled from "styled-components";
import tw from "twin.macro";

import { ImageProps } from "./Image";

const Wrapper = styled.div<{
  width: string | number;
  height: string | number;
}>`
  ${({ width }) => width && `width: ${width}px`};
  ${({ height }) => height && `height: ${height}px`};
  > span {
    position: unset !important;
    width: 100% !important;
    height: 100% !important;
  }
`;

/** @deprecated 独自実装で公式でサポートされているわけではないので、なるべく控える */
export const DynamicNextImage = ({
  width,
  height,
  onClick,
  className,
  layout: _layout,
  ...rest
}: ImageProps) => {
  // clickableな画像でもpointer-eventsの有効化はラッパーのdivのみにする(clickable ≠ 保存等の操作OK)
  return (
    <Wrapper
      width={width}
      height={height}
      onClick={onClick}
      css={onClick && tw`cursor-pointer pointer-events-auto`}
      className={className}
    >
      <NextImage
        layout="fill"
        tw="object-cover w-full! relative! h-[unset]! pointer-events-none"
        className={className}
        {...rest}
      />
    </Wrapper>
  );
};
