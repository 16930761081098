import HttpClient from "shared/repositories/HttpClient";
import { GetFortuneListRes, ID } from "shared/types";
import { SWRConfiguration } from "swr";

export class FortuneTellerFortuneRepository {
  private getListPath(fortuneTellerId: ID) {
    return fortuneTellerId
      ? `/fortune_tellers/${fortuneTellerId}/fortunes`
      : null;
  }

  public getList(fortuneTellerId: ID) {
    const path = this.getListPath(fortuneTellerId);

    return {
      path,
      data: () =>
        HttpClient.miror.get<GetFortuneListRes>(path).then((res) => res.data),
      fallbackData: { fortunes: [] } as SWRConfiguration["fallbackData"],
    };
  }
}
