import { MessageParams } from "yup/lib/types";

/**
 * see: https://github.com/heartcombo/devise/wiki/How-To:-Set-up-simple-password-complexity-requirements#manual-solution
 * NOTE: 特殊文字を要求するサービスはまだそこまで多くない印象があるので一旦なしにしている（あった方がセキュアだが離脱率とのトレードオフ感）
 *       特殊文字を含める正規表現: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,70}$/
 */
const VALID_PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/;

export const validation = (value: string): boolean => {
  value = value || "";
  return VALID_PASSWORD_REGEXP.test(value);
};

const error = ({ label }: MessageParams): string =>
  `${label}は8文字以上の半角英数字で大文字・小文字・数字をそれぞれ1つ以上含む必要があります。`;

export const passwordValidator = {
  name: "password",
  validation: function () {
    return this.test(null, error, validation);
  },
};
