import { createContext, useContext } from "react";

export interface ToggleFlashParams {
  show?: boolean;
  message?: string;
}

export interface FlashContextValue {
  show: boolean;
  message: string;
  toggleFlash: (params: ToggleFlashParams) => void;
}

export const FlashContext = createContext<FlashContextValue>(null);

export const useFlashContext = () => useContext(FlashContext);
