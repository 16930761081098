import HttpClient from "shared/repositories/HttpClient";
import {
  GetUserNotificationListParams,
  GetUserNotificationListRes,
  GetUserNotificationRes,
  UserNotification,
} from "shared/types";

export class UserNotificationRepository {
  private getListPath() {
    return `/user_notifications`;
  }

  private getObjectPath(notificationType: string) {
    return `/user_notifications/${notificationType}`;
  }

  private getPostPath() {
    return `/user_notifications`;
  }

  private getPatchPath(id: number) {
    return `/user_notifications/${id}`;
  }

  public getList(params?: GetUserNotificationListParams) {
    const path = this.getListPath();

    return {
      path,
      fetcher: () =>
        HttpClient.miror
          .get<GetUserNotificationListRes>(path, { params })
          .then((res) => res.data),
      fallbackData: { userNotifications: [] as UserNotification[] },
    };
  }

  public getObject(notificationType: string) {
    const path = this.getObjectPath(notificationType);

    return {
      path,
      fetcher: () =>
        HttpClient.miror
          .get<GetUserNotificationRes>(path)
          .then((res) => res.data),
      fallbackData: { userNotification: null as UserNotification },
    };
  }

  public post(params: object) {
    const path = this.getPostPath();

    return {
      path,
      req: () => HttpClient.miror.post(path, { userNotification: params }),
    };
  }

  public patch(id: number, params: object) {
    const path = this.getPatchPath(id);

    return {
      path,
      req: () => HttpClient.miror.patch(path, { userNotification: params }),
    };
  }
}
