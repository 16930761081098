import HttpClient from "shared/repositories/HttpClient";
import { GetSubscriptionPlanResponse, ID } from "shared/types";

export class SubscriptionPlanRepository {
  private getSubscriptionPlanPath(id: ID) {
    return id ? `/subscription_plans/${id}` : null;
  }

  public getSubscriptionPlan(id: ID) {
    const path = this.getSubscriptionPlanPath(id);
    const fetcher = async () =>
      await HttpClient.miror
        .get<GetSubscriptionPlanResponse>(path)
        .then((res) => res.data);

    return {
      path,
      req: fetcher,
      fallbackData: { subscriptionPlan: {} },
    };
  }
}
