import { useQueryEffect } from "features/app/hooks/useQueryEffect";
import { usePrevLocation } from "features/shared/hooks/usePrevLocation";
import { FC, useEffect } from "react";
import { useSwrConfig } from "shared/lib/swr/config";
import { SWRConfig } from "swr";

/**
 * - toggleFlashが有効化されているコンポーネント
 * - layout/index.tsxより下にマウントする
 * - SWRConfigはエラーハンドリングでフラッシュに依存しているのでこれより上流でマウントしないよう注意
 */
export const WithFlash: FC = ({ children }) => {
  const config = useSwrConfig();
  const [, , setPrevLocation] = usePrevLocation();
  const location = typeof window !== "undefined" && window.location.href;

  useEffect(() => setPrevLocation(location), [location, setPrevLocation]);

  useQueryEffect();

  return <SWRConfig value={config}>{children}</SWRConfig>;
};
