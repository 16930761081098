import { repository } from "repositories";
import { ID, GetTelFortuneRes, SWRResponse } from "shared/types";
import useSWR from "swr";

export const useTelFortune = (id: ID): SWRResponse<GetTelFortuneRes> => {
  const repo = repository.telFortune.getObject(id);
  const { data, error, mutate } = useSWR(repo.path, repo.data);

  return {
    telFortune: data?.telFortune,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
