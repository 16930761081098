import { useCallback, useState } from "react";
import {
  FlashContext,
  FlashContextValue,
  ToggleFlashParams,
} from "./useFlashContext";

/**
 * @returns flash [UseFlashReturn]
 */
export const useFlash = (): FlashContextValue => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  const toggleFlash = useCallback(
    ({ show = true, message }: ToggleFlashParams) => {
      setShow(show);
      setMessage(message);
      setTimeout(() => {
        setShow(false);
        setMessage("");
      }, 3000);
    },
    []
  );

  return { show, message, toggleFlash };
};

/**
 * @param value [UseFlashReturn]
 */
export const FlashProvider = FlashContext.Provider;
