import { useSession } from "next-auth/client";
import HttpClient from "shared/repositories/HttpClient";
import { SWRResponse, TODO_Transfer } from "shared/types";
import useSWR from "swr";

interface UnreadMessages {
  count: number;
}

export const useUnreadMessages = (): SWRResponse<{
  unreadMessages: UnreadMessages;
}> => {
  const [session] = useSession();
  const key = session ? "/me/unread_messages" : null;
  const fetcher = async (url: string): Promise<UnreadMessages> =>
    (await HttpClient.miror.get(url)).data;
  const { data, error, mutate } = useSWR(key, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    refreshInterval: 10000,
    focusThrottleInterval: 5000,
    fallbackData: { count: 0 },
  });

  return {
    unreadMessages: data,
    isLoading: !data && !error,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};
