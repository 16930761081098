import Link from "next/link";
import LogoIcon from "../../assets/icons/logo.svg";
import WhileLogoIcon from "../../assets/icons/white_logo.svg";
import { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {
  color?: "theme" | "white";
  disabled?: boolean;
  className?: string;
}

export const Logo = ({ color = "theme", disabled = false, ...rest }: Props) => {
  return (
    <Link href={disabled ? "#" : "/"}>
      <a>
        {color === "theme" ? (
          <LogoIcon {...rest} />
        ) : (
          <WhileLogoIcon {...rest} />
        )}
      </a>
    </Link>
  );
};
