import { parseCookies } from "nookies";
import QueryString, { ParsedQs } from "qs";
import { useHostName } from "shared/hooks/useHostName";
import { setPrevLocation } from "utils/routes/setPrevLocation";

type UsePrevLocationReturn = [
  string | undefined,
  ParsedQs,
  (location: string) => void
];

export const usePrevLocation = (): UsePrevLocationReturn => {
  const cookies = parseCookies();
  const { host } = useHostName();

  if (!cookies.prev_location) return [undefined, {}, setPrevLocation];

  try {
    const url = new URL(cookies.prev_location, host);
    const query = QueryString.parse(url.search, { ignoreQueryPrefix: true });
    return [url.pathname, query, setPrevLocation];
  } catch (error) {
    console.log("error", error);

    return [cookies.prev_location, {}, setPrevLocation];
  }
};
