import dayjs, { Dayjs } from "dayjs";
import { MenuType, Fortune, TelFortune, FortuneTeller } from "../types";

export default class Favorite {
  id: number;
  favoritableType: MenuType & "FortuneTeller";
  favoritableId: number;
  createdAt: Dayjs;
  favoritable: Fortune | TelFortune | FortuneTeller;

  constructor(data?: Partial<Favorite>) {
    Object.assign(this, data);

    if (!data) return;

    this.createdAt = dayjs(data.createdAt);
  }
}
