import HttpClient from "shared/repositories/HttpClient";
import { AcquisitionDataParams, PostAcquisitionDataRes } from "shared/types";

export class AcquisitionDataRepository {
  private postAcquisitionDataPath() {
    return `/me/acquisition_data`;
  }

  public postAcquisitionData = (params: AcquisitionDataParams) => {
    const path = this.postAcquisitionDataPath();

    return {
      path,
      req: () =>
        HttpClient.miror
          .post<PostAcquisitionDataRes>(path, {
            acquisitionData: { ...params },
          })
          .then((res) => res.data),
    };
  };
}
