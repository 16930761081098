import { PointHistory } from "shared/models/index";
import clients from "shared/repositories/HttpClient";
import { PointHistoriesResponse } from "shared/types";
import { BaseRepository } from "shared/repositories/BaseRepository";

const PointHistoriesRepository = {
  index: async (url: string): Promise<PointHistoriesResponse> => {
    const res = await clients.miror.get(url);
    const pointHistories = res.data?.pointHistories?.map(
      (pointHistory: any) => new PointHistory(pointHistory)
    );
    return { pointHistories, meta: res.data.meta };
  },
};

export class PointHistoryRepository extends BaseRepository {
  private getListPath() {
    return `${this.version}/me/point_histories`;
  }

  public getList() {
    const path = this.getListPath();

    const fetcher = async (url?: string) =>
      this.clients
        .miror()
        .get(url || path)
        .then((res) => res.data);

    return {
      path,
      fetcher,
    };
  }
}

export default PointHistoriesRepository;
