import HttpClient from "shared/repositories/HttpClient";
import {
  GetFreeFortuneKanteiParams,
  GetFreeFortuneKanteiRes,
} from "shared/types";

export class FreeFortuneKanteiRepository {
  private getObjectPath() {
    return "/free_fortune/kantei";
  }

  public getObject(params: GetFreeFortuneKanteiParams) {
    const path = this.getObjectPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneKanteiRes>(path, { params })
          .then((res) => res.data),
    };
  }
}
