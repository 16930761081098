import dayjs, { Dayjs } from "dayjs";

import { menuTypesV2, MenuTypeV2 } from "../types";

export default class ChatRoomList {
  id: number;
  orderId: number;
  type: MenuTypeV2;
  lastmod: Dayjs;
  unreadMessages: number;
  to: {
    name: string;
    imageUrl: string;
  };
  latestMessage: {
    body: string;
  };
  sentResult?: boolean;
  orderedWhileWaiting?: boolean;

  constructor(data?: Partial<ChatRoomList>) {
    Object.assign(this, data);
    this.lastmod = dayjs(data.lastmod);
  }

  get roomUrl(): string {
    return this.type === menuTypesV2.chat
      ? `/chat_rooms/${this.id}`
      : `/tel_fortune_chat_rooms/${this.id}`;
  }
}
