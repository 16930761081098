import { useMe } from "repositories/hooks";
import { Button } from "@miror-ui/components";
import { BaseModal } from "shared/components/organisms/modal/BaseModal";
import { useHasSubscriptionsModal } from "features/user/hooks/useHasSubscriptionsModal";
import { useHasUnpaidOrdersModal } from "features/user/hooks/useHasUnpaidOrdersModal";

interface Props {
  onSubmit: () => void;
}

export const DeleteCardModal = ({ onSubmit }: Props) => {
  const { me, isLoading } = useMe();

  const { showModal: showHasSubscriptionsModal } = useHasSubscriptionsModal();
  const { showModal: showHasUnpaidOrdersModal } = useHasUnpaidOrdersModal();

  const handleClick = () => {
    if (me.hasSubscriptions) {
      showHasSubscriptionsModal();
      return;
    }
    if (me.hasUnpaidOrders) {
      showHasUnpaidOrdersModal();
      return;
    }
    onSubmit();
  };

  return (
    <BaseModal
      title={`現在のカード情報は削除されますが\nよろしいですか？`}
      subTitle="ご登録クレジットカードを変更すると現在ご登録いただいているカード情報は削除されます。別のカードのご利用をご希望の方はOKを押して再度カード情報をご入力ください。"
    >
      <Button onClick={handleClick} disabled={isLoading}>
        OK
      </Button>
    </BaseModal>
  );
};
