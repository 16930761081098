import {
  GetCalledFortuneTellerListRes,
  GetCalledFortuneTellerRes,
  Pagination,
} from "shared/types";
import { BaseRepository } from "shared/repositories/BaseRepository";

export class CalledFortuneTellerRepository extends BaseRepository {
  constructor() {
    super({ version: "v1" });
  }

  public getCalledFortuneTellerListPath() {
    return `/${this.version}/me/called_fortune_tellers`;
  }

  public getCalledFortuneTellerPath(fortuneTellerId: number | string) {
    return `/${this.version}/me/called_fortune_tellers/${fortuneTellerId}`;
  }

  public async getCalledFortuneTellerList(params?: Pagination) {
    return await this.clients
      .miror()
      .get<GetCalledFortuneTellerListRes>(
        this.getCalledFortuneTellerListPath(),
        {
          params,
        }
      )
      .then((res) => res.data);
  }

  public async getCalledFortuneTeller(fortuneTellerId: number | string) {
    return await this.clients
      .miror()
      .get<GetCalledFortuneTellerRes>(
        this.getCalledFortuneTellerPath(fortuneTellerId),
        {
          params: {
            fortuneTellerId,
          },
        }
      )
      .then((res) => res.data);
  }
}
