import { HtmlHTMLAttributes } from "react";
import { styled } from "twin.macro";

const Base = styled.div<Omit<TriangleProps, "direction">>`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${({ size, width, height }) =>
    `0 ${(width && width / 2) || size || 100}px ${height || size || 100}px ${
      (width && width / 2) || size || 100
    }px;`}}
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: ${(props) => props.color ?? "#000"};
  border-left-color: transparent;
`;

export interface TriangleProps extends HtmlHTMLAttributes<HTMLDivElement> {
  color?: HTMLElement["style"]["color"];
  size?: number;
  width?: number;
  height?: number;
  direction?: "top" | "bottom" | "left" | "right";
  className?: string;
}

export const Triangle = ({ direction, className, ...rest }: TriangleProps) => {
  switch (direction) {
    case "top":
      return <Base className={className} {...rest} />;
    case "bottom":
      return (
        <Base
          css={{ transform: "rotate(180deg)" }}
          className={className}
          {...rest}
        />
      );
    case "left":
      return (
        <Base
          css={{ transform: "rotate(-90deg)" }}
          className={className}
          {...rest}
        />
      );
    case "right":
      return (
        <Base
          css={{ transform: "rotate(90deg)" }}
          className={className}
          {...rest}
        />
      );
    default:
      return <Base className={className} {...rest} />;
  }
};
