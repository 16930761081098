import { keys, appState } from "lib/recoil/AppState";
import { selector } from "recoil";
import { Category, Fortune, TelFortune, FortuneTeller } from "shared/models";
import { Tag, Karte } from "shared/types";
export * from "./order";

/**
 * 共通: カルテ情報
 */
export const kartesState = selector<Karte[]>({
  key: keys.kartes,
  get: ({ get }) => get(appState).kartes,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), kartes: newValue }),
});

/**
 * ナビゲーション: 未読メッセージ数
 */
export const unreadMessagesState = selector<{ count: number }>({
  key: keys.unreadMessages,
  get: ({ get }) => get(appState).unreadMessages,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), unreadMessages: newValue }),
});

/**
 * 共通: 占術タグ
 */
export const divinationTagsState = selector<Tag[]>({
  key: keys.divinationTagsState,
  get: ({ get }) => get(appState).divinationTags,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), divinationTags: newValue }),
});

/**
 * 共通: カテゴリー一覧
 */
export const categoriesState = selector<Category[]>({
  key: keys.categoriesState,
  get: ({ get }) => get(appState).categories,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), categories: newValue }),
});

/**
 * メニューページ: ナビゲーション用
 */
export const currentMenuState = selector<Fortune | TelFortune>({
  key: keys.currentMenuState,
  get: ({ get }) => get(appState).currentMenu,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), currentMenu: newValue }),
});

/**
 * メニューページ: ナビゲーション用
 */
export const noticeSubscribedState = selector<boolean>({
  key: keys.noticeSubscribedState,
  get: ({ get }) => get(appState).noticeSubscribed,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), noticeSubscribed: newValue }),
});

/**
 * 占い師ページ: ナビゲーション用
 */
export const currentFortuneTellerState = selector<FortuneTeller>({
  key: keys.currentFortuneTeller,
  get: ({ get }) => get(appState).currentFortuneTeller,
  set: ({ get, set }, newValue) =>
    set(appState, { ...get(appState), currentFortuneTeller: newValue }),
});

export default appState;
