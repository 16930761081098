import clients from "shared/repositories/HttpClient";
import { GetPayjpCardInfoRes } from "shared/types";

const PayjpCardInformationRepository = {
  get: async () => {
    const res = await clients.miror.get<GetPayjpCardInfoRes>(
      `/me/payjp_card_information`
    );

    return res.data;
  },
  post: async (token: string) => {
    const params = { payjpCardInformation: { token } };
    const res = await clients.miror.post<GetPayjpCardInfoRes>(
      `/me/payjp_card_information`,
      params
    );

    return res.data;
  },
  delete: async (): Promise<boolean> => {
    const res = await clients.miror.delete(`/me/payjp_card_information`);
    return res.status === 200;
  },
};

export default PayjpCardInformationRepository;
