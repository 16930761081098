import { NextPageContext, NextApiResponse } from "next";
import { CookieSerializeOptions } from "next/dist/server/web/types";
import { setCookie as setNookie } from "nookies";

const hostName =
  (process.env.NEXTAUTH_URL && new URL(process.env.NEXTAUTH_URL).hostname) ||
  window.location.hostname;

const domain = hostName == "localhost" ? hostName : "miror.jp";

export const defaultCookieOption = {
  path: "/",
  domain: process.env.NEXTAUTH_COOKIE_DOMAIN || domain,
};

export const keys = {
  campaign: "miror.campaign",
  channelTalk: "miror.channelTalk",
  prevLocation: "prev_location",
} as const;

export const setCookie = (
  ctx:
    | Pick<NextPageContext, "res">
    | {
        res: NextApiResponse<any>;
      }
    | {
        res: Response;
      },
  name: typeof keys[keyof typeof keys],
  value: any,
  options?: CookieSerializeOptions
) => {
  setNookie(ctx, name, value, {
    ...defaultCookieOption,
    ...options,
  });
};
