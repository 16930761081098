import { repository } from "repositories";
import { GetFreeFortuneTagRes, SWRResponse } from "shared/types";
import useSWR from "swr";

export const useFreeFortuneTag = (
  id: number
): SWRResponse<GetFreeFortuneTagRes> => {
  const repo = repository.freeFortuneTag.get(id);
  const { data, error, mutate } = useSWR(
    repo.path ? [repo.path, id] : undefined,
    repo.data
  );

  return {
    freeFortuneTag: data?.freeFortuneTag,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
