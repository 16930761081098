import { FormData as ProfileFormData } from "features/profile/hooks/useProfileForm";
import FormData from "form-data";
import { GetServerSidePropsContext } from "next";
import { User } from "shared/models/index";
import HttpClient from "shared/repositories/HttpClient";
import clients, { headers } from "shared/repositories/HttpClient";
import { SignInUser, EmailSignupParams } from "shared/types";

type PatchUserParams = Partial<ProfileFormData> & { image?: FileList };

const keys = {
  user: "user",
  image: "image",
  userProfileAttributes: "user_profile_attributes",
  name: "name",
  birthday: "birthday",
  gender: "gender",
  bloodtype: "bloodtype",
  phoneNumber: "phone_number",
} as const;

export class UserFormData extends FormData {
  constructor({
    image,
    name,
    birthday,
    gender,
    bloodtype,
    phoneNumber,
  }: PatchUserParams) {
    super();
    image &&
      image.length != 0 &&
      this.append(`${keys.user}[${keys.image}]`, image[0]);
    name &&
      this.append(
        `${keys.user}[${keys.userProfileAttributes}][${keys.name}]`,
        name
      );
    birthday &&
      this.append(
        `${keys.user}[${keys.userProfileAttributes}][${keys.birthday}]`,
        birthday
      );
    gender &&
      this.append(
        `${keys.user}[${keys.userProfileAttributes}][${keys.gender}]`,
        gender
      );
    bloodtype &&
      this.append(
        `${keys.user}[${keys.userProfileAttributes}][${keys.bloodtype}]`,
        bloodtype
      );
    phoneNumber &&
      this.append(`${keys.user}[${keys.phoneNumber}]`, phoneNumber);
  }
}

const UsersRepository = {
  show: async (url: string, ctx?: GetServerSidePropsContext): Promise<User> => {
    const res = await clients.miror.get(url, {
      headers: ctx?.req ? { cookie: ctx.req.headers.cookie } : undefined,
    });
    return new User(res.data.user);
  },
  post: async (params: EmailSignupParams): Promise<SignInUser> => {
    const res = await clients.miror.post("/users", {
      nextAuthJwt: { ...params },
    });
    return res.data;
  },
  patch: async (params: PatchUserParams): Promise<boolean> => {
    const data = new UserFormData(params);
    const res = await clients.miror.patch(`/me`, data, headers.formData);
    return res.status === 200;
  },
  delete: async (): Promise<boolean> => {
    const res = await clients.miror.delete(`/me`);
    return res.status === 200;
  },
};

export class UserRepositoryV2 {
  private getObjectPath() {
    return `/me`;
  }

  private getPatchPath() {
    return `/me`;
  }

  public getObject(ctx?: GetServerSidePropsContext) {
    const path = this.getObjectPath();

    const fetcher = () =>
      HttpClient.miror
        .get<{ user: User }>(path, {
          headers: ctx?.req ? { cookie: ctx.req.headers.cookie } : undefined,
        })
        .then((res) => res.data);

    return { path, fetcher };
  }

  public patch() {
    const path = this.getPatchPath();

    const req = (params: PatchUserParams) => {
      const data = new UserFormData(params);

      HttpClient.miror
        .patch<{ user: User }>(path, data, headers.formData)
        .then((res) => res.data);
    };

    return { path, req };
  }
}

export default UsersRepository;
