import { ja as shared } from "shared/utils/locale";

const ja = {
  ...shared,
  account: {
    deleted: "退会処理が完了しました",
    registered: "既に会員登録済みです",
  },
  coupon: {
    received: "クーポンを受け取りました",
    noCampaignId: "キャンペーンは終了しています",
  },
  socialAccount: {
    linkedToLoggedInUser: "連携が完了しました",
  },
} as const;

export default ja;
