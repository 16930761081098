import { useRouter } from "next/router";
import { useEffect } from "react";
import { useFlashContext } from "shared/hooks/useFlashContext";
import { Queries, queries } from "utils/routes";

export const useQueryEffect = (): void => {
  const router = useRouter();
  const query = router.query as unknown as Queries;
  const { toggleFlash } = useFlashContext();

  // TODO: utils/routes/queriesで型付けする
  useEffect(() => {
    if ((query.error as string) === "true") {
      toggleFlash({ message: "問題が発生しました" });
      // 問題が発生しました
    } else if (query.error === queries.values.error.default) {
      toggleFlash({ message: queries.messages.error.default });
      // 無効なリクエストです
    } else if (query.error === queries.values.error.badRequest) {
      toggleFlash({ message: queries.messages.error.badRequest });
      // 不正なアクセスです
    } else if (query.error === queries.values.error.unauthorized) {
      toggleFlash({ message: queries.messages.error.unauthorized });
      // 不正なアクセスです
    } else if (query.error === queries.values.error.forbidden) {
      toggleFlash({ message: queries.messages.error.forbidden });
      // データが見つかりません
    } else if (query.error === queries.values.error.notFound) {
      toggleFlash({ message: queries.messages.error.notFound });
      // 確認メールを送信しました
    } else if (query.email === queries.values.email.sendConfirmation) {
      toggleFlash({ message: queries.messages.email.sendConfirmation });
      // メールアドレスの確認が完了しました
    } else if (query.email === queries.values.email.confirmed) {
      toggleFlash({ message: queries.messages.email.confirmed });
      // 連携が完了しました
    } else if (
      query.socialAccount === queries.values.socialAccount.linkedToLoggedInUser
    ) {
      toggleFlash({
        message: queries.messages.socialAccount.linkedToLoggedInUser,
      });
      // LINE通知を設定しました
    } else if (query.lineNotify === queries.values.lineNotify.enabled) {
      toggleFlash({ message: queries.messages.lineNotify.enabled });
      // LINE通知を解除しました
    } else if (query.lineNotify === queries.values.lineNotify.disabled) {
      toggleFlash({ message: queries.messages.lineNotify.disabled });
    } else if (((query as any).emailConfirmation as string) === "failure") {
      toggleFlash({ message: "無効なリンクです" });
    } else if ((query as any).unauthorized as string) {
      toggleFlash({
        message: "ログインセッションが切れました。再度ログインしてください。",
      });
    } else if (query.signUp === queries.values.signUp.success) {
      // 登録が完了しました
      toggleFlash({ message: queries.messages.signUp.success });
      // ログインしました
    } else if (query.signIn === queries.values.signIn.success) {
      toggleFlash({ message: queries.messages.signIn.success });
      // キャンペーンは終了しています
    } else if (
      query.receiveCoupon === queries.values.receiveCoupon.noCampaignId
    ) {
      toggleFlash({ message: queries.messages.receiveCoupon.noCampaignId });
    } else if (((query as any).resetPasswordToken as string) === "notFound") {
      toggleFlash({ message: "無効なリンクです" });
      // パスワードの設定について数分以内にメールでご連絡いたします
    } else if (query.password === queries.values.password.sentSetPasswordMail) {
      toggleFlash({ message: queries.messages.password.sentSetPasswordMail });
      // パスワードの再設定が完了しました
    } else if (query.password === queries.values.password.reset) {
      toggleFlash({ message: queries.messages.password.reset });
    } else if (((query as any).updateProfile as string) === "success") {
      toggleFlash({ message: "プロフィールを更新しました" });
      // パスワードを更新しました
    } else if (query.password === queries.values.password.updated) {
      toggleFlash({ message: queries.messages.password.updated });
      // ログアウトしました
    } else if (query.signOut === queries.values.signOut.success) {
      toggleFlash({ message: queries.messages.signOut.success });
      // クーポンを受け取りました
    } else if (query.receiveCoupon === queries.values.receiveCoupon.success) {
      toggleFlash({ message: queries.messages.receiveCoupon.success });
      // 既に会員登録済みです
    } else if (
      query.receiveCoupon === queries.values.receiveCoupon.registered
    ) {
      toggleFlash({ message: queries.messages.receiveCoupon.registered });
      // 退会処理が完了しました
    } else if (query.deleteAccount === queries.values.deleteAccount.success) {
      toggleFlash({ message: queries.messages.deleteAccount.success });
    }
  }, [query, toggleFlash]);
};
