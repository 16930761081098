import HttpClient from "shared/repositories/HttpClient";
import { GetFreeFortuneCategoryRes } from "shared/types";

export class FreeFortuneCategoryRepository {
  private getPath(id: number) {
    return id ? `/free_fortune/categories/${id}` : null;
  }

  public get(id: number) {
    const path = this.getPath(id);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFreeFortuneCategoryRes>(path)
          .then((res) => res.data),
    };
  }
}
