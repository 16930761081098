import ModalProvider from "mui-modal-provider";
import { Session } from "next-auth";
import { Provider as SessionProvider } from "next-auth/client";
import { FC } from "react";
import { RecoilRoot } from "recoil";
import { useFlash, FlashProvider } from "shared/hooks/useFlash";
import { useRouter } from "next/router";
import { DFPSlotsProvider } from "react-dfp";

interface Props {
  session: Session;
}

const Providers: FC<Props> = ({ children, session }) => {
  const flash = useFlash();
  const { asPath } = useRouter();

  const isMedia = /(press|miracle)\/column/.test(asPath);

  return (
    <RecoilRoot>
      <SessionProvider session={session}>
        <FlashProvider value={flash}>
          <ModalProvider>
            {isMedia ? (
              <DFPSlotsProvider dfpNetworkId="">{children}</DFPSlotsProvider>
            ) : (
              children
            )}
          </ModalProvider>
        </FlashProvider>
      </SessionProvider>
    </RecoilRoot>
  );
};

export default Providers;
