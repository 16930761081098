import { Colors } from "../../styles";

const faviconPath = `/favicons/${process.env.NEXT_PUBLIC_APP_ENV}`;
export const Favicon = () => {
  return (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${faviconPath}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${faviconPath}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${faviconPath}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${faviconPath}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${faviconPath}/safari-pinned-tab.svg`}
        color="#E271B6"
      />
      <meta name="msapplication-TileColor" content={Colors.pink["01"]} />
      <meta name="theme-color" content={Colors.pink["01"]} />
    </>
  );
};
