export const purchaseHistory = {
  status: {
    /** chat_rooms.status: 0 */
    unpaid: "支払い前",
    /**
     * chat_room.status: 10
     * tel_fortune_chat_rooms.status: 10
     */
    inactive: "鑑定開始前",
    /** tel_fortune_chat_rooms.status: 20 */
    fixing_schedule: "鑑定中",
    /** tel_fortune_chat_rooms.status: 21 */
    fixed_schedule: "鑑定中",
    /** tel_fortune_chat_rooms.status: 30 */
    rescheduling: "鑑定中",
    /** chat_rooms.status: 20 */
    activated: "鑑定中",
    /** tel_fortune_chat_rooms.status: 31 */
    ringing: "鑑定中",
    /** tel_fortune_chat_rooms.status: 32 */
    admin_answered: "鑑定中",
    /** tel_fortune_chat_rooms.status: 33 */
    admin_stopped_ringing: "鑑定中",
    /** tel_fortune_chat_rooms.status: 34 */
    user_stopped_ringing: "鑑定中",
    /** tel_fortune_chat_rooms.status: 40 */
    end_phone_call: "鑑定中",
    /** tel_fortune_chat_rooms.status: 41 */
    cut_off: "鑑定中",
    /** tel_fortune_chat_rooms.status: 42 */
    admin_miss_call: "鑑定中",
    /** tel_fortune_chat_rooms.status: 43 */
    failed_talking: "鑑定中",
    /** tel_fortune_chat_rooms.status: 44 */
    succeeded_talking: "鑑定中",
    /**
     * chat_room.status: 30
     * tel_fortune_chat_rooms.status: 50
     */
    closed: "鑑定終了",
    /**
     * chat_room.status: 40
     * tel_fortune_chat_rooms.status: 60
     */
    standard_canceled: "キャンセル済み",
    /** chat_room.status: 41 */
    first_time_canceled: "キャンセル済み",
    /** tel_fortune_chat_rooms.status: 61 */
    after_end_canceled: "キャンセル済み",
    /**
     * chat_room.status: 42
     * tel_fortune_chat_rooms.status: 62
     */
    exceptional_canceled: "キャンセル済み",
    /**
     * chat_room.status: 43
     * tel_fortune_chat_rooms.status: 63
     */
    automatically_canceled: "キャンセル済み",
  } as const,
};
