import { FreeFortuneThemes } from "repositories";
import { FreeFortuneTheme } from "shared/models";
import { ResultType } from "shared/models/free_fortune_chat_question";
import { SWRResponse, TODO_Transfer } from "shared/types";
import useSWR from "swr";

export const useFreeFortuneThemes = (
  resultType: ResultType
): SWRResponse<{ themes: FreeFortuneTheme[] }> => {
  const { data, error, mutate } = useSWR(
    [`/free_fortune_themes`, resultType],
    FreeFortuneThemes.index
  );

  return {
    themes: data ?? [],
    isLoading: !error && !data,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};
