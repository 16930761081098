import { TagType } from "../types";

export default class Tag {
  id: number;
  name: string;
  bannerUrl?: string;
  menuCounts?: number;
  fortuneTellersAmount?: number;
  variant?: TagType;
  metaDescriptionType?: TagType;

  constructor(data?: Partial<Tag>) {
    Object.assign(this, data);
  }

  get isScene(): boolean {
    return this.metaDescriptionType === "scene";
  }

  get isDivination(): boolean {
    return this.metaDescriptionType === "divination";
  }
}
