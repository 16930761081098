import * as models from "./model";

const ja = {
  models,
  error: {
    default: "問題が発生しました",
    badRequest: "無効なリクエストです",
    unauthorized: "不正なアクセスです",
    forbidden: "不正なアクセスです",
    notFound: "データが見つかりません",
    unprocessableEntity: "入力されたデータに問題があります",
    tokenExpired: "ログインセッションが切れました。再度ログインしてください。",
    payjp: {
      default: "カード情報に誤りがございます。",
      invalidNumber: "カード番号をご確認ください。",
      invalidCvc: "CVCをご確認ください。",
      invalidExpirationDate: "有効期限年、または月をご確認ください。",
      invalidExpiryMonth: "有効期限月をご確認ください。",
      invalidExpiryYear: "有効期限年をご確認ください。",
      expiredCard: "カードの有効期限切れです。",
      cardDeclined: "クレジットカード会社にお問い合わせください。",
      processingError: "カード登録に失敗しました。",
    },
  },
  email: {
    updated: "確認メールを送信しました",
    confirmed: "メールアドレスの確認が完了しました",
    sendConfirmation: "確認メールを送信しました",
    confirmationFailed: "無効なリンクです",
  },
  password: {
    updated: "パスワードを更新しました",
    reset: "パスワードの再設定が完了しました",
    sentSetPasswordMail:
      "パスワードの設定について数分以内にメールでご連絡いたします。",
    sentResetPasswordMail:
      "パスワードの再設定について数分以内にメールでご連絡いたします。",
    invalidToken: "無効なリンクです",
  },
  signUp: "登録が完了しました",
  signIn: "ログインしました",
  signOut: "ログアウトしました",
  lineNotify: {
    enabled: "LINE通知を設定しました",
    disabled: "LINE通知を解除しました",
  },
} as const;

export default ja;
