import clients from "shared/repositories/HttpClient";
import {
  ID,
  GetFortuneTellerRes,
  GetFortuneTellerListRes,
  GetFortuneTellerMetaDataRes,
  MenuTypeV2,
  FortuneTellerOrderBy,
} from "shared/types";

export type WaitingStatus = "waiting";
export interface Params {
  page?: number;
  limit?: number;
  menuType?: MenuTypeV2;
  waitingStatus?: WaitingStatus;
  tagId?: ID;
  categoryAlias?: string;
  orderBy?: FortuneTellerOrderBy;
  shuffle?: boolean;
}

export class FortuneTellerRepository {
  private getObjectPath(id?: ID) {
    return id ? `/fortune_tellers/${id}` : undefined;
  }

  private getListPath() {
    return `/fortune_tellers`;
  }

  private getMetaDataPath(id?: ID) {
    return id ? `/fortune_tellers/${id}/meta_data` : undefined;
  }

  public getObject(id: ID) {
    const path = id ? this.getObjectPath(id) : null;
    return {
      path,
      data: () =>
        clients.miror.get<GetFortuneTellerRes>(path).then((res) => res.data),
    };
  }

  public getList(params?: Params & { waiting?: WaitingStatus }) {
    const path = this.getListPath();
    return {
      path,
      data: () =>
        clients.miror
          .get<GetFortuneTellerListRes>(path, {
            params: { ...params, waitingStatus: params.waiting },
          })
          .then((res) => res.data),
    };
  }

  public getMetaData(id: ID) {
    const path = this.getMetaDataPath(id);
    return {
      path,
      data: () =>
        clients.miror
          .get<GetFortuneTellerMetaDataRes>(path)
          .then((res) => res.data),
    };
  }
}
