import { repository } from "repositories";
import {
  WithPaginationMetaResponse,
  GetFortuneTellerListRes,
  SWRResponse,
  GetFortuneTellerListParams,
  TODO_Transfer,
} from "shared/types";
import useSWR from "swr";

export const useFortuneTellerList = ({
  skip,
  ...params
}: GetFortuneTellerListParams): SWRResponse<
  WithPaginationMetaResponse<GetFortuneTellerListRes>
> => {
  const repo = repository.fortuneTeller.getList(params);
  const key = skip ? null : [repo.path, ...Object.values(params)];

  const { data, error, mutate } = useSWR(key, repo.data);

  return {
    ...data,
    isLoading: !data && !error,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};
