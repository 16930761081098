import Providers from "components/layout/Providers";
import "shared/styles/globals.css";
import Root from "components/utils/Root";
import { NextComponentType } from "next";
import { DefaultSeo } from "next-seo";
import { AppProps as DefaultAppProps } from "next/app";
import Head from "next/head";
import "dayjs/locale/ja";
import { Favicon } from "shared/components/utils/Meta";
import useTracking from "shared/hooks/useTracking";
import SEO from "../next-seo.config";
import "shared/lib/yup/extend";
import { ReactNode } from "react";
import { BrowserTracing } from "@sentry/tracing";
import { Sentry } from "lib/sentry";

if (process.env.NODE_ENV === "production") {
  require("styles/build.css");
} else {
  require("shared/styles/tailwind.css");
}

Sentry.init({
  dsn: "https://b8c8865288b3448583c6fd4b99a43175@o217269.ingest.sentry.io/6532823",
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.0,
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  initialScope: {
    tags: { package: "user" },
  },
  ignoreErrors: [
    "Non-Error promise rejection captured",
    "Load failed",
    "e.yn is not a function.",
  ],
});

export interface AppProps extends DefaultAppProps {
  Component: NextComponentType & {
    getLayout: () => ReactNode;
  };
}

const Miror = ({ Component, pageProps }: AppProps) => {
  useTracking();
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <Favicon />
      </Head>
      {/* DefaultSeoはHeadに入れたくなるがちゃんと動かなくなるので外に置くこと☺︎!! */}
      <DefaultSeo {...SEO} />
      <Providers session={pageProps.session}>
        <Root Component={Component}>
          <Component {...pageProps} />
        </Root>
      </Providers>
    </>
  );
};

export default Sentry.withProfiler(Miror);
