import {
  MessageFormData,
  MessageParams,
} from "shared/features/chat_rooms/hooks/useMessageForm";
import { Message } from "shared/models/index";
import clients, { headers } from "shared/repositories/HttpClient";
import httpClient from "shared/repositories/HttpClient";

const MessagesRepository = {
  get: async (chatRoomId: string | string[] | number): Promise<Message[]> => {
    const res = await httpClient.miror.get(
      `/chat_rooms/${chatRoomId}/messages`
    );
    return (
      res.data?.messages?.map((message: Message) => new Message(message)) ?? []
    );
  },
  post: async (
    chatRoomId: string | string[] | number,
    params: MessageParams
  ): Promise<Message> => {
    const path = `/chat_rooms/${chatRoomId}/messages`;
    const data = new MessageFormData(params);
    const res = await clients.miror.post(path, data, headers.formData);
    return new Message(res.data.message);
  },
};

export default MessagesRepository;
