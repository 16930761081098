import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { MenuType, Fortune, TelFortune } from "../types";

dayjs.extend(isSameOrAfter);

const couponRestrictionTypeNames: Record<string, string> = {
  fortune_teller: "特定の占い師",
};

interface CouponRestrictionColumn {
  name: string;
  value: number;
}

interface CouponRestrictionType {
  name: string;
  couponRestrictionColumns: CouponRestrictionColumn[];
}

export default class UserCoupon {
  id: number;
  name: string;
  discountAmount: number;
  discountRate: number;
  forType: "chat" | "tel";
  isCombined: boolean;
  planToRedeem: boolean;
  minimumPrice: number;
  expiredAt: Dayjs;
  isAvailable?: boolean;
  couponId?: number;
  ownershipId?: number;
  menuType?: MenuType;
  menu?: Fortune | TelFortune;
  redeemedAt?: Dayjs;
  couponRestrictionTypes: CouponRestrictionType[];

  constructor(data?: Partial<UserCoupon>) {
    Object.assign(this, data);

    if (!data) return;

    this.expiredAt = dayjs(data.expiredAt);
    this.redeemedAt = data.redeemedAt ? dayjs(data.redeemedAt) : null;
  }

  get isExpired(): boolean {
    return dayjs().isSameOrAfter(this.expiredAt);
  }

  get description(): string {
    const restrictions = [];

    if (!this.isCombined) {
      restrictions.push("併用不可");
    }

    if (this.minimumPrice > 0) {
      restrictions.push(`${this.minimumPrice}円以上購入時のみ利用可能`);
    }

    if (this.couponRestrictionTypes.length > 0) {
      this.couponRestrictionTypes.forEach((restrictionType) => {
        const restriction = couponRestrictionTypeNames[restrictionType.name];
        restrictions.push(`${restriction}のみ`);
      });
    }

    return restrictions.join(", ");
  }

  discountPriceFor(price: number): number {
    if (this.discountAmount >= 1) {
      return this.discountAmount;
    } else if (this.discountRate >= 1) {
      return (price * this.discountRate) / 100;
    } else {
      return 0;
    }
  }
}
