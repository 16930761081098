import { FreeFortuneChatReplies } from "repositories";
import { FreeFortuneChatReply } from "shared/models";
import { SWRResponse, TODO_Transfer } from "shared/types";
import useSWR from "swr";

export const useFreeFortuneChatReplies = (
  freeFortuneChatQuestionId: string
): SWRResponse<{ replies: FreeFortuneChatReply[] }> => {
  const { data, error, mutate } = useSWR(
    `/free_fortune_chat_questions/${freeFortuneChatQuestionId}/free_fortune_chat_replies`,
    FreeFortuneChatReplies.index,
    { fallbackData: [] }
  );

  return {
    replies: data,
    isLoading: !error && !data,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};
