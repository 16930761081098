import Axios, { AxiosRequestConfig } from "axios";
import qs from "qs";

import { mapKeysSnakeCase, mapKeysCamelCase } from "../utils";

const isDebug = process.env.NEXT_PUBLIC_DEBUG === "true";

export const hosts = {
  miror: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/v1/`,
  media: `https://media-api.miror.jp/v1/`,
  works: `https://works.dears-fortune.jp/api/v1/`,
};

export const headers = {
  json: {
    headers: {
      "Content-Type": "application/json",
    },
  },
  formData: {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  },
};

export const config: AxiosRequestConfig = {
  ...headers.json,
  paramsSerializer: (params: any) =>
    qs.stringify(params, { arrayFormat: "brackets" }),
  responseType: "json",
  timeout: 20000,
};

const miror = Axios.create({
  baseURL: hosts.miror,
  ...config,
  withCredentials: true,
});

const media = Axios.create({
  baseURL: hosts.media,
  ...config,
});

const works = Axios.create({
  baseURL: hosts.works,
  ...config,
});

const admin = Axios.create({
  baseURL: `${hosts.miror}admin/`,
  ...config,
  withCredentials: true,
});

const superAdmin = Axios.create({
  baseURL: `${hosts.miror}super_admin/`,
  ...config,
  withCredentials: true,
  headers: {
    Authorization: `Bearer eyJhbGciOiJub25lIn0.eyJjbGllbnRfaWQiOiIxRWhDaWFYRV9pNjlGeUQ3ZWZBWFlKOERTQzQzbnR0Tk5mSFEteFdOdHQ0IiwiY2xpZW50X3NlY3JldCI6ImNVVTUtNGtwdlpteENZTDVSR2RKQ1J4RlFNaE05TkxTZVFqVWhwR2RWbUkifQ.`,
  },
});

const clients = [miror, media, works, admin, superAdmin];

clients.map((client) => {
  client.interceptors.request.use(
    (config) => {
      config.data = mapKeysSnakeCase(config.data);
      config.params = mapKeysSnakeCase(config.params);
      if (isDebug) console.log("Request: ", config);
      return config;
    },
    (error) => {
      if (isDebug) console.log("Request Error: ", error);
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => {
      response.data = mapKeysCamelCase(response.data);
      if (isDebug) console.log("Response: ", response);
      return response;
    },
    (error) => {
      if (isDebug) console.log("Response Error: ", error);
      return Promise.reject(error);
    }
  );
});

const HttpClient = {
  miror,
  media,
  works,
  admin,
  superAdmin,
};

export default HttpClient;
