import HttpClient from "shared/repositories/HttpClient";
import {
  GetTelFortuneListParams,
  GetTelFortuneListRes,
  GetTelFortuneMetaDataRes,
  GetTelFortuneRes,
  ID,
} from "shared/types";

export class TelFortuneRepositoryV2 {
  private getObjectPath(id: ID) {
    return `/tel_fortunes/${id}`;
  }

  private getListPath() {
    return `/tel_fortunes`;
  }

  private getMetaDataPath(id: ID) {
    return id ? `/tel_fortunes/${id}/meta_data` : undefined;
  }

  public getObject(id: ID) {
    const path = id ? this.getObjectPath(id) : null;

    return {
      path,
      data: () =>
        HttpClient.miror.get<GetTelFortuneRes>(path).then((res) => res.data),
    };
  }

  public getList(params?: GetTelFortuneListParams) {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTelFortuneListRes>(path, { params })
          .then((res) => res.data),
    };
  }

  public getMetaData(id: ID) {
    const path = this.getMetaDataPath(id);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTelFortuneMetaDataRes>(path)
          .then((res) => res.data),
    };
  }
}
