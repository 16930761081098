import { FC, ReactNode, useState } from "react";
import { css } from "styled-components";
import tw, { styled } from "twin.macro";

import CancelIcon from "../../../assets/icons/cancel.svg";
import { zIndex } from "../../../styles";
import { Dissmissable } from "../../atoms/Dissmissable";

const Modal = styled.div<{
  visible: boolean;
}>`
  position: fixed;
  z-index: ${zIndex.floating};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  max-width: 640px;
  margin: 0 auto;
`;

export interface BaseModalProps {
  title?: string | ReactNode;
  subTitle?: string;
  className?: string;
  onDismiss?: () => void;
  dismissable?: boolean;
  backgroundOnly?: boolean;
  children?: ReactNode;
}

/**
 * @param title [string]
 * @param subTitle [string]
 * @param onDismiss [() => void]
 * @param dismissable [boolean];
 * @param backgroundOnly [boolean]
 */
export const BaseModal: FC<BaseModalProps> = ({
  title,
  subTitle,
  className,
  onDismiss,
  dismissable = true,
  backgroundOnly,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDissmissModal = () => {
    if (dismissable) {
      if (onDismiss) {
        onDismiss();
      } else {
        setIsOpen(false);
      }
    }
  };

  return (
    <Modal visible={isOpen} tw="animate-fade-in">
      <div
        tw="absolute inset-0 bg-[rgba(0,0,0,.5)] flex-center"
        onClick={handleDissmissModal}
      />
      <div tw="relative w-4/5 max-w-sm" css={css({ zIndex: zIndex.floating })}>
        {backgroundOnly ? (
          <Dissmissable
            icon={<CancelIcon tw="mb-2" css={!dismissable && tw`hidden`} />}
            onDissmiss={handleDissmissModal}
            tw="animate-zoom-in"
          >
            {children}
          </Dissmissable>
        ) : (
          <Dissmissable
            icon={<CancelIcon tw="mb-2" css={!dismissable && tw`hidden`} />}
            onDissmiss={handleDissmissModal}
            tw="animate-zoom-in"
          >
            <div
              tw="bg-white py-8 px-5 rounded-3xl text-center"
              className={className}
            >
              {title && (
                <h3 tw="font-base text-base tracking-[.8px] whitespace-pre-line">
                  {title}
                </h3>
              )}
              {subTitle && (
                <p tw="font-base font-light text-2xs leading-5 mt-[10px] whitespace-pre-line mb-6">
                  {subTitle}
                </p>
              )}
              <div tw="w-full">{children}</div>
            </div>
          </Dissmissable>
        )}
      </div>
    </Modal>
  );
};
