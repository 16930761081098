import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { UserCoupon } from "shared/models";
import { MenuTypeV2, PaymentMethodType } from "shared/types";

const { persistAtom } = recoilPersist();

export interface OrderState {
  _type: MenuTypeV2;
  menuId: number;
  name: string;
  birthday: string;
  gender: string;
  bloodtype: string;
  consultContent: string;
  phoneNumber: string;
  paymentMethod: PaymentMethodType;
  paymentMethodForm: PaymentMethodType;
  usePoint: boolean;
  useCoupon: boolean;
  selectedCoupons: UserCoupon[];
  cvsName: string; // TODO: enum
  firstName: string;
  lastName: string;
  lastNameKana: string;
  fromNow: boolean;
}

export const orderState = atom<OrderState>({
  key: "orderState",
  default: {
    _type: "chat",
    menuId: 0,
    name: "",
    birthday: "",
    gender: "",
    bloodtype: "",
    consultContent: "",
    phoneNumber: "",
    paymentMethod: null,
    paymentMethodForm: null,
    usePoint: false,
    useCoupon: false,
    selectedCoupons: [],
    cvsName: "",
    firstName: "",
    lastName: "",
    lastNameKana: "",
    fromNow: false,
  },
  effects_UNSTABLE: [persistAtom],
});
