/** @jsxImportSource @emotion/react */
import { useState } from "react";
import tw from "twin.macro";

interface Tab {
  label: string;
  query?: string;
  onClick?: (idx: number) => void;
}

interface Props {
  tabs: Tab[];
  initialTabIdx?: number;
  onClick?: (idx: number) => void;
}

export const Tabs = ({ tabs, initialTabIdx = 0, onClick }: Props) => {
  const [activeTabIdx, setActiveTabIdx] = useState(
    initialTabIdx > 0 ? initialTabIdx : 0
  );

  const handleClickTab = (idx: number) => {
    setActiveTabIdx(idx);

    const tab = tabs[idx];

    if (tab && tab.onClick) {
      tab.onClick(idx);
    } else if (onClick) {
      onClick(idx);
    }
  };

  return (
    <ul
      tw="grid grid-flow-col border border-solid border-theme rounded-full max-w-screen-sm"
      css={{ gridAutoColumns: "1fr" }}
    >
      {tabs.map((tab, idx) => (
        <li
          key={tab.label}
          tw="text-center cursor-pointer h-12 flex items-center justify-center"
          css={[
            activeTabIdx === idx
              ? tw`from-[#A84FB2] to-[#E68AB4] text-white`
              : tw`from-white to-white text-theme`,
            idx / 2 === 0
              ? tw`bg-gradient-to-l rounded-l-full`
              : tw`bg-gradient-to-r rounded-r-full`,
          ]}
          onClick={() => handleClickTab(idx)}
        >
          <span
            tw="font-semibold text-sm leading-none"
            css={activeTabIdx === idx ? tw`text-white` : tw`text-theme`}
          >
            {tab.label}
          </span>
        </li>
      ))}
    </ul>
  );
};
