import { SWRConfiguration } from "swr";
import { AxiosError } from "axios";
import { useErrorMessage } from "../../hooks/useErrorMessage";
import useLogout from "../../hooks/useLogout";
import { useRouter } from "next/router";
import { queries } from "../../utils/locale";

/**
 *
 * see: https://swr.vercel.app/docs/advanced/cache#localstorage-based-persistent-cache
 */
// function localStorageProvider() {
//   // When initializing, we restore the data from `localStorage` into a map.
//   const map = new Map(JSON.parse(localStorage.getItem("app-cache") || "[]"));

//   // Before unloading the app, we write back all the data into `localStorage`.
//   window.addEventListener("beforeunload", () => {
//     const appCache = JSON.stringify(Array.from(map.entries()));
//     localStorage.setItem("app-cache", appCache);
//   });

//   // We still use the map for write & read for performance.
//   return map;
// }

export const useSwrConfig = (): SWRConfiguration => {
  const { push, query } = useRouter();
  const logout = useLogout();
  const { handleAxiosError } = useErrorMessage();

  const handleError = async (error: AxiosError) => {
    switch (error?.response?.status) {
      case 401: {
        logout({ ...query, unauthorized: true });
        break;
      }
      case 403: {
        push({
          pathname: "/",
          query: { [queries.keys.error]: queries.values.error.forbidden },
        });
        break;
      }
      case undefined: {
        handleAxiosError(error);
        break;
      }
      default: {
        handleAxiosError(error);
        break;
      }
    }
  };

  return {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onError: handleError,
    /**
     * TODO:
     *  - パフォーマンス向上のためにuseSWRの結果をlocalStorageにキャッシュして、
     *    cacheファーストなデータローディングを行いたい
     *  - しかし、現状APIから取得したデータはrepositoriesでclassインスタンス化した上、
     *    各所でインスタンスメソッドなどを使用しているため、
     *    キャッシュ先をlocalStorageに切り替えるとキャッシュされたデータがjsonオブジェクト化し、
     *    各所のインスタンスメソッドが軒並み動かなくなる
     *  - そのため、まずはモデルごとにclassインスタンス化をやめてコンポーネントを調整し、
     *    全てのモデルで脱classインスタンス化が完了したらキャッシュ先をlocalStorageに切り替えたい
     */
    // provider:
    //   typeof window !== "undefined" ? localStorageProvider : () => new Map(),
  };
};
