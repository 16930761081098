import { NextSeoProps } from "next-seo";

const envLabel = (() => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case "development":
    case "ngrok":
      return "【開発】";
    case "staging":
      return "【QA】";
    case "production":
      return "";
    default:
      return "";
  }
})();

const title = `${envLabel}当たると口コミで評判のチャット占い！`;

const description = `${envLabel}登録無料ですぐに本格メール占いが受けられる！完全非公開で恋愛、結婚、相性、片思い、復縁などの日々の不安や悩みを相談できます。実力派の占い師があなたの未来を徹底鑑定！`;

const url = (() => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case "development":
      return "http://localhost:3000/";
    case "ngrok":
      return "https://miror.jp.ngrok.io/";
    case "staging":
      return "https://staging.miror.jp/";
    case "production":
      return "https://miror.jp/";
    default:
      return "https://miror.jp/";
  }
})();

const config: NextSeoProps = {
  defaultTitle: title,
  description,
  twitter: {
    cardType: "summary_large_image",
  },
  facebook: {
    appId: "730106420521855",
  },
  openGraph: {
    title,
    description,
    url,
    type: "article",
    images: [{ url: "https://cdn.miror.jp/v2/og/miror.png" }],
  },
  noindex: process.env.NEXT_PUBLIC_APP_ENV !== "production",
  nofollow: process.env.NEXT_PUBLIC_APP_ENV !== "production",
};

export default config;
