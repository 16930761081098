import Axios from "axios";
import { FeatureFlags } from "shared/types";
import { mapKeysCamelCase } from "shared/utils";
import useSWR from "swr";

const axios = Axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
  withCredentials: true,
});

axios.interceptors.response.use((response) => {
  response.data = mapKeysCamelCase(response.data);
  return response;
}, Promise.reject);

/**
 * @param isSubscriptionEnabled boolean
 * @param supportProgram boolean
 */
export const useFeatureFlags = (): FeatureFlags => {
  const { data } = useSWR(
    "/feature_flags",
    (url) =>
      axios
        .get<FeatureFlags>(`${process.env.NEXT_PUBLIC_API_ENDPOINT}${url}`)
        .then((res) => res.data),
    {
      fallbackData: {
        isSubscriptionEnabled: false,
        supportProgram: false,
        isFreeFortunePopupEnabled: false,
        isCvFromChatToTelPocEnabled: false,
        isCvFromChatToSubscriptionPocEnabled: false,
        isTelFortuneDisabled: false,
        isOnlyFirstTimeMenuEnabled: false,
        isStampRallyEnabled: false,
        isMirorUiButtonEnabled: false,
        isPointHistoryV2Enabled: false,
        isPaymentLayoutV2Enabled: false,
        isTestFortuneTeller: false,
      },
    }
  );

  return data;
};
