import { MessageParams } from "yup/lib/types";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validation = (value: FileList) => {
  const file = value.item(0);
  return file ? SUPPORTED_FORMATS.includes(file.type) : false;
};

const error = ({ label }: MessageParams) =>
  `${label}はサポートされていない形式のファイルです`;

export const imgValidator = {
  name: "img",
  validation: function () {
    return this.test(null, error, validation);
  },
};
