import HttpClient from "shared/repositories/HttpClient";
import clients from "shared/repositories/HttpClient";
import { GetTagTopicListRes, ID, TagTopic } from "shared/types";

export class TagTopicRepositoryV2 {
  private getListPath() {
    return `topic_list`;
  }

  public getList(tagId: ID) {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.works
          .get<GetTagTopicListRes>(path, { params: { tagIds: tagId } })
          .then((res) => res.data),
    };
  }
}

const TagTopicsRepository = {
  index: async (
    url: string,
    params: { tagIds: string | number }
  ): Promise<TagTopic[]> => {
    const { data } = await clients.works.get<GetTagTopicListRes>(url, {
      params,
    });
    return data.topics;
  },
};

export default TagTopicsRepository;
