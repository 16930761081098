import { OrderTypes } from "features/tags/components/ButtonAccessory";
import HttpClient from "shared/repositories/HttpClient";
import clients from "shared/repositories/HttpClient";
import {
  GetCategoryFortuneListParams,
  GetTelFortuneListRes,
  ID,
} from "shared/types";

export class TagTelFortuneRepositoryV2 {
  private getListPath(tagId: ID) {
    return tagId ? `/tags/${tagId}/tel_fortunes` : null;
  }

  public getList(tagId: ID, params: GetCategoryFortuneListParams) {
    const path = this.getListPath(tagId);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTelFortuneListRes>(path, { params })
          .then((res) => res.data),
    };
  }
}

const TagTelFortunesRepository = {
  index: async (
    url: string,
    params?: {
      page?: number;
      limit?: number;
      orderBy?: OrderTypes;
      shuffle?: boolean;
    }
  ): Promise<GetTelFortuneListRes> => {
    const res = await clients.miror.get<GetTelFortuneListRes>(url, {
      params,
    });

    return res.data;
  },
};

export default TagTelFortunesRepository;
