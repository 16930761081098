import HttpClient from "shared/repositories/HttpClient";
import { GetCategoryTopicListRes, ID } from "shared/types";

export interface GetCategoryTopicListParams {
  exclude?: ID[];
  limit?: number;
  page?: number;
  order?: "random";
  meta?: boolean;
}

export class CategoryTopicRepository {
  private getListPath(alias: string | string[]) {
    return `/media/categories/${alias}/topics`;
  }

  public getList(
    alias: string | string[],
    params?: GetCategoryTopicListParams
  ) {
    const path = this.getListPath(alias);
    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetCategoryTopicListRes>(path, { params })
          .then((res) => res.data),
    };
  }
}
