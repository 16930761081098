import { repository } from "repositories";
import {
  GetCategoryFortuneListParams,
  GetFortuneListRes,
  ID,
  SWRResponse,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWR from "swr";

interface Params extends GetCategoryFortuneListParams {
  tagId: ID;
  skip?: boolean;
}

export const useTagFortuneList = ({
  tagId,
  skip,
  ...params
}: Params): SWRResponse<WithPaginationMetaResponse<GetFortuneListRes>> => {
  const repo = repository.tagFortune.getList(tagId, params);
  const { data, error, mutate } = useSWR(
    skip ? null : [repo.path, ...Object.values(params)],
    repo.data
  );

  return {
    ...data,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
