import { repository } from "repositories";
import {
  GetFortuneListRes,
  SWROptions,
  SWRResponse,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWR from "swr";

export const useFortunes = (
  options?: SWROptions
): SWRResponse<WithPaginationMetaResponse<GetFortuneListRes>> => {
  const params = { limit: 10 };
  const repo = repository.fortune.getList(params);

  const { data, error, mutate } = useSWR(
    options?.skip ? null : [repo.path, ...Object.values(params)],
    repo.data,
    {
      fallbackData: {
        fortunes: [],
        meta: { totalPages: 1 },
      },
    }
  );

  return {
    ...data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
