import Link from "next/link";
import { NotificationBar } from "shared/components/atoms/NotificationBar";
import { Paths } from "utils/routes";

interface Props {
  onClose?: () => void;
}

export const UnpaidNotification = ({ onClose }: Props) => {
  return (
    <NotificationBar tw="bg-gray" onClose={onClose}>
      <div tw="h-4">
        <Link href={Paths.purchaseHistories.index()} passHref>
          <a tw="flex-center">
            <span tw="font-base mr-2">お知らせ</span>
            <span tw="font-normal text-black">
              お支払いが完了するまで鑑定が開始されません
            </span>
            <div tw="bg-[#FE0014] rounded-full w-3 h-3 flex-center ml-2">
              <span tw="font-bold text-2xs">N</span>
            </div>
          </a>
        </Link>
      </div>
    </NotificationBar>
  );
};
