import { useMemo } from "react";
import { useKarteList } from "repositories/hooks";

export const useUnpaidOrder = () => {
  const { data, ...rest } = useKarteList({ isPaid: false, limit: 10 });
  const unpaidOrders = useMemo(
    () =>
      data.flatMap((d) => d.kartes).filter((karte) => karte && !karte.isPaid),
    [data]
  );

  return {
    unpaidOrders,
    ...rest,
  };
};
