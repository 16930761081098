/** @jsxImportSource @emotion/react */
import { ButtonHTMLAttributes, forwardRef } from "react";
import tw from "twin.macro";

/** ボタンの種類 */
export const buttonVariants = {
  filled: "filled",
  outlined: "outlined",
} as const;

type ButtonVariant = typeof buttonVariants[keyof typeof buttonVariants];

const getVariantClasses = (variant: ButtonVariant) => {
  switch (variant) {
    case "filled":
      return tw`bg-gradient-to-l from-[#B647B7] to-[#F683B6] text-white`;
    case "outlined":
      return tw`bg-white border border-theme text-theme`;
  }
};

/** 非活性化されたボタンのスタイル */
const getDisabledClasses = (
  variant: ButtonVariant,
  type: "submit" | "button" | "reset"
) => {
  switch (type) {
    case "submit": {
      return tw`bg-secondary text-white cursor-not-allowed`;
    }
    case "button":
    case "reset": {
      switch (variant) {
        case "filled":
          return [
            getVariantClasses(buttonVariants.filled),
            tw`opacity-60 cursor-not-allowed`,
          ];
        case "outlined":
          return [
            getVariantClasses(buttonVariants.outlined),
            tw`opacity-60 cursor-not-allowed`,
          ];
      }
    }
  }
};

const getComputedClasses = (
  variant: ButtonVariant,
  type: "submit" | "button" | "reset",
  disabled: boolean
) => {
  const variantClasses = getVariantClasses(variant);
  const disabledClasses = getDisabledClasses(variant, type);

  return disabled ? disabledClasses : variantClasses;
};

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof buttonVariants;
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      variant = buttonVariants.filled,
      type = "button",
      disabled = false,
      ...rest
    },
    ref
  ) => {
    const computedClasses = getComputedClasses(variant, type, disabled);
    return (
      <button
        ref={ref}
        tw="
          w-full h-14 sm:(max-w-sm mx-auto)
          rounded-full flex items-center justify-center
          text-base tracking-067 font-semibold leading-none
        "
        css={computedClasses}
        type={type}
        disabled={disabled}
        {...rest}
      >
        {children}
      </button>
    );
  }
);
