import { useSession } from "next-auth/client";
import * as Repositories from "repositories";
import useSWRInfinite from 'swr/infinite'
import { ChatRoomList } from "shared/models";
import {
  WithPaginationMetaResponse,
} from "shared/types";
import { SWRConfiguration } from "swr";

interface UseChatRoomListProps {
  perPage?: number;
}

export const useChatRoomList = (
  { perPage = 30 }: UseChatRoomListProps,
  options?: SWRConfiguration
) => {
  const [session] = useSession();

  const getKey = (
    pageIndex: number,
    previousPageData: WithPaginationMetaResponse<{
      chatRoomList: ChatRoomList[];
    }>
  ) => {
    // fetchした結果、データが存在せず空配列が返ってきたとき
    const isEmpty = previousPageData && !previousPageData.chatRoomList.length;

    if (!session || isEmpty) return null;

    return `/chat_room_list?page=${pageIndex + 1}&limit=${perPage}`;
  };

  return useSWRInfinite(
    getKey,
    Repositories.ChatRoomList.index,
    options
  );
};
