import dayjs, { Dayjs } from "dayjs";

import { defaultImages } from "../assets/images/defaultImages";
import { Data, Review } from "../types";

import BlockList from "./block_list";

import { Tag, Fortune, TelFortune, SampleChatRoom } from "./index";

export type RegistrationStatus =
  | "wait_confirm"
  | "confirmed"
  | "filled_profile"
  | "created_first_menu"
  | "deleted";

export type IsWaitingStatusKeys =
  | "waitingFlag"
  | "telWaitingFlag"
  | "both"
  | "any";

/** @deprecated should use shared/types/response/FortuneTellerResponse */
export default class FortuneTeller {
  /** ID */
  id: number;
  /** 名前 */
  name: string;
  /** オリジナル画像 */
  imageUrl: string;
  /** サムネイル */
  thumbnailUrl: string;
  /** レビュー平均点 */
  averageScore: number;
  /** 総レビュー件数 */
  reviewCounts: number;
  /** 受付枠数:進行中の鑑定数が1:1かどうか */
  isBusy: boolean;
  /** バナー画像 */
  bannerUrl: string;
  /** リサイズされたバナー画像 */
  processedBannerUrl: string;
  /** 公開済みのチャット鑑定が存在するか */
  havePublishedChat: boolean;
  /** 公開済みの電話鑑定が存在するか */
  havePublishedTel: boolean;
  /** チャット鑑定の申し込みを受付中/停止中 */
  chatAcceptFlag: boolean;
  /** 電話鑑定の申し込みを受付中/停止中 */
  telAcceptFlag: boolean;
  /** チャット鑑定の待機フラグ */
  waitingFlag: boolean;
  /** 電話鑑定の待機フラグ */
  telWaitingFlag: boolean;
  // ブロックされているかどうか
  isBlocked?: boolean;
  // 終了済みのチャット鑑定数
  totalChatSalesQuantity?: number;
  // チャット鑑定のレビュー平均点
  fortuneAverageScore?: number;
  // 終了済みの電話鑑定数
  totalTelSalesQuantity?: number;
  // 電話鑑定のレビュー平均点
  telFortuneAverageScore?: number;
  // 自己紹介文
  selfIntroduction?: string;
  // サンプルチャットルーム
  sampleChatRoom?: SampleChatRoom;
  // 占術タグ
  divinationTags?: Tag[];
  // チャット鑑定メニュー
  fortunes?: Fortune[];
  // 電話鑑定メニュー
  telFortunes?: TelFortune[];
  // チャット鑑定のレビュー + 電話鑑定のレビュー
  reviews?: Review[];
  // 受付枠数
  maxOngoingFortuneNumber?: number;
  // 進行中の鑑定数
  onGoingFortuneNumber?: number;
  // チャット鑑定の待機開始時刻
  chatWaitingStartedAt?: Dayjs;
  // 電話鑑定の待機開始時刻
  telWaitingStartedAt?: Dayjs;
  // キャンセル可能/不可能
  cancellable?: boolean;
  // ユーザーのブロック可能/不可能
  blockable?: boolean;
  // ブロック中のユーザー
  blockLists?: BlockList[];
  // 電話番号
  phoneNumber?: string;
  // 登録状態
  registrationStatus?: RegistrationStatus;

  constructor(data?: Partial<FortuneTeller>) {
    Object.assign(this, data);

    if (!data) return;

    this.chatWaitingStartedAt = dayjs(data.chatWaitingStartedAt);
    this.telWaitingStartedAt = dayjs(data.telWaitingStartedAt);
    this.thumbnailUrl = data.thumbnailUrl ?? defaultImages.user;
    this.imageUrl = data.imageUrl ?? this.thumbnailUrl ?? defaultImages.user;
    this.fortunes = data.fortunes
      ? data.fortunes.map((fortune: Data) => new Fortune(fortune))
      : [];
    this.telFortunes = data.telFortunes
      ? data.telFortunes.map((telFortune: Data) => new TelFortune(telFortune))
      : [];
    this.sampleChatRoom = data.sampleChatRoom
      ? new SampleChatRoom(data.sampleChatRoom)
      : null;
    this.blockLists = data.blockLists?.map((b) => new BlockList(b)) ?? [];
  }

  get isKoko(): boolean {
    return this.id === 1154;
  }

  get redirectTo(): string {
    const destination = (registrationStatus: RegistrationStatus): string => {
      switch (registrationStatus) {
        case "wait_confirm": {
          return "/sign_in/sent_confirmation";
        }
        case "confirmed": {
          return "/profile/new";
        }
        case "filled_profile": {
          return "/menu/new";
        }
        case "created_first_menu": {
          return "/";
        }
      }
    };
    return destination(this.registrationStatus);
  }

  isWaiting(key: IsWaitingStatusKeys): boolean {
    switch (key) {
      case "waitingFlag":
        return this.waitingFlag;
      case "telWaitingFlag":
        return this.telWaitingFlag;
      case "both":
        return this.waitingFlag && this.telWaitingFlag;
      case "any":
        return this.waitingFlag || this.telWaitingFlag;
      default:
        return false;
    }
  }
}
