import { useErrorMessage } from "shared/hooks/useErrorMessage";
import { useAcquisitionData } from "shared/hooks/useAcquisitionData";
import { repository } from "repositories";

export const useRegisterAcquisitionData = () => {
  const {
    topicId,
    readTopicAt,
    campaignId,
    clickedCampaignAt,
    channelTalkUuid,
  } = useAcquisitionData();

  const { handleAxiosError } = useErrorMessage();

  const registerAcquisitionData = async () => {
    try {
      await repository.acquisitionData
        .postAcquisitionData({
          topicId,
          readTopicAt,
          campaignId,
          clickedCampaignAt,
          uuid: channelTalkUuid,
        })
        .req();
    } catch (error) {
      handleAxiosError(error);
    }
  };

  return {
    registerAcquisitionData,
  };
};
