import nookies, { parseCookies } from "nookies";
import { GetServerSidePropsContext } from "next";
import { keys, setCookie } from "shared/utils/cookie";

const ignoreLocations = [
  "/users/sign_in",
  "/users/sign_in/check_password",
  "/users/sign_in/create_password",
  "/users/sign_in/reset_password",
  "/users/sign_in/sent_confirmation",
  "/callbacks/line",
  "/callbacks/twitter",
  "/callbacks/facebook",
] as string[];

/** getStaticPropsのcontextにはresolvedUrlがなく、リクエストされたURLの保存はできないためgetServerSidePropsのcontextのみを受け付ける */
const setPrevLocationOnServer = (ctx: GetServerSidePropsContext) => {
  const cookies = nookies.get(ctx);

  if (cookies.prev_location === ctx.resolvedUrl) return;
  if (ignoreLocations.some((loc) => ctx.resolvedUrl.match(loc))) return;

  // NOTE: トップページのpathをクエリパラメータ付きで保存するとリダイレクト時に意図しないポップアップが表示される可能性があるのでpathnameを保存する
  const formatLocation = ctx.resolvedUrl.match(/\/\?/) ? "/" : ctx.resolvedUrl;

  setCookie(ctx, keys.prevLocation, formatLocation);
};

const setPrevLocationOnBrowser = (location: string) => {
  const cookies = parseCookies();

  if (cookies.prev_location === location) return;
  if (ignoreLocations.some((loc) => location.match(loc))) return;

  // NOTE: トップページのpathをクエリパラメータ付きで保存するとリダイレクト時に意図しないポップアップが表示される可能性があるのでpathnameを保存する
  const formatLocation = location.match(/\/\?/) ? "/" : location;

  setCookie(null, keys.prevLocation, formatLocation);
};

export const setPrevLocation = (arg: GetServerSidePropsContext | string) => {
  if (typeof window === "undefined") {
    setPrevLocationOnServer(arg as GetServerSidePropsContext);
  } else {
    setPrevLocationOnBrowser(arg as string);
  }
};
