import { repository } from "repositories";
import {
  GetFortuneTellerTelFortuneListParams,
  GetTelFortuneListRes,
  ID,
  SWRResponse,
} from "shared/types";
import useSWR from "swr";

export const useFortuneTellerTelFortuneList = (
  fortuneTellerId: ID,
  params: GetFortuneTellerTelFortuneListParams
): SWRResponse<GetTelFortuneListRes> => {
  const repo = repository.fortuneTellerTelFortune.getList(
    fortuneTellerId,
    params
  );
  const key = fortuneTellerId ? [repo.path, ...Object.values(params)] : null;
  const { data, error, mutate } = useSWR(key, repo.data, {
    fallbackData: repo.fallbackData,
  });
  return {
    ...data,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
