import { Button } from "@miror-ui/components";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";

export const HasSubscriptionsModal = ({
  onDismiss,
  ...rest
}: BaseModalProps) => {
  return (
    <BaseModal
      title="サブスクメニューの決済に使用されているカードは変更できません"
      subTitle="サブスクメニューの毎月の決済にご利用されているクレジットカードは変更できません。該当のご登録クレジットカードを変更したい場合は運営までお問い合わせください。"
      onDismiss={onDismiss}
      {...rest}
    >
      <Button onClick={onDismiss}>OK</Button>
    </BaseModal>
  );
};
