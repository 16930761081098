import { useCallback, useState } from "react";
import { isClient } from "../utils";

export const useClientRect = <T extends Element>(deps?: any[]) => {
  const [rect, setRect] = useState<DOMRect>();

  const ref = useCallback((node: T) => {
    if (node === null) {
      // デフォルトのDOMRect
      const alternativeRect = new DOMRect(0, 0, 0, 0);

      setRect(alternativeRect);
    } else {
      setRect(node.getBoundingClientRect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    ref,
    rect,
    bottom: rect?.bottom || 0,
    height: rect?.height || 0,
    left: rect?.bottom || 0,
    right: rect?.right || 0,
    top: rect?.top || 0,
    width: rect?.width || 0,
    x: rect?.x || 0,
    y: rect?.y || 0,
  };
};

/**
 * - 今の実装だとレイアウト要素のDOMRectをref経由で取得しているが、
 *   getLayoutにpropsとしてposition: fixedなカスタム要素を渡した場合、
 *   ラッパーdivのheightが0になるため、応急処置的に子要素のrectで対応する
 * - 本来はカスタム要素に対して直接refを渡したい
 *   - バニラJS的な書き方ならデフォルト要素とカスタム要素で変わらないidを割り当てて、
 *     getElementById使ってピンポイントに目的要素取得できるのでrefより楽説もある
 *   - ただやっぱDOM全体においてそのidが確実に一意である保証はないはずなので、
 *     運用で一意性を担保するようなレガシー実装であることに変わりはないよねと思う
 */
export const useChildClientRect = <T extends Element>(deps?: any[]) => {
  const [rect, setRect] = useState<DOMRect>();

  const ref = useCallback((node: T) => {
    if (!isClient) {
      return;
    } else if (node && node.childNodes.length > 0) {
      const childElRect = new DOMRect();

      node.childNodes.forEach((n, i) => {
        const childElement = node.children.item(i);
        childElRect.x = childElRect.x + childElement.clientLeft;
        childElRect.y = childElRect.y + childElement.clientTop;
        childElRect.width = childElRect.width + childElement.clientWidth;
        childElRect.height = childElRect.height + childElement.clientHeight;
      });

      setRect(childElRect);
    } else {
      // デフォルトのDOMRect
      const alternativeRect = new DOMRect(0, 0, 0, 0);

      setRect(alternativeRect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    ref,
    rect,
    bottom: rect?.bottom || 0,
    height: rect?.height || 0,
    left: rect?.bottom || 0,
    right: rect?.right || 0,
    top: rect?.top || 0,
    width: rect?.width || 0,
    x: rect?.x || 0,
    y: rect?.y || 0,
  };
};
