import { Tag } from "./index";

export default class Category {
  id: number;
  name: string;
  alias: string;
  imageUrl?: string;
  tags?: Array<Tag>;
  menusAmount?: number;
  fortuneTellersAmount?: number;

  constructor(data?: Partial<Category>) {
    Object.assign(this, data);
  }
}
