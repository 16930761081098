import { ParsedUrlQuery } from "querystring";

import { lineCallbackActions } from "features/callback/hooks/useLineCallbackAction";
import { ID } from "shared/types";

export interface PushState<T = Record<string, any>> {
  pathname: string;
  query?: T;
}

export type LineCallbackQueries = {
  action: typeof lineCallbackActions[keyof typeof lineCallbackActions];
} & ParsedUrlQuery;

export type AffiliateQueries = {
  campaign_id: string;
} & ParsedUrlQuery;

export const Paths = {
  home: () => `/` as const,
  announcements: {
    index: () => `/announcements` as const,
    show: (id: ID) => `/announcements/${id}` as const,
  },
  categories: {
    index: (alias: string) => `/categories/${alias}` as const,
    fortunes: (alias: string) => `/categories/${alias}/fortunes` as const,
    telFortunes: (alias: string) =>
      `/categories/${alias}/tel_fortunes` as const,
  },
  chatRooms: {
    index: () => `/chat_rooms` as const,
    show: (id: ID) => `/chat_rooms/${id}` as const,
    sample: () => `/chat_rooms/sample` as const,
  },
  couponHistories: {
    index: () => `/coupon_histories` as const,
  },
  dailyHoloscopes: {
    index: () => `/daily_holoscope` as const,
    show: (zodiacSign: string) => `/daily_holoscope/${zodiacSign}` as const,
  },
  email: {
    edit: () => `/email/edit` as const,
  },
  favorites: {
    index: () => `/favorites` as const,
  },
  fortuneTellers: {
    index: () => `/fortune_tellers/list` as const,
    show: (id: ID) => `/fortune_tellers/list/${id}` as const,
    list: {
      chatWaiting: (): PushState => ({
        pathname: `/fortune_tellers/list` as const,
        query: {
          waitingStatus: "waiting",
          menuType: "chat",
          shuffle: true,
        },
      }),
      telWaiting: (): PushState => ({
        pathname: `/fortune_tellers/list` as const,
        query: {
          waitingStatus: "waiting",
          menuType: "tel",
          shuffle: true,
        },
      }),
      chatPopular: (): PushState => ({
        pathname: `/fortune_tellers/list` as const,
        query: {
          orderBy: "repeat_rate",
          menuType: "chat",
        },
      }),
      telPopular: (): PushState => ({
        pathname: `/fortune_tellers/list` as const,
        query: {
          orderBy: "repeat_rate",
          menuType: "tel",
        },
      }),
      newer: (): PushState => ({
        pathname: `/fortune_tellers/list` as const,
        query: {
          orderBy: "created_at",
        },
      }),
    },
    reviews: (id: ID) => `/fortune_tellers/${id}/reviews` as const,
    sampleChatRoom: (id: ID) =>
      `/fortune_tellers/${id}/sample_chat_room` as const,
  },
  fortunes: {
    show: (id: ID) => `/fortunes/${id}` as const,
    reviews: (id: ID) => `/fortunes/${id}/reviews` as const,
  },
  order: {
    chat: {
      menu: (id: ID) => `/order/chat/menu?fortune_teller_id=${id}` as const,
      profile: (menuId: ID) => `/order/chat/menu/${menuId}/profile` as const,
      kerte: (menuId: ID) => `/order/chat/menu/${menuId}/karte` as const,
      paymentMethod: (menuId: ID) =>
        `/order/chat/menu/${menuId}/payment_method` as const,
      couponList: (menuId: ID) => `/order/chat/menu/${menuId}/coupons` as const,
      payment: (menuId: ID) => `/order/chat/menu/${menuId}/payment` as const,
      completion: (menuId: ID, orderId: ID): PushState => ({
        pathname: `/order/chat/menu/${menuId}/completion` as const,
        query: {
          order_id: orderId,
        },
      }),
    },
    tel: {
      menu: (id: ID) => `/order/tel/menu?fortune_teller_id=${id}` as const,
      profile: (menuId: ID) => `/order/tel/menu/${menuId}/profile` as const,
      paymentMethod: (menuId: ID) =>
        `/order/tel/menu/${menuId}/payment_method` as const,
      couponList: (menuId: ID) => `/order/tel/menu/${menuId}/coupons` as const,
      payment: (menuId: ID) => `/order/tel/menu/${menuId}/payment` as const,
    },
    subscription: {
      payment: (menuId: ID) =>
        `/order/subscription/menu/${menuId}/payment` as const,
    },
    payment: (orderId: ID) => ({
      index: () => `/order/${orderId}/unpaid/payment` as const,
      transfer: () => `/order/${orderId}/unpaid/payment/transfer` as const,
      credit: () => `/order/${orderId}/unpaid/payment/credit` as const,
    }),
  },
  subscription: {
    index: () => `/subscriptions` as const,
    chat: (subscriptionId: ID) =>
      `/subscriptions/${subscriptionId}/chat` as const,
    completions: (subscriptionId: ID) =>
      `/subscriptions/${subscriptionId}/completions` as const,
    plans: (subscriptionPlanId: ID) => ({
      無料占い:
        `/subscriptions/plans/${subscriptionPlanId}/%E7%84%A1%E6%96%99%E5%8D%A0%E3%81%84` as const,
      無料占い結果:
        `/subscriptions/plans/${subscriptionPlanId}/%E7%84%A1%E6%96%99%E5%8D%A0%E3%81%84%E7%B5%90%E6%9E%9C` as const,
      subscribe: {
        customerInformation:
          `/subscriptions/plans/${subscriptionPlanId}/subscribe/customer_information` as const,
        pay: `/subscriptions/plans/${subscriptionPlanId}/subscribe/pay` as const,
      },
    }),
  },
  pointHistories: {
    index: () => `/point_histories` as const,
  },
  profile: {
    edit: () => `/profile/edit` as const,
  },
  purchaseHistories: {
    index: () => `/purchase_histories` as const,
    show: (id: ID) => `/purchase_histories/${id}` as const,
  },
  tags: {
    index: () => `/tags` as const,
    show: (id: ID) => `/tags/${id}` as const,
    fortunes: (id: ID) => `/tags/${id}/fortunes` as const,
    telFortunes: (id: ID) => `/tags/${id}/tel_fortunes` as const,
  },
  telFortuneChatRooms: {
    show: (id: ID) => `/tel_fortune_chat_rooms/${id}` as const,
  },
  telFortunes: {
    show: (id: ID) => `/tel_fortunes/${id}` as const,
    reviews: (id: ID) => `/tel_fortunes/${id}/reviews` as const,
  },
  password: {
    edit: () => `/password/edit` as const,
    reset: () => `/password/reset` as const,
  },
  users: {
    signIn: {
      index: () => `/users/sign_in` as const,
      checkPassword: () => `/users/sign_in/check_password` as const,
      createPassword: () => `/users/sign_in/create_password` as const,
      resetPassword: () => `/users/sign_in/reset_password` as const,
      sentConfirmation: () => `/users/sign_in/sent_confirmation` as const,
      confirmationMailInfo: () =>
        "https://miror.wraptas.site/77bbb30989d04d34b39e4bcaf0d5630c" as const,
    },
    socialAccounts: {
      line: () => `/users/social_accounts/line` as const,
    },
    wallet: {
      index: () => `/users/wallet` as const,
      addCard: () => `/users/wallet/add_card` as const,
    },
    deleteAccount: {
      index: () => `/users/delete_account` as const,
      form: () => `/users/delete_account/form` as const,
      confirm: () => `/users/delete_account/confirm` as const,
    },
  },
  guideline: () => `/guideline` as const,
  mypage: () => `/mypage` as const,
  notifications: {
    index: () => `/notifications` as const,
    setting: (type: string) => `/notifications/${type}` as const,
  },
  privacyPolicy: () => `/privacy_policy` as const,
  termsOfSale: () => `/terms_of_sale` as const,
  terms: () => `/terms` as const,
  無料占い: () => `/%E7%84%A1%E6%96%99%E5%8D%A0%E3%81%84` as const,
  無料占い結果: () =>
    `/%E7%84%A1%E6%96%99%E5%8D%A0%E3%81%84%E7%B5%90%E6%9E%9C` as const,
  press: {
    list: () => `/press` as const,
    column: (id: ID) => `/press/column/${id}` as const,
  },
  miracle: {
    list: () => `/miracle` as const,
    column: (id: ID) => `/miracle/column/${id}` as const,
  },
  callbacks: {
    line: {
      default: (): PushState<LineCallbackQueries> => ({
        pathname: `/callbacks/line` as const,
      }),
      from無料占い: (): PushState<LineCallbackQueries> => ({
        pathname: `/callbacks/line` as const,
        query: {
          action: lineCallbackActions.friendshipPrompt,
        },
      }),
      fromTelFortuneAffiliate: (): PushState<LineCallbackQueries> => ({
        pathname: `/callbacks/line` as const,
        query: {
          action: lineCallbackActions.receiveCoupon,
        },
      }),
      fromLP2: (): PushState<LineCallbackQueries> => ({
        pathname: `/callbacks/line` as const,
        query: {
          action: lineCallbackActions.receiveCoupon,
        },
      }),
      fromTelFortuneLP: (): PushState<LineCallbackQueries> => ({
        pathname: `/callbacks/line` as const,
        query: {
          action: lineCallbackActions.redirectFortuneTellerList,
        },
      }),
    },
    twitter: {
      default: (): PushState => ({
        pathname: `/callbacks/twitter` as const,
      }),
    },
    facebook: {
      default: (): PushState => ({
        pathname: `/callbacks/facebook` as const,
      }),
    },
  },
  af: {
    show: (id: ID): PushState<AffiliateQueries> => ({
      pathname: `/af/${id}` as const,
    }),
  },
  phoneNumber: {
    edit: () => `/phone_number/edit` as const,
  },
  freeFortuneContents: {
    index: () => `/free_fortune` as const,
    show: (alias: string, id: ID) => `/free_fortune/${alias}/${id}` as const,
  } as const,
  now: {
    callHistory: () => `/now?tab=0` as const,
    telWaitingFortuneTellerList: () => `/now?tab=1` as const,
    favorites: () => `/now?tab=2` as const,
  },
};
