export const ACCOUNT_ID = 60542;

/**
 * see: https://guides.criteotilt.com/onetag/onetag_ja/
 */
export const siteType = {
  mobile: "m",
  tablet: "t",
  /**
   * NOTE: default
   */
  desktop: "d",
};

export const HASH_METHOD = "sha256";
