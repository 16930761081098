import QueryString from "qs";
import HttpClient from "shared/repositories/HttpClient";
import { GetFortuneTellerReviewListRes, ID, Pagination } from "shared/types";

export class FortuneTellerReviewRepository {
  private getListPath(fortuneTellerId: ID, params?: Pagination) {
    const query = QueryString.stringify(params);
    return `/fortune_tellers/${fortuneTellerId}/reviews?${query}`;
  }

  public getList(fortuneTellerId: ID, params?: Pagination) {
    const path = this.getListPath(fortuneTellerId, params);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFortuneTellerReviewListRes>(path)
          .then((res) => res.data),
    };
  }
}
