import { ChatRoom } from "shared/models/index";
import clients from "shared/repositories/HttpClient";

const ChatRoomsRepository = {
  show: async (chatRoomId: string | string[] | number): Promise<ChatRoom> => {
    const res = await clients.miror.get(`chat_rooms/${chatRoomId}`);
    return new ChatRoom(res.data?.chatRoom);
  },
};

export default ChatRoomsRepository;
