import { AdminOrder, Menu, User } from "../../models";
import { FortuneTeller } from "./FortuneTellerResponse";
import { Order } from "./OrderResponse";

export const chatRoomStatus = {
  unpaid: "unpaid",
  inactive: "inactive",
  activated: "activated",
  closed: "closed",
  standardCanceled: "standard_canceled",
  firstTimeCanceled: "first_time_canceled",
  exceptionalCanceled: "exceptional_canceled",
  automaticallyCanceled: "automatically_canceled",
} as const;

export interface ChatRoomSchema {
  id: number;
  fortuneApplicationId: number;
  userId: number;
  fortuneTellerId: number;
  status: typeof chatRoomStatus[keyof typeof chatRoomStatus];
  sentResult: boolean;
  resultSentAt: string;
  createdAt: string;
  openedAt: string;
  closedAt: string;
  updatedAt: string;
}

export interface ChatRoom extends Pick<ChatRoomSchema, "id" | "createdAt"> {}

export interface GetChatRoomResponse {
  chatRoom: ChatRoom &
    Pick<ChatRoomSchema, "sentResult" | "status"> & {
      fortuneTeller: FortuneTeller;
      user: User;
      order: Order;
      menu: Menu;
      canCancelRequest: boolean;
      reviewed: boolean;
      adminOrder?: AdminOrder;
    };
}
