import { FC } from "react";
import { externalLink } from "../../utils/externalLink";

interface Props {
  name: keyof typeof externalLink;
  className?: string;
}

export const ExternalLink: FC<Props> = ({ name, className, children }) => {
  return (
    <a
      href={externalLink[name]}
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      {children}
    </a>
  );
};
