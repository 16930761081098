import { repository } from "repositories";
import { GetFreeFortuneCategoryRes, SWRResponse } from "shared/types";
import useSWR from "swr";

export const useFreeFortuneCategory = (
  id: number
): SWRResponse<GetFreeFortuneCategoryRes> => {
  const repo = repository.freeFortuneCategory.get(id);
  const { data, error, mutate } = useSWR(
    repo.path ? [repo.path, id] : undefined,
    repo.data
  );

  return {
    freeFortuneCategory: data?.freeFortuneCategory,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
