import { LocaleObject } from "yup/lib/locale";
import { MessageParams } from "yup/lib/types";

export const requiredMessage = ({ label }: MessageParams): string =>
  `${label}を入力してください`;

export const typeErrorMessage = ({ type }: MessageParams): string =>
  `${type}を入力してください`;

export const ja: LocaleObject = {
  mixed: {
    required: requiredMessage,
  },
};
