import { forwardRef } from "react";
import { css } from "styled-components";

interface Props {
  width?: string | number;
  height?: string | number;
  className?: string;
}

export const Skeleton = forwardRef<SVGRectElement, Props>(
  ({ width, height, className }, ref) => {
    return (
      <svg
        tw="bg-gray reflective after:reflection"
        css={css({
          backgroundImage:
            "repeating-linear-gradient(120deg, rgba(255, 255, 255, .2), rgba(255, 255, 255, .2) 24px, transparent 0, transparent 48px);",
          width,
          height,
        })}
        className={className}
      >
        <defs>
          <rect ref={ref} tw="w-full h-full" x="0" y="0" rx="0" ry="0" />
        </defs>
      </svg>
    );
  }
);

Skeleton.displayName = "Skeleton";
