import { FC, ReactNode } from "react";
import { css } from "styled-components";

import { IconButton } from "./IconButton";
import { CloseIcon } from "../../assets/icons/CloseIcon";

interface DissmissableProps {
  onDissmiss: () => void;
  icon?: ReactNode;
  className?: string;
}

export const Dissmissable: FC<DissmissableProps> = ({
  onDissmiss,
  icon,
  className,
  children,
}) => {
  return (
    <div className={className}>
      <IconButton
        icon={
          icon ?? (
            <div tw="bg-black bg-opacity-80 rounded-full w-6 h-6 flex-center">
              <CloseIcon
                css={css({ stroke: "#fff", transform: `scale(.6)` })}
              />
            </div>
          )
        }
        onClick={onDissmiss}
        tw="mb-2 mr-1 ml-auto"
      />
      {children}
    </div>
  );
};
