import { Fortune, TelFortune, FortuneTeller } from ".";

interface Links {
  detailPage: string;
  orderPage: string;
  reviewPage: string;
}

export default class Menu {
  id: number;
  title: string;
  fortuneTeller?: FortuneTeller;
  isPublished?: boolean;

  constructor(data?: Partial<Menu>) {
    Object.assign(this, data);
  }

  static isChat(instance: Fortune | TelFortune | Menu): instance is Fortune {
    return instance instanceof Fortune;
  }

  static isTel(instance: Fortune | TelFortune | Menu): instance is TelFortune {
    return instance instanceof TelFortune;
  }

  get links(): Links {
    return {
      detailPage: Menu.isChat(this)
        ? `/fortunes/${this.id}`
        : `/tel_fortunes/${this.id}`,
      orderPage: Menu.isChat(this)
        ? `/order/chat/menu/${this.id}/profile`
        : `/order/tel/menu/${this.id}/profile`,
      reviewPage: Menu.isChat(this)
        ? `/fortunes/${this.id}/reviews`
        : `/tel_fortunes/${this.id}/reviews`,
    };
  }

  get canOrder(): boolean {
    const { chatAcceptFlag, telAcceptFlag, isBusy } = this.fortuneTeller;

    if (!this.isPublished) return false;

    if (Menu.isChat(this)) {
      return chatAcceptFlag && !isBusy;
    }
    if (Menu.isTel(this)) {
      return telAcceptFlag;
    }
    return undefined;
  }

  get originPrice(): number {
    if (Menu.isChat(this)) {
      return this.price;
    } else if (Menu.isTel(this)) {
      return this.costPerMinute;
    } else {
      return undefined;
    }
  }
}
