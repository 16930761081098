import { repository } from "repositories";
import HttpClient from "shared/repositories/HttpClient";
import {
  WithPaginationMetaResponse,
  GetFortuneReviewListRes,
  ID,
  Pagination,
} from "shared/types";
import useSWRInfinite from "swr/infinite";

interface Params extends Pagination {
  fortuneId: ID;
}

export const useFortuneReviewList = ({ fortuneId }: Params) => {
  const getKey = (
    pageIndex: number,
    previousPageData: WithPaginationMetaResponse<GetFortuneReviewListRes>
  ) => {
    if (previousPageData && !previousPageData.fortuneReviews.length)
      return null;

    const repo = repository.fortuneReview.getList(fortuneId, pageIndex);

    return repo.path;
  };
  return useSWRInfinite(getKey, (path: string) =>
    HttpClient.miror.get<GetFortuneReviewListRes>(path).then((res) => res.data)
  );
};
