import HttpClient from "shared/repositories/HttpClient";
import {
  GetCategoryFortuneListParams,
  GetTelFortuneListRes,
} from "shared/types";
import { SWRConfiguration } from "swr";

export class CategoryTelFortuneRepository {
  private getListPath(alias: string) {
    return alias ? `/categories/${alias}/tel_fortunes` : null;
  }

  public getList(alias: string, params: GetCategoryFortuneListParams) {
    const path = this.getListPath(alias);
    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTelFortuneListRes>(path, { params })
          .then((res) => res.data),
      fallbackData: {
        telFortunes: [],
        meta: { totalPages: 1 },
      } as SWRConfiguration["fallbackData"],
    };
  }
}
