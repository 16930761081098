import { MessageParams } from "yup/lib/types";

const validation = (str: string): boolean => {
  str = str == null ? "" : str;
  // eslint-disable-next-line no-irregular-whitespace
  return !!str.match(/^[ァ-ヶー　]+$/);
};

const error = ({ label }: MessageParams): string =>
  `${label}はカタカナで入力してください`;

export const kanaValidator = {
  name: "kana",
  validation: function () {
    return this.test(null, error, validation);
  },
};
