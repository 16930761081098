import * as Sentry from "@sentry/react";
import { useRouter } from "next/router";
import { signOut } from "next-auth/client";
import { ParsedUrlQueryInput } from "querystring";
import useLocalStorage from "./useLocalStorage";

type UseLogout = (query?: string | ParsedUrlQueryInput) => void;

const useLogout = (): UseLogout => {
  const { resetStorage } = useLocalStorage();
  const { push } = useRouter();

  return async (query?: string | ParsedUrlQueryInput) => {
    Sentry.setUser(null);
    await signOut({ redirect: false });
    await push({
      pathname: "/",
      query,
    });
    resetStorage();
  };
};

export default useLogout;
