import HttpClient from "shared/repositories/HttpClient";
import { GetPurchaseHistoryListRes } from "shared/types";

export class PurchaseHistoryRepository {
  private getListPath() {
    return `/me/purchase_histories`;
  }

  public getList() {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetPurchaseHistoryListRes>(path)
          .then((res) => res.data),
      fallbackData: [
        {
          purchaseHistories: [],
          meta: { totalPages: 1 },
        } as GetPurchaseHistoryListRes,
      ],
    };
  }
}
