/**
 *
 * TODO:
 *  - よく使う色定義はtailwind.config.jsに移行したい
 *  - サービスカラーのpinkとグラデーションだけ移行済み
 *
 */
export const Colors = {
  black: {
    /** black */
    "01": "#3C424A",
  },
  gray: {
    /** secondary */
    "01": "#838D9B",
    "02": "#F2F7FE",
    "03": "#DFE7F2",
    "04": "#E9E9EA",
    "05": "#E7EAEC",
  },
  pink: {
    /** theme */
    "01": "#E271B6",
    /** blossom */
    "02": "#FFF1FB",
    "03": "#E68AB4",
  },
  blue: {
    /** gray */
    "01": "#F3F7FD",
    "02": "#00A2F9",
    "03": "#0077FB",
    "04": "#67C4E3",
    "05": "#ECF5F8",
  },
  gradient: {
    "01": "linear-gradient(-44deg, #B647B7, #F683B6)",
    "02": "linear-gradient(-44deg, #E3A1FF, #EA88D0)",
    "03": "linear-gradient(-44deg, #80C3F3, #4A90E2)",
    "04": "linear-gradient(45deg, #3F1F8E, #AD52BF)",
  },
  purple: {
    "01": "#AB52B2",
  },
  green: {
    "01": "#05C744",
    "02": "#34C759",
  },
} as const;
