/**
 * NOTE:
 *  - tailwindを参考にしてる
 *    - see: https://tailwindcss.com/docs/z-index
 *  - tailwindをまんま使ってもいいけど、コメントでUsageを書きたいので独自定義にしている
 */
export const zIndex = {
  /**
   * NOTE:
   *  - 要素Bを要素Aの下に表示したいときなど
   */
  negative: -10,
  /**
   * NOTE:
   *  - 要素Bを要素Aの上に表示したいときなど
   */
  priority: 10,
  /**
   * NOTE:
   *  - ヘッダーやナビゲーションなど通常の要素よりも前に出す
   */
  fixed: 20,
  /**
   * NOTE:
   *  - モーダルなどfixedな要素よりも前に出す
   */
  floating: 30,
  /**
   * NOTE:
   *  - エラーメッセージなどfloatingよりも更に優先したい要素に対して
   */
  force: 40,
  /**
   * NOTE:
   *  - 待っていて欲しいときに表示するローディングは最優先
   */
  loading: 50,
} as const;
