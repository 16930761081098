import { useSession } from "next-auth/client";
import QueryString from "qs";
import { Favorites } from "repositories";
import {
  WithPaginationMetaResponse,
  GetFavoritesParams,
  GetFavoriteListResponse,
} from "shared/types";
import { mapKeysSnakeCase } from "shared/utils";
import useSWRInfinite from "swr/infinite";

export const useFavorites = ({ limit = 30, ...params }: GetFavoritesParams) => {
  const [session] = useSession();

  const query = QueryString.stringify(mapKeysSnakeCase(params));

  const getKey = (
    pageIndex: number,
    previousPageData: WithPaginationMetaResponse<GetFavoriteListResponse>
  ) => {
    // fetchした結果、データが存在せず空配列が返ってきたとき
    const isEmpty = previousPageData && !previousPageData.favorites.length;

    if (!session || isEmpty) return null;

    return `/favorites?page=${pageIndex + 1}&limit=${limit}&${query}`;
  };

  return useSWRInfinite(getKey, (url: string) => Favorites.index(url), {
    fallbackData: [],
  });
};
