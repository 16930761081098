import HttpClient from "shared/repositories/HttpClient";
import { GetFortuneTellerTelFortuneListParams, GetTelFortuneListRes, ID } from "shared/types";
import { SWRConfiguration } from "swr";

export class FortuneTellerTelFortuneRepository {
  private getListPath(fortuneTellerId: ID) {
    return fortuneTellerId
      ? `/fortune_tellers/${fortuneTellerId}/tel_fortunes`
      : null;
  }

  public getList(fortuneTellerId: ID, params: GetFortuneTellerTelFortuneListParams) {
    const path = this.getListPath(fortuneTellerId);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetTelFortuneListRes>(path, { params })
          .then((res) => res.data),
      fallbackData: { telFortunes: [] } as SWRConfiguration["fallbackData"],
    };
  }
}
