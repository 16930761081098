import { repository } from "repositories";
import { MediaType, OrderType } from "repositories/TopicRepository";
import { Topic } from "shared/types";
import { SWRResponse } from "shared/types";
import useSWR from "swr";

export const useTopics = (
  mediaType: MediaType,
  params?: {
    limit?: number;
    order?: OrderType;
    meta?: boolean;
  }
): SWRResponse<{ topics: Topic[] }> => {
  const repo = repository.topic.getList(mediaType, params);

  const { data, error, mutate } = useSWR(
    [repo.path, mediaType, ...Object.values(params)],
    repo.data,
    { fallbackData: { topics: [], meta: { total: 0 } } }
  );

  return {
    topics: data?.topics,
    isLoading: !error && !data,
    isError: !!error,
    mutate,
  };
};
