import { SampleChatRoom } from "shared/models/index";
import clients from "shared/repositories/HttpClient";

const SampleChatRoomsRepository = {
  show: async (
    fortuneTellerId: string | string[] | number
  ): Promise<SampleChatRoom> => {
    const res = await clients.miror.get(
      `/fortune_tellers/${fortuneTellerId}/sample_chat_room`
    );
    return new SampleChatRoom(res.data.chatRoom);
  },
};

export default SampleChatRoomsRepository;
