import { MessageParams } from "yup/lib/types";

const validation = (str: string) => {
  str = str == null ? "" : str;
  return !str?.match(/(?:http|https):\/\/(?!miror\.)+[\w-]+\..[\w-]+/);
};

const error = ({ label }: MessageParams): string =>
  `${label}に外部のURLを含めることはできません`;

export const extUrlValidator = {
  name: "preventExternalUrl",
  validation: function () {
    return this.test(null, error, validation);
  },
};
