import HttpClient from "shared/repositories/HttpClient";
import { GetCategoryFortuneListParams, GetFortuneListRes } from "shared/types";
import { SWRConfiguration } from "swr";

export class CategoryFortuneRepository {
  private getListPath(alias: string) {
    return alias ? `/categories/${alias}/fortunes` : null;
  }

  public getList(alias: string, params: GetCategoryFortuneListParams) {
    const path = this.getListPath(alias);
    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFortuneListRes>(path, { params })
          .then((res) => res.data),
      fallbackData: {
        fortunes: [],
        meta: { totalPages: 1 },
      } as SWRConfiguration["fallbackData"],
    };
  }
}
