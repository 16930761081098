import { useModal } from "mui-modal-provider";
import { useRef, useMemo, useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import { useCookies } from "react-cookie";
import {
  REQUIRED_DURATION_TO_GET_STAMP,
  StampCardModal,
  StampCardModalProps,
} from "components/organisms/modal/StampCardModal";
import { ShowFnOutput } from "mui-modal-provider/dist/types";
import { useCallLogList } from "./useCallLogList";
import { BaseModalProps } from "shared/components/organisms/modal/BaseModal";
import dayjs from "dayjs";

const today = dayjs();

export const useStampCardModal = () => {
  const router = useRouter();
  const { callLogs } = useCallLogList(
    {
      minimumCallDuration: REQUIRED_DURATION_TO_GET_STAMP,
      from: today.startOf("month").format("YYYY-MM-DD"),
      until: today.endOf("month").format("YYYY-MM-DD"),
    },
    {
      refreshInterval:
        router.pathname === "/tel_fortune_chat_rooms/[id]" ? 3000 : 0,
    }
  );

  const callDurationProgress = useMemo(
    () => callLogs.reduce((a, b) => a + b.callDuration, 0),
    [callLogs]
  );

  const { showModal } = useModal();
  const modal = useRef<ShowFnOutput<any>>();
  const [cookies, setCookie] = useCookies();

  const canShowModal = useMemo(
    () => !modal.current && callDurationProgress > 0,
    [modal, callDurationProgress]
  );

  const showStampCardModal = useCallback(
    (props: BaseModalProps) => {
      if (!canShowModal) return;

      modal.current = showModal<StampCardModalProps>(StampCardModal, {
        ...props,
        callDurationProgress,
        onDismiss: () => {
          modal.current.destroy();
          modal.current = null;
        },
      });
    },
    [canShowModal, showModal, callDurationProgress]
  );

  useEffect(() => {
    if (router.pathname === "/tel_fortune_chat_rooms/[id]") {
      modal.current = null;
    }
  }, [router.pathname]);

  useEffect(() => {
    if (router.pathname === "/tel_fortune_chat_rooms/[id]") {
      const telFortuneChatRoom = callLogs.find(
        (callLog) => callLog.telFortuneChatRoom.id === Number(router.query.id)
      );
      if (canShowModal && telFortuneChatRoom) {
        showStampCardModal({
          title: "スタンプが押されました🎊",
          subTitle:
            "毎月の電話鑑定の通話分数に応じてポイントがもらえる電話鑑定スタンプカード機能でスタンプが押されました💮",
        });
      }
    } else if (canShowModal && !cookies["show-stamp-card-modal"]) {
      showStampCardModal({
        title: "スタンプが貯まっています🎁",
        subTitle:
          "電話占いスタンプカードにスタンプが貯まっています！さらに電話占いを受けてポイントをゲットしましょう🔮",
      });
      setCookie("show-stamp-card-modal", "true", {
        maxAge:
          process.env.NEXT_PUBLIC_APP_ENV === "production" ? 60 * 60 * 24 : 10,
      });
    }
  }, [
    router.pathname,
    callLogs,
    router.query.id,
    canShowModal,
    showStampCardModal,
    callDurationProgress,
    cookies,
    setCookie,
  ]);

  return {
    showModal: showStampCardModal,
  };
};
