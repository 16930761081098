import { DeleteCardModal } from "components/organisms/modal/DeleteCardModal";
import { useModal } from "mui-modal-provider";
import { useSession } from "next-auth/client";
import { useState } from "react";
import PayjpCardInformationRepository from "repositories/payjp_card_information";
import { useErrorMessage } from "shared/hooks/useErrorMessage";
import { GetPayjpCardInfoRes, SWRResponse } from "shared/types";
import useSWR from "swr";

type UsePayjpCardReturn = SWRResponse<GetPayjpCardInfoRes> & {
  onChangeCard: () => void;
  processing: boolean;
};

export const usePayjpCard = (): UsePayjpCardReturn => {
  const [processing, setProcessing] = useState(false);

  const [session] = useSession();

  const { data, error, mutate, isValidating } = useSWR(
    session ? "/me/payjp_card_information" : null,
    PayjpCardInformationRepository.get
  );

  const { showModal } = useModal();
  const { handleAxiosError } = useErrorMessage();

  const onChangeCard = () => {
    const modal = showModal(DeleteCardModal, {
      onSubmit: async () => {
        setProcessing(true);
        try {
          await PayjpCardInformationRepository.delete();
          await mutate();
        } catch (error) {
          handleAxiosError(error);
        } finally {
          setProcessing(false);
          modal.destroy();
        }
      },
    });
  };

  return {
    payjpCardInformation: data?.payjpCardInformation,
    isLoading: (!error && !data) || isValidating,
    isError: error,
    mutate,
    onChangeCard,
    processing,
  };
};
