import { useRouter } from "next/router";
import Script from "next/script";
import { useMe } from "repositories/hooks";
import { ACCOUNT_ID } from "shared/lib/criteo/constants";
import { GA_MEASUREMENT_ID } from "shared/lib/google";

/**
 * https://nextjs.org/docs/api-reference/next/script
 */
export const NextScriptTag = () => {
  const { isLoading, me } = useMe();

  const { asPath } = useRouter();

  const isMedia = /(press|miracle)\/column/.test(asPath);

  const onLoadPayjp = () => {
    if (typeof window !== "undefined" && window.Payjp) {
      window.payjp = window.Payjp(process.env.NEXT_PUBLIC_PAYJP_PUBLIC_KEY);
    }
  };

  return (
    <>
      {/** Google tag (gtag.js) */}
      {!isLoading && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
            async
          />
          <Script
            id="NextScript/GoogleAnalytics"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_MEASUREMENT_ID}', {
              'debug_mode': ${process.env.NEXT_PUBLIC_APP_ENV !== "production"},
              'user_id': '${me ? me.id : null}'
            });`,
            }}
          />
        </>
      )}
      {/**
       * [Criteo]
       *  - https://guides.criteotilt.com/onetag/onetag_ja/
       *  - 本番環境以外でトラッキング情報を送信したくないので初期化しない
       */}
      {process.env.NEXT_PUBLIC_APP_ENV === "production" && (
        <Script
          strategy="afterInteractive"
          type="text/javascript"
          src={`//dynamic.criteo.com/js/ld/ld.js?a=${ACCOUNT_ID}`}
          async
        />
      )}
      {/** [Payjp] */}
      {!isMedia && (
        <Script src="https://js.pay.jp/v2/pay.js" onLoad={onLoadPayjp} />
      )}
    </>
  );
};
