import { atom } from "recoil";
import {
  Tag,
  Category,
  Fortune,
  TelFortune,
  FortuneTeller,
} from "shared/models";
import { Karte } from "shared/types";

export const keys = {
  appState: "appState",
  kartes: "userKartes",
  divinationTagsState: "divinationTagsState",
  categoriesState: "categoriesState",
  unreadMessages: "unreadMessages",
  currentMenuState: "currentMenuState",
  noticeSubscribedState: "noticeSubscribedState",
  currentFortuneTeller: "currentFortuneTeller",
} as const;

interface AppState {
  kartes: Karte[];
  divinationTags: Tag[];
  categories: Category[];
  unreadMessages: { count: number };
  currentMenu: Fortune | TelFortune;
  noticeSubscribed: boolean;
  currentFortuneTeller: FortuneTeller;
}

const defaultState: AppState = {
  kartes: [],
  divinationTags: null,
  categories: null,
  unreadMessages: null,
  currentMenu: null,
  noticeSubscribed: false,
  currentFortuneTeller: null,
};

/**
 * @returns
 *  - kartes [Karte[]] カルテ情報
 *  - divinationTags [Tag[]] 占術タグ
 *  - categories [Category[]] カテゴリー
 *  - unreadMessages [{count: number}] 未読メッセージ数
 *  - currentMenu [Fortune | TelFortune] 開いているメニューページのメニュー情報
 *  - noticeSubscribed [boolean] currentMenuの鑑定再開通知を設定済みかどうか
 *  - currentFortuneTeller [FortuneTeller] 開いている占い師ページの占い師情報
 */
export const appState = atom({
  key: keys.appState,
  default: defaultState,
});

export default appState;
