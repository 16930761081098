import dynamic from "next/dynamic";
import { StaticImageData } from "next/image";
import { css } from "styled-components";

import { defaultImages } from "../../assets/images/defaultImages";
import { ImageProps } from "./Image";

const BlurImage = dynamic(async () => (await import("./BlurImage")).BlurImage);
const Image = dynamic(async () => (await import("./Image")).Image);

interface Props extends ImageProps {
  src: string | StaticImageData;
  size?: number;
  blur?: number;
  className?: string;
}

/**
 * @param src [string]
 * @param size [number] default: 32
 * @param blur [number] default: 0
 * @param className [string]
 */
export const Avatar = ({
  src,
  size = 32,
  blur = 0,
  className,
  ...nextImageProps
}: Props) => {
  return (
    <>
      {blur > 0 ? (
        <BlurImage
          src={src}
          width={size}
          height={size}
          blur={blur}
          className={className}
        />
      ) : (
        <Image
          tw="rounded-full"
          css={css({ maxWidth: size, maxHeight: size })}
          className={className}
          src={src}
          width={size}
          height={size}
          layout="fixed"
          objectFit="cover"
          fallback={defaultImages.user}
          {...nextImageProps}
        />
      )}
    </>
  );
};
