import HttpClient from "shared/repositories/HttpClient";
import {
  GetFortuneListRes,
  ID,
  GetCategoryFortuneListParams,
} from "shared/types";

export class TagFortuneRepositoryV2 {
  private getListPath(tagId: ID) {
    return tagId ? `/tags/${tagId}/fortunes` : null;
  }

  public getList(tagId: ID, params: GetCategoryFortuneListParams) {
    const path = this.getListPath(tagId);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFortuneListRes>(path, { params })
          .then((res) => res.data),
    };
  }
}
