import { yupResolver } from "@hookform/resolvers/yup";
import FormData from "form-data";
import { useForm, UseFormReturn } from "react-hook-form";
import * as yup from "yup";

import { MessageType } from "../../../models/message";

import useChatRoomId from "../../../hooks/useChatRoomId";
import useLocalStorage from "../../../hooks/useLocalStorage";

export class MessageFormData extends FormData {
  constructor({ body, messageType, file }: MessageParams) {
    super();
    body && this.append("message[body]", body);
    this.append("message[message_type]", messageType);
    file && file.length !== 0 && this.append("message[image]", file.item(0));
  }
}

export interface MessageParams {
  body: string;
  file: FileList;
  messageType: typeof MessageType[keyof typeof MessageType];
}

export const fields = {
  body: { name: "body", label: "メッセージ" },
  file: { name: "file", label: "ファイル" },
  messageType: { name: "messageType", label: "" },
} as const;

const schema = yup.object().shape({
  body: yup.string().label(fields.body.label),
  // TODO: PDF許可する
  // file: yup.mixed().label(fields.file.label).img(),
  messageType: yup.string().oneOf(Object.values(MessageType)).required(),
});

const resolver = yupResolver(schema);

export const useDefaultValues = (args?: {
  isUseCacheValue?: boolean;
}): MessageParams => {
  const { getSerializedItem, keys, makeCustomKey } = useLocalStorage();
  const chatRoomId = useChatRoomId();
  const key = makeCustomKey(keys.chatRoomInput, chatRoomId?.toString());
  return {
    body: !args?.isUseCacheValue ? "" : getSerializedItem(key),
    file: null,
    messageType: MessageType.plain,
  };
};

interface UseMessageFormReturn {
  form: UseFormReturn<MessageParams>;
  fields: typeof fields;
  disabled: boolean;
}

export const useMessageForm = (): UseMessageFormReturn => {
  const defaultValues = useDefaultValues();
  const form = useForm<MessageParams>({
    mode: "onChange",
    resolver,
    defaultValues,
  });
  const { isValid, isSubmitting } = form.formState;
  const disabled = !isValid || isSubmitting;
  return {
    form,
    fields,
    disabled,
  };
};
