import { useSession } from "next-auth/client";
import { Users } from "repositories/index";
import { User } from "shared/models/index";
import { SWROptions, SWRResponse, TODO_Transfer } from "shared/types";
import useSWR from "swr";

export const useMe = (options?: SWROptions): SWRResponse<{ me: User }> => {
  const [session, loading] = useSession();

  const key = session && !options?.skip ? "/me" : null;
  const { data, error, mutate } = useSWR(key, Users.show);

  return {
    me: data,
    isLoading: loading || (!!session && !data && !error),
    isError: error,
    mutate: mutate as TODO_Transfer,
  };
};
