import { FreeFortuneTheme } from "shared/models";
import { ResultType } from "shared/models/free_fortune_chat_question";
import clients from "shared/repositories/HttpClient";
import { Data } from "shared/types";

const FreeFortuneThemesRepository = {
  index: async (
    url: string,
    resultType: ResultType
  ): Promise<FreeFortuneTheme[]> => {
    const res = await clients.miror.get(url, { params: { resultType } });
    return (
      res.data?.freeFortuneThemes?.map(
        (theme: Data) => new FreeFortuneTheme(theme)
      ) ?? []
    );
  },
};

export default FreeFortuneThemesRepository;
