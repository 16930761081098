const LINER_GRADIENT_ID = "HOME";

interface Props {
  className?: string;
}

export const HomeIcon = ({ className }: Props) => {
  return (
    <svg
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <linearGradient
        id={LINER_GRADIENT_ID}
        x1="91.453687%"
        x2="11.278681%"
        y1="100%"
        y2="2.154082%"
      >
        <stop offset="0" stopColor="#B647B7" />
        <stop offset="1" stopColor="#F683B6" />
      </linearGradient>
      <path
        d="m14.0770667 3.37897333c-1.1606667-1.11381333-2.9934667-1.11381333-4.15413337 0l-6.16861333 5.91912c-.15113333.14497334-.2528.33390667-.29058667.53990667-.73884 4.0292-.79337333 8.154-.16132 12.2013333l.15002667.9606667h3.96861333v-8.2817333c0-.5524.44772-1 1-1h7.15787997c.5522667 0 1 .4476 1 1v8.2817333h3.9686667l.15-.9606667c.632-4.0473333.5774667-8.1721333-.1613333-12.2013333-.0377334-.206-.1394667-.39493333-.2905334-.53990667zm-5.53889337-1.44309333c1.93462667-1.85634667 4.98902667-1.85634667 6.92369337 0l6.1685333 5.91912c.4541333.43572.7596 1.00330667.8730667 1.62233333.7793333 4.25013337.8369333 8.60133337.1701333 12.87053337l-.2409333 1.5433333c-.0997334.6382667-.6494667 1.1088-1.2954667 1.1088h-5.5582667c-.5522666 0-1-.4477333-1-1v-8.2817333h-5.15786663v8.2817333c0 .5522667-.44773334 1-1.00001334 1h-5.55830666c-.64597334 0-1.19569334-.4705333-1.29536-1.1088l-.24101334-1.5433333c-.66673333-4.2692-.6092-8.6204.17017334-12.87053337.11350666-.61902666.41894666-1.18661333.87304-1.62233333z"
        fill={`url(#${LINER_GRADIENT_ID})`}
        fillRule="evenodd"
      />
    </svg>
  );
};
