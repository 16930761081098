import { useSession } from "next-auth/client";
import HttpClient from "shared/repositories/HttpClient";
import useSWR, { SWRConfiguration } from "swr";
import { useFeatureFlags } from "features/shared/hooks/useFeatureFlags";
import { CallLog } from "shared/types";

interface CallLogsParams {
  from?: string;
  until?: string;
  minimumCallDuration?: number;
  maximumCallDuration?: number;
}

const fetcher = (params: CallLogsParams) => async (url: string) => {
  return HttpClient.miror.get(url, { params }).then((res) => res.data);
};

export const useCallLogList = (
  params?: CallLogsParams,
  options?: SWRConfiguration
) => {
  const { isStampRallyEnabled } = useFeatureFlags();
  const [session] = useSession();

  const { data, error } = useSWR<{ callLogs: CallLog[] }>(
    isStampRallyEnabled && session ? "/me/call_logs" : null,
    fetcher(params),
    {
      fallbackData: { callLogs: [] },
      ...options,
    }
  );

  return {
    callLogs: data.callLogs,
    isLoading: !data && !error,
    isError: !!error,
  };
};
