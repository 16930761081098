import { DailyHoloscope } from "shared/models/index";
import clients from "shared/repositories/HttpClient";

const DailyHoloscopesRepository = {
  show: async (url: string): Promise<DailyHoloscope> => {
    const res = await clients.miror.get(url);
    return new DailyHoloscope(res.data.dailyHoloscope);
  },
};

export default DailyHoloscopesRepository;
