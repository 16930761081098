import { FreeFortuneChatReply } from "shared/models";
import clients from "shared/repositories/HttpClient";
import { Data } from "shared/types";

const FreeFortuneChatRepliesRepository = {
  index: async (url?: string): Promise<FreeFortuneChatReply[]> => {
    if (!url) return;
    const res = await clients.miror.get(url);
    return (
      res.data?.map((reply: Data) => new FreeFortuneChatReply(reply)) ?? []
    );
  },
};

export default FreeFortuneChatRepliesRepository;
