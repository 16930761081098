import * as Repositories from "repositories";
import { TotalPoint } from "shared/models/index";
import { SWRResponse, TODO_Transfer } from "shared/types";
import useSWR from "swr";

export const useTotalPoint = (): SWRResponse<{ totalPoint: TotalPoint }> => {
  const key = `/me/total_point`;
  const { data, error, mutate } = useSWR(key, Repositories.TotalPoint.show);

  return {
    totalPoint: data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate as TODO_Transfer,
  };
};
