import { Card } from "shared/components/atoms/card";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";
import tw from "twin.macro";
import HighLightStarIcon from "shared/assets/icons/HighLightStarIcon.svg";
import { findMultiples } from "shared/utils/findMultiples";
import { StampCardFrame } from "../StampCard/StampCardFrame";

const isProd = process.env.NEXT_PUBLIC_APP_ENV === "production";

const HighLight = tw.span`text-[15px] tracking-[.75px] leading-6 text-[#FE4FD0]`;

/**
 * NOTE: dev, staging環境では動作確認がしやすいように1分間の通話でスタンプがMAXになるようにする
 *  - production: 60(sec) * 10(min) = 600(sec)
 *  - other: 3(sec) * 20 = 60(sec) == 20stamps
 */
export const REQUIRED_DURATION_TO_GET_STAMP = isProd ? 600 : 3;

const STAMP_CARD_COLS = 5;

const STAMP_CARD_ROWS = [
  { point: 1000 },
  { point: 2000 },
  { point: 3500 },
  { point: 5000 },
];

const stampFrames = Array.from(
  { length: STAMP_CARD_ROWS.length * STAMP_CARD_COLS },
  (_, i) => i
);

/**
 * スタンプカードモーダル
 *
 * @param callMinutesProgress 通話時間(分単位)
 * @returns
 */
export interface StampCardModalProps extends BaseModalProps {
  callDurationProgress: number;
}

export const StampCardModal = ({
  callDurationProgress,
  ...baseModalProps
}: StampCardModalProps) => {
  const filledCount = Math.floor(
    callDurationProgress / REQUIRED_DURATION_TO_GET_STAMP
  );

  /** 5..20 */
  const nextTierIdx = findMultiples(filledCount, STAMP_CARD_COLS)[0];
  const totalDurationToNextTier = nextTierIdx * REQUIRED_DURATION_TO_GET_STAMP;
  const isFullFilled = filledCount >= stampFrames.length;
  const remainingDurationToNextTier = isFullFilled
    ? 0
    : totalDurationToNextTier - callDurationProgress;
  const nextTierRowIdx = isFullFilled
    ? STAMP_CARD_ROWS.length - 1
    : nextTierIdx / STAMP_CARD_COLS - 1;
  const nextTierPoint = STAMP_CARD_ROWS[nextTierRowIdx].point;

  return (
    <BaseModal {...baseModalProps} tw="pb-4">
      <p tw="font-base text-[9px] tracking-[.45px]">
        今月あと
        <HighLight>
          {isProd
            ? `${Math.floor(remainingDurationToNextTier / 60)}分`
            : `${remainingDurationToNextTier}秒`}
        </HighLight>
        通話で
        <HighLight>{nextTierPoint}円分</HighLight>
        Ptプレゼント
      </p>

      <Card
        tw="rounded-none border-[4px] border-white bg-[#FFE4F8] mt-3 px-2 pb-4"
        css={{ boxShadow: "0px 0px 8px 0px rgba(165, 165, 165, 0.5)" }}
      >
        <div tw="flex items-center">
          <HighLightStarIcon
            tw="svg text-[#FE4FD0]"
            css={{ transform: "scale(.6)" }}
          />
          <p tw="font-bold text-[9px] tracking-[.3px] text-[#FE4FD0] mt-0.5 relative right-2">
            電話占いスタンプカード
          </p>
        </div>

        <ul tw="px-1 grid grid-cols-5 gap-1">
          {stampFrames.map((_, idx) => (
            <StampCardFrame
              key={idx}
              stampCardCols={STAMP_CARD_COLS}
              stampCardRows={STAMP_CARD_ROWS}
              frameNum={idx + 1}
              isFilled={idx + 1 <= filledCount}
            />
          ))}
        </ul>
      </Card>

      <ul tw="text-[8px] leading-3 text-secondary text-left mt-2">
        <li>
          ※{" "}
          {isProd
            ? `${REQUIRED_DURATION_TO_GET_STAMP / 60}分`
            : `${REQUIRED_DURATION_TO_GET_STAMP}秒`}
          通話する度にスタンプが1つ押されます。
        </li>
        <li>※ キャンセルされた場合その分のスタンプは取り消されます。</li>
        <li>※ ポイントプレゼントは翌月に行われます。</li>
      </ul>
    </BaseModal>
  );
};
