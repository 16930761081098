import { categoriesState } from "lib/recoil/selectors";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Categories } from "repositories";
import { Category } from "shared/models/index";
import { SWRResponse, TODO_Transfer } from "shared/types";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

export const useCategories = (): SWRResponse<{ categories: Category[] }> => {
  const [categories, setCategories] = useRecoilState(categoriesState);
  const { data, error, mutate } = useSWRImmutable(
    !categories ? `/categories` : null,
    Categories.index
  );

  useEffect(() => {
    if (!data) return;
    setCategories(data);
  }, [data, setCategories]);

  return {
    categories: categories ?? data ?? [],
    isLoading: !error && !data,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};

export const useCategory = (
  alias: string
): SWRResponse<{ category: Category }> => {
  const key = [`/categories/${alias}`];

  const { data, error, mutate } = useSWR(key, Categories.show);

  return {
    category: data,
    isLoading: !error && !data,
    isError: !!error,
    mutate: mutate as TODO_Transfer,
  };
};
