import { ReactNode } from "react";

interface Props {
  title: string;
  bellowTitle?: ReactNode;
  description?: ReactNode;
}

/**
 * @param title [string] required
 * @param description [string] optional
 */
export const PageTitle = ({
  title,
  bellowTitle,
  description,
  ...rest
}: Props) => {
  return (
    <div tw="w-full py-8 px-0 text-center bg-[#fff]" {...rest}>
      <h1 tw="font-base text-xl tracking-[1px]">{title}</h1>
      {bellowTitle}
      {description && (
        <span tw="font-base font-light text-2xs leading-[14px] mt-3 block mx-auto max-w-[80%]">
          {description}
        </span>
      )}
    </div>
  );
};
