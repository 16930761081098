import { repository } from "repositories";
import {
  GetCategoryFortuneListParams,
  GetTelFortuneListRes,
  SWRResponse,
  WithPaginationMetaResponse,
} from "shared/types";
import useSWR from "swr";

export const useCategoryTelFortuneList = (
  alias: string,
  params: GetCategoryFortuneListParams
): SWRResponse<WithPaginationMetaResponse<GetTelFortuneListRes>> => {
  const repo = repository.categoryTelFortune.getList(alias, params);
  const key = alias ? [repo.path, ...Object.values(params)] : null;
  const { data, error, mutate } = useSWR(key, repo.data, {
    fallbackData: repo.fallbackData,
  });
  return {
    ...data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};
