import { ButtonHTMLAttributes, ReactNode } from "react";

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
}

export const IconButton = ({ icon, children, ...rest }: IconButtonProps) => {
  return (
    <button
      type="button"
      tw="bg-opacity-0 border-none flex flex-col items-center"
      {...rest}
    >
      {icon}
      {children}
    </button>
  );
};
