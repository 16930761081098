import HttpClient from "shared/repositories/HttpClient";
import { GetMediaSitemapListParams, GetMediaSitemapListResponse } from "shared/types";

export class MediaSitemapRepository {
  private getListPath() {
    return `/sitemaps`;
  }

  public getList(params: GetMediaSitemapListParams) {
    const path = this.getListPath();

    return {
      path,
      data: () =>
        HttpClient.media
          .get<GetMediaSitemapListResponse>(path, { params })
          .then((res) => res.data),
    };
  }
}
