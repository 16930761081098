export const GTM_CONTAINER_ID = "GTM-NDGSMQ3";

export const GoogleTagManagerIframe = () => {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_ID}`}
        height="0"
        width="0"
        tw="hidden invisible"
      ></iframe>
    </noscript>
  );
};
