import { forwardRef, RefObject, ButtonHTMLAttributes, ReactNode } from "react";
import { css } from "styled-components";
import tw from "twin.macro";

export const outlineButtonCss = tw`border-theme bg-white border border-solid text-theme`;

export const baseButtonCss = tw`rounded-[32.5px] h-[65px] font-base text-white text-base leading-none tracking-[.41px] flex-center px-4 sm:max-w-sm sm:mx-auto`;

export const Variants = {
  fill: "fill",
  outline: "outline",
} as const;

interface ComponentProps {
  children: ReactNode;
  variant?: keyof typeof Variants;
}

export type Props = ComponentProps & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef(
  (props: Props, ref?: RefObject<HTMLButtonElement>) => {
    const { disabled, variant, children, type = "button", ...rest } = props;
    const isOutline = variant === Variants.outline;

    if (disabled) {
      return (
        <button
          type={type}
          tw="cursor-not-allowed"
          css={[
            baseButtonCss,
            isOutline && outlineButtonCss,
            type === "submit"
              ? tw`bg-secondary text-white! border-none`
              : tw`opacity-60`,
            type === "submit" && css({ backgroundImage: "none !important" }),
          ]}
          disabled={disabled}
          ref={ref}
          {...rest}
        >
          {children}
        </button>
      );
    } else {
      return (
        <button
          type={type}
          css={[baseButtonCss, isOutline && outlineButtonCss]}
          ref={ref}
          {...rest}
        >
          {children}
        </button>
      );
    }
  }
);

Button.displayName = "Button";
