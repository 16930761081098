import { AxiosResponse } from "axios";
import { Favorite } from "shared/models/index";
import clients from "shared/repositories/HttpClient";
import {
  WithPaginationMetaResponse,
  Data,
  GetFavoritesParams,
  GetFavoriteListResponse,
} from "shared/types";

const FavoritesRepository = {
  index: async (
    url: string,
    params?: GetFavoritesParams
  ): Promise<WithPaginationMetaResponse<GetFavoriteListResponse>> => {
    const res = await clients.miror.get(url, { params });
    const favorites = res.data.favorites.map(
      (favorite: Data) => new Favorite(favorite)
    );
    return { favorites, meta: res.data.meta };
  },
  post: async (params: {
    favoritableType: string;
    favoritableId: number;
  }): Promise<boolean> => {
    const url =
      params.favoritableType === "Fortune"
        ? `/fortunes/${params.favoritableId}/favorite`
        : `/tel_fortunes/${params.favoritableId}/favorite`;
    const res: AxiosResponse = await clients.miror.post(url, params);
    return res.status === 201;
  },
  delete: async (params: {
    favoritableType: string;
    favoritableId: number;
  }): Promise<boolean> => {
    const url =
      params.favoritableType === "Fortune"
        ? `/fortunes/${params.favoritableId}/favorite`
        : `/tel_fortunes/${params.favoritableId}/favorite`;
    const res: AxiosResponse = await clients.miror.delete(url, {
      data: params,
    });
    return res.status === 200;
  },
};

export default FavoritesRepository;
