import { Button } from "@miror-ui/components";
import {
  BaseModal,
  BaseModalProps,
} from "shared/components/organisms/modal/BaseModal";

export const HasUnpaidOrdersModal = ({
  onDismiss,
  ...rest
}: BaseModalProps) => {
  return (
    <BaseModal
      title="鑑定終了前の電話鑑定の決済に使用されているカードは変更できません"
      subTitle="鑑定終了前の電話鑑定の決済にご利用されているクレジットカードは変更できません。電話鑑定終了もしくはキャンセル後に再度お試しください。"
      onDismiss={onDismiss}
      {...rest}
    >
      <Button onClick={onDismiss}>OK</Button>
    </BaseModal>
  );
};
