import dayjs, { Dayjs } from "dayjs";

export const MessageType = {
  plain: "plain",
  result: "result",
  fromOfficial: "from_official",
  question: "question",
  answer: "answer",
  pushAfterSevenDays: "push_after_seven_days",
} as const;

export default class Message {
  id: number;
  userId: number;
  fortuneTellerId: number;
  toId: number;
  body: string;
  alreadyRead: boolean;
  imageUrl?: string;
  messageType: typeof MessageType[keyof typeof MessageType];
  createdAt?: Dayjs;

  constructor(data?: Partial<Message>) {
    Object.assign(this, data);

    if (!data) return;

    this.createdAt = dayjs(data.createdAt);
  }

  get fromId(): number | undefined {
    if (this.userId) {
      return this.userId;
    } else if (this.fortuneTellerId) {
      return this.fortuneTellerId;
    } else {
      // 運営
      return undefined;
    }
  }
}
