import { useSession } from "next-auth/client";
import QueryString from "qs";
import { repository } from "repositories";
import HttpClient from "shared/repositories/HttpClient";
import { GetKarteListParams, GetKarteListRes } from "shared/types";
import { mapKeysSnakeCase } from "shared/utils";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";

export const useKarteList = (
  params?: GetKarteListParams
): SWRInfiniteResponse<GetKarteListRes> & { isInitialLoading: boolean } => {
  const [session] = useSession();

  const repo = repository.karte.getList(params);

  const getKey = (pageIndex: number, previousPageData: GetKarteListRes) => {
    if (!session || (previousPageData && !previousPageData.kartes.length))
      return null;

    const query = QueryString.stringify(mapKeysSnakeCase(params));

    return `/${repo.path}?page=${pageIndex}&${query}`;
  };

  const { data, isValidating, ...rest } = useSWRInfinite(
    getKey,
    (path: string) =>
      HttpClient.miror.get<GetKarteListRes>(path).then((res) => res.data)
  );

  const isInitialLoading = isValidating && !data;

  return {
    data: data || [repo.fallbackData],
    isValidating: !isInitialLoading && isValidating,
    isInitialLoading,
    ...rest,
  };
};
