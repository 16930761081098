import { FormData } from "features/review/hooks/useReviewForm";
import HttpClient from "shared/repositories/HttpClient";
import { ID, GetFortuneReviewListRes } from "shared/types";

export class FortuneReviewRepository {
  private getListPath(fortuneId: ID, pageIndex: number, perPage: number) {
    return `/fortunes/${fortuneId}/reviews?page=${
      pageIndex + 1
    }&limit=${perPage}`;
  }

  private postReviewPath(fortuneApplicationId: ID) {
    return `/fortune_applications/${fortuneApplicationId}/fortune_reviews`;
  }

  public getList(fortuneId: ID, pageIndex = 0, perPage = 30) {
    const path = this.getListPath(fortuneId, pageIndex, perPage);

    return {
      path,
      data: () =>
        HttpClient.miror
          .get<GetFortuneReviewListRes>(path)
          .then((res) => res.data),
    };
  }

  public postReview(fortuneApplicationId: ID, formData: FormData) {
    const path = this.postReviewPath(fortuneApplicationId);

    return {
      path,
      req: () =>
        HttpClient.miror.post<any>(path, formData).then((res) => res.data),
    };
  }
}
