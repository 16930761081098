import { AxiosResponse } from "axios";
import { TopBanner } from "shared/models/index";
import clients from "shared/repositories/HttpClient";

const TopBannersRepository = {
  index: async (): Promise<TopBanner[]> => {
    const res: AxiosResponse = await clients.miror.get(`/top_banners`);
    return res.data.topBanners.map(
      (topBanner: any) => new TopBanner(topBanner)
    );
  },
};

export default TopBannersRepository;
