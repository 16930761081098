import {
  MessageFormData,
  MessageParams,
} from "shared/features/chat_rooms/hooks/useMessageForm";
import { TelFortuneMessage } from "shared/models/index";
import clients, { headers } from "shared/repositories/HttpClient";

const TelFortuneMessagesRepository = {
  get: async (
    chatRoomId: string | string[] | number
  ): Promise<TelFortuneMessage[]> => {
    const res = await clients.miror.get(
      `/tel_fortune_chat_rooms/${chatRoomId}/tel_fortune_messages`
    );
    return (
      res.data?.telFortuneMessages?.map(
        (tel_fortune_message: TelFortuneMessage) =>
          new TelFortuneMessage(tel_fortune_message)
      ) ?? []
    );
  },
  post: async (
    chatRoomId: string | string[] | number,
    params: MessageParams
  ): Promise<TelFortuneMessage> => {
    const path = `/tel_fortune_chat_rooms/${chatRoomId}/tel_fortune_messages`;
    const data = new MessageFormData(params);
    const res = await clients.miror.post(path, data, headers.formData);
    return new TelFortuneMessage(res.data.message);
  },
};

export default TelFortuneMessagesRepository;
