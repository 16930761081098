import { MenuTypeV2 } from "../types";

export default class Order {
  _type?: MenuTypeV2;
  id: number;
  menuId: number;
  name: string;
  birthday: string;
  gender: "男性" | "女性" | "その他";
  bloodtype: "A型" | "B型" | "O型" | "AB型";
  consultContent: string;
  phoneNumber?: string;
  paymentMethod: "creditCard" | "cvs" | "atm" | "pointAndCoupon";
  usePoint: boolean;
  useCoupon: boolean;
  useCouponId?: number[];
  cvsName?: "ローソン" | "ファミリーマート" | "ミニストップ";
  firstName?: string;
  lastName?: string;
  lastNameKana?: string;
  orderedWhileWaiting?: boolean;

  constructor(data?: Partial<Order>) {
    Object.assign(this, data);
  }
}
