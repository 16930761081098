import dayjs, { Dayjs } from "dayjs";

import { defaultImages } from "../assets/images/defaultImages";
import { toAmbiguousAge } from "../utils";

export const gender = {
  female: "女性",
  male: "男性",
  other: "その他",
} as const;

export type Gender = typeof gender[keyof typeof gender];

const zodiac = {
  capricorn: "capricorn",
  aquarius: "aquarius",
  pisces: "pisces",
  aries: "aries",
  taurus: "taurus",
  gemini: "gemini",
  cancer: "cancer",
  leo: "leo",
  virgo: "virgo",
  libra: "libra",
  scorpio: "scorpio",
  sagittarius: "sagittarius",
} as const;

export type Zodiac = typeof zodiac[keyof typeof zodiac];

export const bloodtype = {
  a: "A型",
  b: "B型",
  o: "O型",
  ab: "AB型",
} as const;

export type BloodType = typeof bloodtype[keyof typeof bloodtype];

export default class User {
  id: number;
  imageUrl: string;
  birthday: Dayjs;
  gender: Gender;
  deleted: boolean;
  restrictedTelFortune: boolean;
  name?: string;
  email?: string;
  phoneNumber?: string;
  signupSource?: "media" | "ad" | "tel_campaign_lp" | "others";
  zodiac?: Zodiac;
  bloodtype?: BloodType;
  point?: number;
  setLineNotify?: boolean;
  uuid?: string;
  latestKarte?: string;
  canDestroy?: boolean;
  isLinkedToLine?: boolean;
  hasSubscriptions?: boolean;
  hasUnpaidOrders?: boolean;

  constructor(data?: Partial<User>) {
    Object.assign(this, data);

    this.name = data?.deleted ? "退会済みユーザー" : data?.name;
    this.imageUrl = data?.imageUrl || defaultImages.user;
    this.birthday = data?.birthday && dayjs(data.birthday);
  }

  get ambiguousAge(): number {
    return toAmbiguousAge(this.birthday);
  }

  get zodiacIcon(): string | undefined {
    return this.zodiac ? `/icons/${this.zodiac}.svg` : undefined;
  }
}
