import { selector } from "recoil";

import { orderState, OrderState } from "../atoms";

/**
 * 申し込み情報: プロフィール（チャット鑑定）
 */
export type OrderProfileState = Pick<
  OrderState,
  "name" | "birthday" | "gender" | "bloodtype"
>;

export const orderProfileState = selector<OrderProfileState>({
  key: "orderProfileState",
  get: ({ get }) => {
    const { name, birthday, gender, bloodtype } = get(orderState);
    return { name, birthday, gender, bloodtype };
  },
  set: ({ get, set }, newValue) =>
    set(orderState, { ...get(orderState), ...newValue }),
});

/**
 * 申し込み情報: プロフィール（電話鑑定）
 */
export type TelFortuneOrderProfileState = Pick<
  OrderState,
  "name" | "birthday" | "gender" | "bloodtype" | "phoneNumber"
>;

export const telFortuneOrderProfileState =
  selector<TelFortuneOrderProfileState>({
    key: "telFortuneOrderProfileState",
    get: ({ get }) => {
      const { name, birthday, gender, bloodtype, phoneNumber } =
        get(orderState);
      return { name, birthday, gender, bloodtype, phoneNumber };
    },
    set: ({ get, set }, newValue) =>
      set(orderState, { ...get(orderState), ...newValue }),
  });

/**
 * 申し込み情報: カルテ
 */
export type OrderKarteState = Pick<OrderState, "consultContent">;

/**
 * @returns consultContent [string] カルテ情報
 */
export const orderkarteState = selector<OrderKarteState>({
  key: "orderKarteState",
  get: ({ get }) => {
    const { consultContent } = get(orderState);
    return { consultContent };
  },
  set: ({ get, set }, newValue) =>
    set(orderState, { ...get(orderState), ...newValue }),
});

/**
 * 申し込み情報: コンビニ支払い情報
 */
export type OrderCvsState = Pick<
  OrderState,
  | "paymentMethod"
  | "cvsName"
  | "phoneNumber"
  | "firstName"
  | "lastName"
  | "lastNameKana"
>;

export const orderCvsState = selector<OrderCvsState>({
  key: "orderCvsState",
  get: ({ get }) => {
    const {
      paymentMethod,
      cvsName,
      phoneNumber,
      firstName,
      lastName,
      lastNameKana,
    } = get(orderState);
    return {
      paymentMethod,
      cvsName,
      phoneNumber,
      firstName,
      lastName,
      lastNameKana,
    };
  },
  set: ({ get, set }, newValue) =>
    set(orderState, { ...get(orderState), ...newValue }),
});

/**
 * 申し込み情報: ATM支払い情報
 */
export type OrderAtmState = Pick<
  OrderState,
  "paymentMethod" | "lastName" | "lastNameKana"
>;

export const orderAtmState = selector<OrderAtmState>({
  key: "orderAtmState",
  get: ({ get }) => {
    const { paymentMethod, lastName, lastNameKana } = get(orderState);
    return { paymentMethod, lastName, lastNameKana };
  },
  set: ({ get, set }, newValue) =>
    set(orderState, { ...get(orderState), ...newValue }),
});

/**
 * 申し込み情報: ポイント
 */
export const usePointState = selector<boolean>({
  key: "usePointState",
  get: ({ get }) => {
    const { usePoint } = get(orderState);
    return usePoint;
  },
  set: ({ get, set }, newValue) =>
    set(orderState, { ...get(orderState), usePoint: newValue }),
});
