import { FormData } from "shared/hooks/useEmailForm";
import clients from "shared/repositories/HttpClient";
import { SignInUser } from "shared/types";

const EmailRepository = {
  confirmation: async (
    confirmationToken: string | string[]
  ): Promise<SignInUser> => {
    const url = "/me/email/confirmation";
    const params = { confirmationToken };
    const res = await clients.miror.post(url, params);
    return res.data;
  },
  show: async (url: string, email: string): Promise<boolean> => {
    const params = { params: { user: { email } } };
    const res = await clients.miror.get(url, params);
    return res.data.isEmailPresent;
  },
  patch: async (data: FormData): Promise<boolean> => {
    const params = { user: { ...data } };
    const res = await clients.miror.patch("/me/email", params);
    return res.status === 200;
  },
};

export default EmailRepository;
