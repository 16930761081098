import { SWRConfiguration } from "swr";

export * from "./request";
export * from "./response";
export * from "./components";
export * from "./styles";
export * from "./form";

export interface SWRConfig<T> {
  path: string;
  key: string | Array<any>;
  fetcher: () => Promise<T>;
  fallbackData?: SWRConfiguration["fallbackData"];
}

export type KeyValue = { [key: string | number | symbol]: any };

/** next routingのdynamic parameterとデータベースのidどちらも対応させた型 */
export type ID = string | string[] | number | undefined;

/** チャットルームの種類 */
const chatRoomType = {
  chat: "chat",
  tel: "tel",
  sample: "sample",
} as const;

export type ChatRoomType = typeof chatRoomType[keyof typeof chatRoomType];

/**
 * TODO: const assertion
 */
export type ZodiacSign =
  | "capricorn"
  | "aquarius"
  | "pisces"
  | "aries"
  | "taurus"
  | "gemini"
  | "cancer"
  | "leo"
  | "virgo"
  | "libra"
  | "scorpio"
  | "sagittarius";

/**
 * TODO: const assertion
 */
export type LuckType = "total" | "love" | "money" | "work";

/** @deprecated */
export const menuTypes = {
  chat: "Fortune",
  tel: "TelFortune",
} as const;

/** @deprecated */
export type MenuType = typeof menuTypes[keyof typeof menuTypes];

export const menuTypesV2 = {
  chat: "chat",
  tel: "tel",
  subscription: "subscription",
} as const;

export type MenuTypeV2 = keyof typeof menuTypesV2;

export const orderTypes = {
  chat: "FortuneApplication",
  tel: "TelFortuneApplication",
} as const;

export type OrderType = typeof orderTypes[keyof typeof orderTypes];

export const paymentMethod = {
  credit: "credit_card",
  cvs: "convenience_store",
  atm: "atm",
  couponAndPoint: "coupon_and_point",
} as const;

export type PaymentMethodType =
  typeof paymentMethod[keyof typeof paymentMethod];

export const criteoEvent = {
  setAccount: "setAccount",
  setSiteType: "setSiteType",
  setEmail: "setEmail",
  viewHome: "viewHome",
  viewItem: "viewItem",
  viewBasket: "viewBasket",
  trackTransaction: "trackTransaction",
} as const;

export type CriteoEvent = typeof criteoEvent[keyof typeof criteoEvent];

export type CriteoTracker = {
  event: CriteoEvent;
} & Record<string, any>;

export interface Achievement {
  title: string;
  value: string | number;
  unit?: string;
}
