import { forwardRef, ReactNode } from "react";
import { css } from "styled-components";

import { zIndex } from "../../styles";

export interface HeaderProps {
  position?: "static" | "fixed";
  notification?: ReactNode;
  className?: string;
  children: ReactNode;
}

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ position = "fixed", notification, children, className }, ref) => {
    return (
      <header
        ref={ref}
        tw="w-full max-w-screen-sm top-0"
        css={css({ zIndex: zIndex.fixed, position })}
      >
        <div
          tw="flex items-center justify-between py-2 bg-white border-b border-solid border-gray"
          className={className}
        >
          {children}
        </div>
        {notification}
      </header>
    );
  }
);

Header.displayName = "Header";
